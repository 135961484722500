
<div class="col-12 mb-2">

    <div class="card box-shadow">

        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Generated Images      
        </h5>
        
        <div class="card-body">
            <div class="card-body">

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
                        [(ngModel)]="searchModel.searchString">
                        <a href="javascript:void(0);" (click)="searchByString($event)" class="btn btn-outline-secondary"  title="Search">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                        </a>    
                    </div>
                </div>
                
                <div class="table-responsive table-fixed-head mh-37 mh-lg-27">
                    <table class="table table-sm table-centered table-striped table-hover table-nowrap w-100 table-fixed-last-child use-datatable dt-responsive">
                        <thead>
                            <tr>
                                <th class="text-left cursor-pointer" (click)="sorting(1)">
                                    Prompt
                                    <svg *ngIf="col1Arrow==1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    <svg *ngIf="col1Arrow==2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>
                                </th>
                                <th class="text-center cursor-pointer" (click)="sorting(2)">
                                    No of Images
                                    <svg *ngIf="col2Arrow==1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    <svg *ngIf="col2Arrow==2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>
                                </th>
                                <th class="text-left cursor-pointer" (click)="sorting(3)">
                                    Size of Images
                                    <svg *ngIf="col3Arrow==1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    <svg *ngIf="col3Arrow==2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>
                                </th>
                                <th class="text-left cursor-pointer" (click)="sorting(4)">
                                    Created At
                                    <svg *ngIf="col4Arrow==1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    <svg *ngIf="col4Arrow==2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>
                                </th>
                                <th class="text-left cursor-pointer">Type</th>                                    
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let image of userGeneratedImages | paginate: pagingConfiguration; index as i">
                                <td class="text-left">{{image.prompt}}</td>
                                <td class="text-center">{{image.noOfImages}}</td>
                                <td class="text-left">{{image.sizeOfImages}}</td>
                                <td class="text-left">{{image.createdAt | date}} {{image.createdAt | date :'h:mm a'}}</td>
                                <td class="text-left">{{image.type}}</td>
                                <td class="text-center">
                                    <a href="javascript:void(0);" (click)="showImageDetails(image.id)" class="text-decoration-none text-dark"  title="View">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">                                         
                                            <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
                                      </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col-3">
                    </div>
                    <div class="col-6 text-left">
                        <pagination-controls (pageChange)="pageChange($event)" class="my-pagination">
                        </pagination-controls>
                    </div>
                    <div class="col-2">
                        Showing
                        {{((pagingConfiguration.currentPage-1)*pagingConfiguration.itemsPerPage)+1}} -
                        {{((pagingConfiguration.currentPage*pagingConfiguration.itemsPerPage) >= pagingConfiguration.totalItems)? pagingConfiguration.totalItems: (pagingConfiguration.currentPage*pagingConfiguration.itemsPerPage)}}
                        of {{pagingConfiguration.totalItems}}
                    </div>
                    <div class="col-1">
                        <select [(ngModel)]="pagingConfiguration.itemsPerPage"
                        (change)="selectItemPerPage($event)" class="form-select" aria-label=".form-select-sm example"
                                >
                                <option value="10" selected>10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>

<!-- Image Details Modal -->
<div class="modal fade  modal-lg" id="imageDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
    <div class="modal-content">

        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Image Details</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-4 mb-4">

            <div class="row">

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-2">

                    <div class="card box-shadow">
            
                        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                            Summary       
                        </h5>
                        
                        <div class="card-body">
                            <div class="card-body">
                                
                                <div class="row mb-2">
            
                                    <div class="col-12 mb-2">
                                        <strong>Image Type</strong>
                                        <div>{{generatedImage.type}}</div>
                                    </div>
            
                                    <div class="col-12 mb-2">
                                        <strong>Prompt</strong>
                                        <div>{{generatedImage.prompt}}</div>
                                    </div>
            
                                    <div class="col-12 mb-2">
                                        <strong>No of Images</strong>
                                        <div>{{generatedImage.noOfImages}}</div>
                                    </div>
            
                                    <div class="col-12 mb-2">
                                        <strong>Size of Images</strong>
                                        <div>{{generatedImage.sizeOfImages}}</div>
                                    </div>
    
                                    <div class="col-12 mb-2">
                                        <strong>Created At</strong>
                                        <div>{{generatedImage.createdAt | date}} {{generatedImage.createdAt | date :'h:mm a'}}</div>
                                    </div>
            
                                </div>
            
                            </div>
                        </div>
            
                    </div>
            
                </div>
    
                <div class="col-9">
    
                    <div class="card box-shadow">
    
                        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                            Generated Image(s)                    
                        </h5>
                        
                        <div class="card-body image-div-height">
            
                            <div class="row">     
            
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4" *ngFor="let image of generatedImagesDetails; let indexOfelement=index;">
            
                                    <div class="mb-2">
                                        <a href="{{image.url}}" class="btn btn-dark" target="_blank">Download</a>
                                    </div>
            
                                    <div class="col-12">
                                        <img src="{{image.url}}" class="img-fluid rounded box-shadow"  alt="Image {{indexOfelement+1}}" >
                                    </div>
                                
                                </div>
            
                            </div>
            
                        </div>
            
                    </div>
    
                </div>

            </div>

        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
    </div>
    </div>
</div>