import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';// 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { ApiUrl } from '../shared/service/api.service';
import { LocalStorageService } from '../shared/service/local.storage.service';


@Injectable({
    providedIn: 'root' /* by using providerIn we don't need to import service on provider array in module */
})

export class FrontEndService{

    constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

    //-------------------------------Tools Item--------------------------------------------------

    GetOpenAILanguageModel(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Pricing/LanguageModelPrice');
        return this.http.get(url);
    }
    
    //----------------------------------End------------------------------------------------------

    
    
}