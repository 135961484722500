import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'back-end-layout',
    templateUrl: './back-end-layout.component.html',
    styleUrls: ['./back-end-layout.component.css']
})

export class BackEndLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}