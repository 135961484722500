import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root' /* by using providerIn we don't need to import service on provider array in module */
})
export class UtilityService {

    constructor(private zone: NgZone, private router: Router) {
    }

    public getCurrentDate():string {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var hh= today.getHours();
        return (mm + '/' + dd + '/' + yyyy + ' ' + today.getHours() 
        +':' + today.getMinutes() + ':' + today.getSeconds());
    }

    public getHotline():string {       
        return "+8801817000339";
    }

}
