
export class UpdateProfileModel {
    firstName: string;
    lastName: string;    
    phoneNumber: string;
    city: string;
    postalcode: string;
    fullAddress: string;
  }

  export class GeneratedImageModel {
    id: string;
    userId: string;  
    prompt: string;
    noOfImages: string; 
    sizeOfImages: string;

    isActive: boolean;
    isDeleted: boolean;
    createdAt: Date;
    generatedImageDetails: GeneratedImageDetailsModel;
  }

  export class GeneratedImageDetailsModel {
    id: string;
    generatedImageId: string;    
    url: string;
  }

  export class UsageDetails{
    Id: number;
    UserId: string;
    ToolsId: number;
    ToolsName: string;
    NoOfTokens: number;
    UsageAmountUSD: number;
    UsageAmountBDT: number;
    TransactionDate: Date;
  }

  export class UsageModel {
    id: string;
    toolsName: string;
    noOfTokens: number;
    usageAmountUSD: string;
    usageAmountBDT: string; 
    transactionDate: Date;
    transactionStatus: string;
  }