import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ApiUrl } from '../../shared/service/api.service';
import {DashboardService} from '../dashboard.service';
import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { UpdateProfileModel } from '../dashboard.model';

declare var $: any;

 @Component({
 selector: 'update-profile',
 templateUrl: './update-profile.component.html',
 styleUrls: ['./update-profile.component.css']
 })

 export class UpdateProfileComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    
    public selectedTemplate:any; 
    public entryForm: FormGroup; 

    updateProfileModel: UpdateProfileModel = new UpdateProfileModel();

    id: string;
    submitted = false;
    errorMessage = "";
    successMessage = "";

    get registrationFromControlStatus() { return this.entryForm.controls; }
      
    constructor(
        private router: Router,
        private formBuilder: FormBuilder, 
        private service: DashboardService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Dashboard");
     }

     ngOnInit() { 
        this.getProfile();

        this.entryForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.maxLength(100)]],
            lastName: ['', [Validators.required, Validators.maxLength(100)]],
            email: ['', [Validators.required, Validators.email]],
            phoneNumber: ['', [Validators.maxLength(20)]],
            city: ['', [Validators.maxLength(20)]],
            postalcode: ['', [Validators.maxLength(20)]],
            fullAddress: ['', [Validators.maxLength(200)]]                       
        });

        this.entryForm.get('email').disable();
    }

    get entryFormControlStatus() { return this.entryForm.controls; }

    goBack() {
        this.location.back();
    };

    getProfile() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getProfile().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {       
                    this.entryForm.get('firstName').setValue(val.data.firstName);  
                    this.entryForm.get('lastName').setValue(val.data.lastName);  
                    this.entryForm.get('email').setValue(val.data.email);                        
                    this.entryForm.get('phoneNumber').setValue(val.data.userProfile[0].phoneNumber);                   
                    this.entryForm.get('city').setValue(val.data.userProfile[0].city);
                    this.entryForm.get('postalcode').setValue(val.data.userProfile[0].postalcode);
                    this.entryForm.get('fullAddress').setValue(val.data.userProfile[0].fullAddress);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    updateProlfe() {
        try {

            this.submitted = true;
            
            this.blockUI.start("Updating..");

            this.updateProfileModel.firstName = this.entryForm.get('firstName').value;
            this.updateProfileModel.lastName = this.entryForm.get('lastName').value;           
            this.updateProfileModel.phoneNumber = this.entryForm.get('phoneNumber').value;
            this.updateProfileModel.city = this.entryForm.get('city').value;
            this.updateProfileModel.postalcode = this.entryForm.get('postalcode').value;
            this.updateProfileModel.fullAddress = this.entryForm.get('fullAddress').value;

            this.service.updateProlfe(this.updateProfileModel).subscribe(response => {
                var val = response;
                if (val.isSucceed == true) {
                    this.successMessage = val.message;
                    alert(this.successMessage);
                }else{
                    this.errorMessage = val.message;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });

        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

 }