import { Component, OnInit, Input } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { AdminService} from '../admin.service';
import { GeneratedChatWithPDFModel, GeneratedChatWithPDFDetailsModel} from '../admin.model';

declare var $: any;

 @Component({
 selector: 'user-chat-with-file',
 templateUrl: './user-chat-with-file.component.html',
 styleUrls: ['./user-chat-with-file.component.css']
 })
 export class UserChatWithFileComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 
   
    @Input() userId="";

    userGeneratedChatWithPDF: GeneratedChatWithPDFModel[];    
    generatedChatWithPDFDetailsModel: GeneratedChatWithPDFDetailsModel[];

    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";
    
    constructor(
        private router: Router,
        private service: AdminService, 
        private localStorageService: LocalStorageService,
        private clipboard: Clipboard,
    ) {

     }

     ngOnInit() {
         this.getChatWithFileList();
    }

    getChatWithFileList() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getChatWithFileList(this.userId).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.userGeneratedChatWithPDF = val.data;
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    getChatWithFileDetails(id: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getChatWithFileDetails(id).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {    
                    this.generatedChatWithPDFDetailsModel = val.data.chatWithFile;
                    $('#chatWithPDFDetailsModal').modal('show');
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
    copyChatPDFText(index: number) {
        this.clipboard.copy(this.generatedChatWithPDFDetailsModel[index].message);
        alert("Copied to clipboard.");
    }

 }