import { Component, OnInit, ElementRef  } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';
import {ToolsService} from '../tools.service';
import {AudioGenerationModel} from '../tools.model';

 @Component({
 selector: 'audio-book',
 templateUrl: './audio-book.component.html',
 styleUrls: ['./audio-book.component.css']
 })
 export class AudioBookComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;    
    public entryForm: FormGroup;
    public availableAudioBooks:any; 
   
    id: string;
    insertOperation = true;
    submitted = false;
    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";
    generatedAudio= null;
    isNewAudioProcessingStarted = false;
    isViewingGeneratedAudio = false;
    audioUrl="";
    generatedAudioIndex=0;
    isTextFileSelected = false;

    entryModel: AudioGenerationModel = new AudioGenerationModel();
    uploadFileFromData: FormData = new FormData();

    get entryFromControlStatus() { return this.entryForm.controls; }
    get audioDetails() { return (this.generatedAudio && this.generatedAudio.generatedAudioDetails) ? this.generatedAudio.generatedAudioDetails : null }

    constructor(
        private router: Router,
        private formBuilder: FormBuilder, 
        private service: ToolsService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private el: ElementRef
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Audio Book");
     }

    ngOnInit() {
        this.getAudios();
        
        this.entryForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            gptModel: ['tts-1', [Validators.required]],
            voice: ['alloy', [Validators.required]],
            responseFormat: ['mp3', [Validators.required]]
        });
    }

    get audioBookGenerationFromControlStatus() { return this.entryForm.controls; }

    goBack() {
        this.location.back();
    };

    clear() {
        try {
            this.id = "";
            this.isTextFileSelected = false;

            this.generatedAudio={};

            this.entryForm.get('name').setValue('');
            this.entryForm.get('gptModel').setValue('tts-1');
            this.entryForm.get('voice').setValue('alloy');
            this.entryForm.get('responseFormat').setValue('mp3');

            this.submitted=false;
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.insertUpdateMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    startNewAudioGeneration() {
        try {
              
            var element = document.getElementById(this.generatedAudioIndex.toString());
            if(element !=null)
                element.classList.remove("active");

            this.isNewAudioProcessingStarted=true;
            this.isViewingGeneratedAudio=false;
            this.audioUrl="";

            this.entryModel.model = "tts-1";
           
            this.entryForm.get('name').setValue("");   
            this.entryForm.get('gptModel').setValue("tts-1");     
            this.entryForm.get('voice').setValue("alloy");
            this.entryForm.get('responseFormat').setValue("mp3");

            this.entryForm.get('name').enable();
            this.entryForm.get('gptModel').enable();
            this.entryForm.get('voice').enable();
            this.entryForm.get('responseFormat').enable();
        }
        catch (ex) {

        }
    }
    
    getAudios() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getAudioBookList().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.availableAudioBooks = val.data;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
    getAudioDetails(audioId: string, index: number) {
        try {
            this.blockUI.start("Loading....");          
            
            this.generatedAudioIndex = index;

            this.audioUrl="";
            this.audioUrl = this.availableAudioBooks[index].audioFileName;

            this.entryForm.get('name').setValue(this.availableAudioBooks[index].name);
            this.entryForm.get('gptModel').setValue(this.availableAudioBooks[index].model);
            this.entryForm.get('voice').setValue(this.availableAudioBooks[index].voice);
            this.entryForm.get('responseFormat').setValue(this.availableAudioBooks[index].responseFormat);
                  
            this.entryForm.get('name').disable();
            this.entryForm.get('gptModel').disable();
            this.entryForm.get('voice').disable();
            this.entryForm.get('responseFormat').disable();
                                      
            this.isNewAudioProcessingStarted=false;
            this.isViewingGeneratedAudio=true;
            
            this.blockUI.stop();
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
       
    selectPDFFile(event) {
        try {
            const file:File = event.target.files[0];            
            if (file) {                         
                this.uploadFileFromData.append('textFile', file, file.name);
                this.isTextFileSelected=true;
            }         
        }
        catch (ex) {
        }
    }

    generateAudio() {
        try {

            this.submitted = true;
            if (this.entryForm.invalid) {
                return;
            }

            this.blockUI.start("Generating Audio...");

            this.uploadFileFromData.append('name', this.entryForm.get('name').value);
            this.uploadFileFromData.append('model', this.entryForm.get('gptModel').value);
            this.uploadFileFromData.append('voice', this.entryForm.get('voice').value);
            this.uploadFileFromData.append('responseFormat', this.entryForm.get('responseFormat').value);

            this.service.generateNewAudio(this.uploadFileFromData).subscribe(response => {
                var val = response;
                this.blockUI.stop();
                if (val.isSucceed == true) {
                    this.clear();
                    this.audioUrl=val.data;
                    this.getAudios();
                    this.successMessage = val.message;
                } else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
 }