import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToolsService} from '../tools.service';
import { AIChatAskedOrSearchModel} from '../tools.model';
import { Message} from '../../tools/tools.model';

 @Component({
 selector: 'chat-with-ai',
 templateUrl: './chat-with-ai.component.html',
 styleUrls: ['./chat-with-ai.component.css']
 })
 export class ChatWithAIComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 

    public availableChats:any; 
    public entryForm: FormGroup;
    public selectedTemplate:any; 
    public chatDetails: Message; 
    
    public gptModelForm: FormGroup;

    id: string;
    insertOperation = true;
    submitted = false;
    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";

    entryModel: AIChatAskedOrSearchModel = new AIChatAskedOrSearchModel();

    get entryFromControlStatus() { return this.entryForm.controls; }   
    
    isChatAvailable = false;
    fileUrl;
    downloadableFileName;
    generatedChatIndex=0;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder, 
        private service: ToolsService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private clipboard: Clipboard,
        private domSanitizer: DomSanitizer
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "chat with AI");
     }

     ngOnInit() {
        this.getChats();

        this.entryForm = this.formBuilder.group({
            askedOrSearch: ['', [Validators.required, Validators.maxLength(200)]]
        });

        this.gptModelForm = this.formBuilder.group({
            gptModel: ['', [Validators.required, Validators.maxLength(50)]]
        });
        this.gptModelForm.get('gptModel').setValue('gpt-4-1106-preview');
        //this.gptModelForm.get('gptModel').disable();
    }

    goBack() {
        this.location.back();
    };

    clear() {
        try {
            this.id = "";
            this.entryForm.get('askedOrSearch').setValue('');
            this.submitted=false;
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.insertUpdateMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    startNewChat() {
        try {
            this.isChatAvailable=true;
            this.entryModel.Messages = [];

            var element = document.getElementById(this.generatedChatIndex.toString());
            if(element !=null)
                element.classList.remove("active");

        }
        catch (ex) {

        }
    }

    processDownloadableData(){
        var data = '';        
         for (var val of this.entryModel.Messages) {
             data = data + val.role + ": " + val.message + "\n"; // + "         " + val.pageNo + "\n\n";
         }
        const blob = new Blob([data], { type: 'application/octet-stream' });
        this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }

    getChats() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getChats().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.availableChats = val.data;
                } else {
                    this.errorMessage = val.errors[0].value;
                    //alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    getChatDtails(createDate: string, index: number) {
        try {
            this.blockUI.start("Loading....");         
            this.service.getChatDetails(createDate).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.entryModel.Messages = val.data;
                    this.isChatAvailable=true;

                    this.processDownloadableData();
                    this.downloadableFileName = val.data[0].userId;

                    this.generatedChatIndex = index;
                } else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    send() {
        try {
            this.submitted = true;
            if (this.entryForm.invalid) { return; }

            this.blockUI.start("Getting answer from AI...");
            
            this.entryModel.gptModel = this.gptModelForm.get('gptModel').value;
            this.entryModel.Messages.push({role: "user", message:this.entryForm.get('askedOrSearch').value});
            //this.entryModel.askedOrSearch = this.entryForm.get('askedOrSearch').value;
            if(this.entryModel.Messages=== undefined)
                this.entryModel.Messages =[];
           
            this.service.sendMesssage(this.entryModel).subscribe(response => {
                var val = response;
                this.blockUI.stop();
                if (val.isSucceed == true) {
                    this.entryModel.Messages.push({role: val.data.role, message: val.data.content});;
                    this.successMessage = val.message;
                    this.clear();
                    this.getChats();
                } else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    copyText(index: number) {
        this.clipboard.copy(this.entryModel.Messages[index].message);
        alert("Copied to clipboard.");
    }

 }