<nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top nav-menu box-shadow">
  <div class="container-fluid">
    
    <a class="navbar-brand" title="GPT Ghor Logo" class="nav-link" [routerLink]="['/']" style="position: relative; z-index: 2; cursor: pointer">
      <img class="img-fluid" style="height: 36px;" src="../../../assets/images/logo.png" alt="GPT Ghor Logo">
      <span class="app-version">Beta 1.0.1</span>
    </a>
       
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        
      </ul>
      <form class="header-menu d-flex">
        <div class="dropdown me-2">
          <button type="button" class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown">
            <img src="https://www.w3schools.com/bootstrap5/img_avatar1.png" alt="Avatar Logo" style="width:25px;" class="rounded-pill"> 
            {{this.loginedUserFullName}}
          </button>
          <ul class="dropdown-menu">
            <li *ngIf="this.currentUserRole=='42ff5dad-3c27-4c97-a3a7-c3d44b67abb3'"><a class="dropdown-item" [routerLink]="['/tools']">Application</a></li>
            <li *ngIf="this.currentUserRole=='42ff5dad-3c27-4c97-a3a7-c3d44b67abb3'"><a class="dropdown-item" [routerLink]="['/dashboard']">Dashboard</a></li>            
            <li><a class="dropdown-item" [routerLink]="['/change-password']">Chance Password</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);"(click)="logout()">Logout</a></li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</nav>