<!-- Footer -->
<footer class="page-footer">
 
  <!-- Copyright -->
    <div class="text-center">
      <span class="me-2">© 2023</span>
      <a [routerLink]="['/faq']" class="link-dark me-2">FAQ</a>
      <a [routerLink]="['/policies']" class="link-dark me-2">Policies</a>
    </div>
  <!-- Copyright -->
  
</footer>
  <!-- Footer -->