<div class="row" style="margin-top: 80px;">
    <div class="col-xl-4"></div>
    <div class="col-xl-4">

        <!--begin::Portlet-->
        <div class="card  box-shadow">

            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                Change Password        
                <a href="javascript:void(0);"  (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                </a>
            </h5>
            
            <div class="card-body">

                <!--begin::Form-->
                <form [formGroup]="changePasswordForm" >  
                    
                    <div class="mb-2">
                        <label for="currentPassword">Current Password</label>
                        <input type="password" formControlName="currentPassword" class="form-control"  
                            [ngClass]="{ 'is-invalid': submitted && changePasswordFromControlStatus.currentPassword.errors }"/>
                        <div *ngIf="submitted && changePasswordFromControlStatus.currentPassword.errors" class="invalid-feedback">
                            <div *ngIf="changePasswordFromControlStatus.currentPassword.errors.required">Password is required</div>
                            <div *ngIf="changePasswordFromControlStatus.currentPassword.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <label for="newPassword">New Password</label>
                        <input type="password" formControlName="newPassword" class="form-control"                              
                            [ngClass]="{ 'is-invalid': submitted && changePasswordFromControlStatus.newPassword.errors }"/>
                        <div *ngIf="submitted && changePasswordFromControlStatus.newPassword.errors" class="invalid-feedback">
                            <div *ngIf="changePasswordFromControlStatus.newPassword.errors.required">New Password is required</div>
                            <div *ngIf="changePasswordFromControlStatus.newPassword.errors.minlength">New Password must be at least 6 characters</div>
                        </div>
                    </div>   

                    <div class="mb-2" style="text-align: center;">
                        <h5 class="fail_message" *ngIf="errorMessage!=''">{{errorMessage}}</h5>
                        <h5 class="success_message" *ngIf="successMessage!=''">{{successMessage}}</h5>
                    </div> 

                    <div class="col-auto" style="text-align: center;">
                        <button [disabled]="changePasswordForm.pristine || changePasswordForm.invalid" (click)="changePassword()" type="button" 
                            class="btn btn-dark">Change Password</button>
                        <button (click)="goBack()"  class="btn btn-dark" style="margin-left: 1%;">Cancel</button>
                    </div>

                </form>   
                <!--end::Form-->

            </div>

        </div>
        <!--end::Portlet-->

    </div>
    <div class="col-xl-4"></div>
</div>