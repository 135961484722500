<div class="row" style="margin-top: 65px;">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xxl-4"></div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 col-xxl-4">
      <div class="card  box-shadow">
              
        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Forget Password Confirmation    
        </h5>
        
        <div class="card-body">           
            <div class="mt-3">Password reset request sent to your email address!</div>
            <div class="mt-3">Please check your <b>Inbox and Spam</b>!</div>
            <div class="mt-3"><a class="nav-link" [routerLink]="['/']" class="">Back To Home</a>    </div>      
        </div>
  
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xxl-4"></div>
  </div>