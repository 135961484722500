import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//My Dashboard component
import { ToolsListComponent } from './tools-list/tools-list.component';
import { ImageGenerationComponent } from './image-generation/image-generation.component';
import {CopyWriteModule} from '../tools/copywriting/copywriting.module';
import {ChatWithAIComponent} from './chat-with-ai/chat-with-ai.component';
import {ChatWithFileComponent} from './chat-with-file/chat-with-file.component';
import {AudioBookComponent} from './audio-book/audio-book.component';
import { VisionComponent } from './vision/vision.component';

@NgModule({
  declarations: [
    ToolsListComponent,
    ImageGenerationComponent,
    ChatWithAIComponent,
    ChatWithFileComponent,
    AudioBookComponent,
    VisionComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,
    CopyWriteModule
  ],

})

export class ToolsModule { }
