
export class AudioGenerationModel {
  name: string;
  model: string;
  voice: string;
  responseFormat: string;
}

export class ImageGenerationModel {
    image: string;
    type: string;
    model: string;
    quality: string;
    style: string;
    prompt: string;
    n: number;
    size: string;
    userId: string;
  }

export class AskedOrSearchModel {
    askedOrSearch: string;
    Messages: Message[]
  }


  export class Message {
    role: string;
    message: string;
  }

  export class ChatWithFileModel {
    role: string;
    content: string;
    pageNo: string;
    createdAt: Date;
  }

  export class EmbeddingsFile {
    id: string;
    project: string;
    userId: string;
    fileId: string;
    fileName: string;
    askedOrSearch: string;
    gptModel: string;
    Messages: Message[];
    embeddingsFileSampleQuestion: EmbeddingsFileSampleQuestionModel[]
  }

  export class EmbeddingsFileSampleQuestionModel {
    id: string;
    question: string
  }

  export class AIChatAskedOrSearchModel {
    gptModel: string;
    askedOrSearch: string;
    Messages: AIChatMessage[]
  }

  export class AIChatMessage {
    role: string;
    message: string;
  }

  export class AddCreditBalanceModel {
    amount: string;
  }