import { Component, OnInit} from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';

import { FrontEndService } from '../front-end.service';
import { ApiUrl } from 'src/app/shared/service/api.service';
import { GPTModel, LanguageModelPrice, ChatWithFileModel, FileProcessingUsage } from '../font-end.model';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.css']
})

export class PricingComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 

    languageModelPrice: LanguageModelPrice = new LanguageModelPrice();
    searchText: string;
    
    constructor(private titleService: Title,  private service: FrontEndService) 
    {
        this.titleService.setTitle(ApiUrl.baseTitle + "Pricing");
    }

    ngOnInit() {
        
        this.languageModelPrice.copywriting= new GPTModel();
        this.languageModelPrice.chatWithAI= new GPTModel();
        this.languageModelPrice.chatWithFile= new ChatWithFileModel();
        this.languageModelPrice.chatWithFile.fileProcessingUsage= new FileProcessingUsage();
        this.languageModelPrice.chatWithFile.chatWithFileUsage= new GPTModel();

        this.getOpenAILanguageModel();
    }

    getOpenAILanguageModel() {
        try {
            this.blockUI.start("Loading....");          
            this.service.GetOpenAILanguageModel().subscribe(response => {             
                 var val = response;
                if (val.isSucceed == true) {                    
                    this.languageModelPrice = val.data;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {

                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

}