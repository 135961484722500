
<div class="row" style="margin-top: 65px;">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xl-4"></div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 col-xl-4">
      <div class="card  box-shadow">
              
        <h5 class="card-header d-flex justify-content-between align-items-center">
            Email Confirmation       
        </h5>
        
        <div class="card-body">           
            <label>Your email address confirmed successfully.
                <a class="nav-link" [routerLink]="['/']" class="kt-link kt-login__link-forgot">Back To Home</a></label>          
        </div>
  
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xl-4"></div>
  </div>