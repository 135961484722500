import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';// 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { ApiUrl } from '../shared/service/api.service';
import { LocalStorageService } from '../shared/service/local.storage.service';
import { UpdateProfileModel } from './dashboard.model';
import {AddCreditBalanceModel} from '../tools/tools.model';

@Injectable({
    providedIn: 'root' /* by using providerIn we don't need to import service on provider array in module */
})

export class DashboardService{

    constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }


    //-------------------------------Subscription--------------------------------------------------

    getSubscription(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Subscription/GetSubscription');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //------------------------------------End------------------------------------------------------


    //-------------------------------User Profile--------------------------------------------------

    // getUsage(): Observable<any> {                
    //     const url = (ApiUrl.baseUrl + 'User/GetUsage');
    //     return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    // }

    getUserCreditBalance(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'User/GetCreditBalance');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    addToCreditBalance(addCreditBalanceModel: AddCreditBalanceModel): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'User/AddToCreditBalance');
        return this.http.post(url, addCreditBalanceModel, this.localStorageService.getAuthenticationHeader());
    }

    getProfile(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'User/GetProfileUser');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    updateProlfe(updateProfileModel: UpdateProfileModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'User/UpdateProfile');
        return this.http.post(url, updateProfileModel, this.localStorageService.getAuthenticationHeader());
    }

    getUsage(pageSize: number, pageNo: number, searchString: string, 
        orderBy: string, order: string): Observable<any> {  

        const url = (ApiUrl.baseUrl + 'User/GetUsage/?pageSize=' + pageSize
        + '&pageNo=' + pageNo + '&searchString='+ searchString
        + '&orderBy=' + orderBy + '&order=' +order);

        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }
    
    //------------------------------------End------------------------------------------------------  

}