
<div class="col-12 mb-2">

    <div class="card box-shadow">

        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Billing      
        </h5>
        
        <div class="card-body">
            <div class="card-body">
                <div class="table-responsive table-fixed-head mh-37 mh-lg-27">
                    <table class="table table-sm table-centered table-striped table-hover table-nowrap w-100 table-fixed-last-child use-datatable dt-responsive">
                        <thead>
                            <tr>
                                <th class="text-right">Amount USD</th>
                                <th class="text-right">Amount BDT</th>
                                <th class="text-center">Transaction Date</th>
                                <th class="text-left">Transaction Status</th>                                                                       
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let billing of userBilling; index as i">
                                <td class="text-right">&#65284;{{billing.amountUSD}}</td>
                                <td class="text-right">&#2547;{{billing.amountBDT}}</td>
                                <td class="text-center">{{billing.transactionDate | date}} {{billing.transactionDate | date :'h:mm a'}}</td>
                                <td class="text-left">{{billing.transactionStatus}}</td>
                            </tr>

                            <tr  *ngFor="let billing of userBilling; index as i">
                                <td class="text-right"><strong>Total: &#65284;{{billingTotalUsageAmountUSD}}</strong></td>
                                <td class="text-right"><strong>Total: &#2547;{{billingTotalUsageAmountBDT}}</strong></td>
                                <td class="text-center"></td>
                                <td class="text-left"></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

</div>