import { Component, OnInit } from '@angular/core';

 @Component({
 selector: 'front-end-footer',
 templateUrl: './front-end-footer.component.html',
 styleUrls: ['./front-end-footer.component.css']
 })
 
 export class FrontEndFooterComponent implements OnInit {

 constructor() { }

 ngOnInit() {
 }

 }