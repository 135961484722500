
<header>
    <front-end-header></front-end-header>
</header>

<div class="container-fluid mt-5" style="min-height: 700px;">
    <router-outlet></router-outlet>
</div>

<front-end-footer></front-end-footer>
