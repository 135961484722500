import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

//My Dashboard component
import { DashboardComponent } from './dashboard/dashboard.component';
import { OverviewComponent } from './overview/overview.component';
import  {SettingsComponent} from './settings/settings.component';
import { BillingOverviewComponent } from './billing-overview/billing-overview.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { UserUsageComponent } from './user-usage/user-usage.component';

@NgModule({
  declarations: [
    DashboardComponent,
    OverviewComponent,
    SettingsComponent,
    BillingOverviewComponent,
    UpdateProfileComponent,
    UserUsageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxPaginationModule
  ],

})

export class DashboardModule { }
