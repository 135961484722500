import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiUrl } from '../../shared/service/api.service';

@Component({
selector: 'dashboard',
templateUrl: './dashboard.component.html',
styleUrls: ['./dashboard.component.css']
})

 export class DashboardComponent implements OnInit {

    constructor(private titleService: Title) 
    {
        this.titleService.setTitle(ApiUrl.baseTitle + "Dashboard");
    }

     ngOnInit() {
    }

 }