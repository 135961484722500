
<div class="col-12 mb-2">

    <div class="card box-shadow">

        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Chat with PDF      
        </h5>
        
        <div class="card-body">
            <div class="card-body">
                <div class="table-responsive table-fixed-head mh-37 mh-lg-27">
                    <table class="table table-sm table-centered table-striped table-hover table-nowrap w-100 table-fixed-last-child use-datatable dt-responsive">
                        <thead>
                            <tr>
                                <th class="text-left">Project</th>
                                <th class="text-left">File Name</th>
                                <th class="text-left">Created At</th>                                                                       
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let chatWithFile of userGeneratedChatWithPDF; index as i">
                                <td class="text-left">{{chatWithFile.project}}</td>
                                <td class="text-left">{{chatWithFile.fileName}}</td>
                                <td class="text-left">{{chatWithFile.createdAt | date}} {{chatWithFile.createdAt | date :'h:mm a'}}</td>
                                <td class="text-center">
                                    <a href="javascript:void(0);" (click)="getChatWithFileDetails(chatWithFile.id)" class="text-decoration-none text-dark"  title="View">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">                                         
                                            <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
                                      </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

</div>

<!-- Chat With PDF Modal -->
<div class="modal fade  modal-lg" id="chatWithPDFDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
    <div class="modal-content">

        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Chat With PDF</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-4 mb-4">

            <div class="row">

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                    <div class="card box-shadow">
    
                        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                            Chat with PDF               
                        </h5>
                        
                        <div class="card-body chat-details">
                            <div class="row module-list"*ngFor="let message of generatedChatWithPDFDetailsModel; let i = index">
            
                                <div class="col-12 mb-2"  *ngIf="message.role=='user'">
                                    <div class="row">
            
                                        <div class="col-3"></div>
            
                                        <div class="col-8 user">                                          
                                            <div>{{message.message}}</div>
                                            <div class="page_no">{{message.pageNo}}</div>
                                        </div>
                                        
                                        <div class="col-1 mt-2">
                                            <a title="Copy Clipboard" href="javascript:void(0);" (click)="copyChatPDFText(i)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                                              </svg>
                                            </a>
                                        </div>
            
                                    </div>
                                </div>
            
                                <div class="col-12 mb-2" *ngIf="message.role=='assistant'">
                                    <div class="row">
                                        <div class="col-8 assistant">                                          
                                            <div>{{message.message}}</div>
                                            <div class="page_no">{{message.pageNo}}</div>
                                        </div>
                                        <div class="col-1 mt-2">
                                            <a title="Copy Clipboard" href="javascript:void(0);" (click)="copyChatPDFText(i)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                                              </svg>
                                            </a>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                </div>
            
                            </div>
                        </div>
            
                    </div>
                </div>
                
            </div>

        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
    </div>
    </div>
</div>