
<div class="row" style="margin-top: 65px;">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xl-4"></div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 col-xl-4">
      <div class="card  box-shadow">
              
        <!-- <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Email Verification
        </h5>
        
        <div class="card-body">             
          <div class="mt-3">A verification email sent to <strong>{{emailAddress}}</strong>!</div>
            <div class="mt-3">Please check your <b>Inbox and Spam</b>!</div>         
        </div> -->
  
        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
          Registration Successfull
      </h5>
      
      <div class="card-body">             
        <div class="mt-3">Your account registered successfully!</div>
          <div class="mt-3">Your can login now</div>         
      </div>

      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xl-4"></div>
  </div>