<header>
    <back-end-header></back-end-header>
</header>

<div class="container-fluid mt-5" style="min-height: 680px;">
    <router-outlet></router-outlet>
</div>

<back-end-footer></back-end-footer>


