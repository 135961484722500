
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title }     from '@angular/platform-browser';
import {Location} from '@angular/common';

import { LocalStorageService } from '../../shared/service/local.storage.service';
import { AuthService } from '../auth.service';
import { ResetPassword } from '../auth.model';
import { ApiUrl } from '../../shared/service/api.service';

 @Component({
 selector: 'forget-password',
 templateUrl: './forget-password.component.html',
 styleUrls: ['./forget-password.component.css']
 })

export class ForgetPasswordComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    resetPasswordModel: ResetPassword = new ResetPassword();

    forgetPasswordForm: FormGroup;
    submitted = false;
    errorMessage = "";
    successMessage = "";

    constructor(public authService: AuthService, private formBuilder: FormBuilder, 
        private router: Router, private localStorageService: LocalStorageService, 
        private titleService: Title, private location: Location, ) {
        this.titleService.setTitle(ApiUrl.baseTitle+ "Forget Password");
        this.clearMessage();
    }

    ngOnInit() {
        this.forgetPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    clearMessage() {
        this.errorMessage = "";
        this.successMessage = "";
    }

    get forgetPasswordFromControlStatus() { return this.forgetPasswordForm.controls; }

    clear(){       
        this.forgetPasswordForm.get('email').setValue('');
    }

    goBack() {
        this.location.back();
    }
    
    resetPasswordRequest() {
        try {
            this.submitted = true;
            if (this.forgetPasswordForm.invalid) {
                return;
            }
            
            this.blockUI.start("Reset Password Processing.");

            this.resetPasswordModel.email=this.forgetPasswordForm.get('email').value;

            this.authService.resetPasswordRequest(this.resetPasswordModel).subscribe(response => {
                this.clearMessage();
                var val = response;

                if (val.isSucceed) {
                    this.successMessage = val.message;
                    this.router.navigate(["forget-password-confirmation"]);
                }
                else {
                    this.errorMessage = val.message;
                }  
                this.blockUI.stop();
            }, error => { 
                this.blockUI.stop(); 
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

}