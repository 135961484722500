
<div class="row" style="margin-top: 65px;">

    <div class="col-3 mb-2">

        <div class="card box-shadow">           
            
            <div class="card-body">
                                
                <div class="row mb-4">
    
                    <div class="col-12 text-center">
                        <div class="chat-btn-wrapper">
                            <button class="chat-btn" (click)="startNewChat()">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="plus" 
                                width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <defs><style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg>
                                <br/>
                                <span class="d-none d-sm-block">Start Chat with AI</span>
                            </button>
                        </div>                               
                    </div>   
                    
                    <div class="col-12" [formGroup]="this.gptModelForm">
                        <label for=noOfImages>Model:</label>
                        <select id="gptModel" formControlName="gptModel" class="form-select" aria-label=".form-select-sm example">
                            <option value="gpt-4-1106-preview" selected>GPT-4 Turbo</option>
                            <option value="gpt-4">GPT-4</option>
                            <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                        </select>
                    </div>

                </div>

                <h5>Chat Log</h5><hr/>

                <div class="list-group chat-list" id="list-tab" role="tablist" style="--bs-list-group-border-color: none; --bs-list-group-border-radius: 0px;">       
                    <a id="{{i}}" style="border-radius: 5px;" *ngFor="let availableEmbeddedFile of availableChats; index as i" 
                        class="list-group-item list-group-item-action mb-1" id="list-home-list" 
                        data-bs-toggle="list" href="javascript:void(0);"  (click)="getChatDtails(availableEmbeddedFile.createdAt, i)" role="tab" aria-controls="list-home">
                
                        <div class="row">
                            <div class="col-1">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 512a48 48 0 1096 0 48 48 0 10-96 0zm200 0a48 48 0 1096 0 48 48 0 10-96 0zm-400 0a48 48 0 1096 0 48 48 0 10-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 00-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 00-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 00112 714v152a46 46 0 0046 46h152.1A449.4 449.4 0 00510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 00142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z"></path></svg>                                        
                            </div>
                            <div class="col-10 d-none d-sm-block">
                                {{availableEmbeddedFile.createdAt | date}}
                            </div>
                        </div>
                                                   
                    </a>    
                </div>

            </div>
        
        </div>

    </div>

    <div *ngIf="isChatAvailable" class="col-9">

        <div class="row">

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                <div class="card box-shadow">

                    <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                        Chat with AI    
                        <a [href]="fileUrl" download="{{downloadableFileName}}.txt" class="text-decoration-none text-dark"  title="Chat History">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                        </a>              
                    </h5>
                    
                    <div class="card-body chat-details">
                        <div class="row module-list"*ngFor="let message of entryModel.Messages; let i = index">

                            <div class="col-12 mb-2"  *ngIf="message.role=='user'">
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-8 user">                                          
                                        {{message.message}}
                                    </div>
                                    <div class="col-1 mt-2">
                                        <a title="Copy Clipboard" href="javascript:void(0);" (click)="copyText(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                                          </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mb-2" *ngIf="message.role=='assistant'">
                                <div class="row">
                                    <div class="col-8 assistant">                                          
                                        {{message.message}}
                                    </div>
                                    <div class="col-1 mt-2">
                                        <a title="Copy Clipboard" href="javascript:void(0);" (click)="copyText(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                                          </svg>
                                        </a>
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                            </div>

                        </div>
                    </div>
        
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div class="card box-shadow">                                        
                    <div class="card-body">
                        <form [formGroup]="this.entryForm">
                            <div class="input-group mb-3">

                                <input type="text" id="askedOrSearch" formControlName="askedOrSearch" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && entryFromControlStatus.askedOrSearch.errors }" 
                                    placeholder="Ask or search anything"/> 

                                <a title="Send" href="javascript:void(0);" (click)="send()"  
                                    [hidden]="!insertOperation" type="button" class="btn btn-dark btn-elevate btn-pill">
                                    <svg style="fill: #fafafa;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">                     
                                        <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"/></svg>
                                </a>

                            </div>
                        </form>
                    </div>       
                </div>
            </div>

        </div>

    </div>

    <div class="col-xs-12 col-sm-12 col-md-1 col-lg-12 col-xl-12 col-xxl-12"></div>

</div>