import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';// 'rxjs/Observable';
import { HttpClient, HttpHeaders  } from '@angular/common/http';

import { ApiUrl } from '../../shared/service/api.service';
import { LocalStorageService } from '../../shared/service/local.storage.service';
import { CreateCopyWriteModel } from './copywriting.model';

@Injectable({
    providedIn: 'root' /* by using providerIn we don't need to import service on provider array in module */
})

export class CopyWriteService{

    constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

    //-------------------------------Menu Item--------------------------------------------------

    GetCopywriting(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Copywriting/GetCopywritingList');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    Insert(model: CreateCopyWriteModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Copywriting/Completion');
        return this.http.post(url, model, this.localStorageService.getAuthenticationHeader());
    }

    //----------------------------------End------------------------------------------------------

    //-----------------------------------Copywriting------------------------------------------------------

    getCopywritings(id: number): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Copywriting/GetGeneratedCopywritings?Id='+id);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    getCopywritingDetails(id: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Copywriting/GetGeneratedCopywriting?Id='+id);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //------------------------------------End------------------------------------------------------
    
}