<div class="row boby-property">
    
    <div class="col-1"></div>
        <div class="col-10">

            <div class="row mt-3">
                <div class="col-12">
                    <h4>Privacy Policy</h4>
                    <div class="col-12">
                        We, Verdetech Labs dba GPTGhor, are committed to protecting and respecting your privacy.
                        This policy sets out the basis on which any personal data we collect from you, or that you 
                        provide to us, will be processed by us. Please read the following carefully to understand 
                        our views and practices regarding your personal data and how we will treat it. For the purposes 
                        of GDPR, we are the “data controller” of your personal data.            
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>Information We Collect:</h4>
                    <div class="col-12">
                        We may collect and process the following data about you:
                        <ul>
                            <li>Information that you provide by filling in forms on our website or app. This includes information provided at the time of registering to use our site or app, subscribing to our service, posting material or requesting further services. We may also ask you for information when you report a problem with our site or app.</li>
                            <li>If you contact us, we may keep a record of that correspondence.</li>
                            <li>Details of transactions you carry out through our site or app and of the fulfillment of your orders.</li>
                            <li>Details of your visits to our site or app including, but not limited to, traffic data, location data, weblogs and other communication data, whether this is required for our own billing purposes or otherwise and the resources that you access.</li>
                            <li>Details of your interactions on our site, including clicks, scrolls, page views, and length of session. </li>  
                        </ul>                           
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>Use of Your Information:</h4>
                    <div class="col-12">
                        We use information held about you in the following ways:
                        <ul>
                            <li>To ensure that content from our site or app is presented in the most effective manner for you and for your computer.</li>
                            <li>To provide you with information, products or services that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes.</li>
                            <li>To carry out our obligations arising from any contracts entered into between you and us.</li>
                            <li>To allow you to participate in interactive features of our service, when you choose to do so.</li>
                            <li>To notify you about changes to our service.</li>
                            <li>To improve our site and to deliver a better experience for you by measuring your engagement, ie: what you like and don't like.</li>                             
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>Disclosure of Your Information:</h4>
                    <div class="col-12">
                        We may disclose your personal information to third parties:
                        <ul>
                            <li>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.</li>
                            <li>If GPTGhor or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</li>
                            <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of GPTGhor, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li> 
                        </ul>                        
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>Security:</h4>
                    <div class="col-12">
                        We take appropriate technical and organizational measures to safeguard your personal information against unauthorized access and unlawful processing as well as against accidental loss, destruction and damage. However please note that no method of transmission over the internet is 100% secure so there is always some element of risk involved when using this medium for communication.     
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>Cookies:</h4>
                    <div class="col-12">
                        We do not use standard cookies to monitor site activity in this app. We do use a cookie system to store data about your account on your local machine - essential to the operation of the site and giving you access to the features this site provides. This data is only shared with our account authenticator, Supabase. We use Google Analytics to track site usage via event tracking rather than cookies. This data is sent to Google Analytics and is not shared with any other third party.     
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>Your Rights:</h4>
                    <div class="col-12">                        
                        You have certain rights under GDPR including:
                        <ul>                            
                            <li>The right to be informed about how your personal data is being used;</li>
                            <li>The right of access;</li>
                            <li>The right to rectification;</li>
                            <li>The right to erasure;</li>
                            <li>The right to restrict processing;</li>
                            <li>The right to data portability;</li>
                            <li>The right to object;</li>
                            <li>Rights related to automated decision making including profiling;</li>
                        </ul>
                        You can exercise these rights at any time by contacting us at gptghor@gmail.com.                           
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>Interpretation of GDPR rights related to data erasure:</h4>
                    <div class="col-12">                        
                        As a result of our nature as an Artifical Intelligence (AI) system, we consider the data that you generate 
                        to be our (GPTGhor as well as you, the user's) shared property. We enable you to use the output for whatever 
                        you like, but do not consider these outputs to be under the purview of the GDPR right to erasure. To respect 
                        your right to erasure, when we receive an erasure request, we will manually anonymize chats and outputs, and 
                        retain these anonymized copies for future AI training data.                              
                    </div>
                </div>
            </div>

            <div class="row mt-3 mb-3">
                <div class="col-12">
                    <h4>Changes To This Policy:</h4>
                    <div class="col-12">                       
                        We reserve the right to update this privacy policy at any time without prior notice so please review it 
                        frequently. Changes and clarifications will take effect immediately upon their posting on the website. 
                        If we make material changes to this policy, we will notify you here that it has been updated so that 
                        you are aware of what information we collect, how we use it and under what circumstances if any we use 
                        it                          
                    </div>
                </div>
            </div>



        </div>
        <div class="col-1"></div>


    




</div>