
<nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top nav-menu box-shadow">
  <div class="container-fluid">
    
    <a class="navbar-brand" title="GPT Ghor Logo" class="nav-link" [routerLink]="['/']" style="position: relative; z-index: 2; cursor: pointer">
      <img class="img-fluid" style="height: 36px;" src="../../../assets/images/logo.png" alt="GPT Ghor Logo">
      <span class="app-version">Beta 1.0.1</span>
    </a>
       
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!-- <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled">Disabled</a>
        </li> -->
      </ul>
      <form class="header-menu d-flex">
        <a href="tel:{{hotLine}}" class="link-dark me-2"><strong>Hot Line: {{hotLine}}</strong> <span class="hotline"> (Mob/WhatsApp)</span></a>
        <a [routerLink]="['/pricing']" class="link-dark me-2"> | Pricing</a>
        <a [routerLink]="['/login']" class="btn btn-dark me-2"  type="button">Login</a>
        <a [routerLink]="['/registration']" class="btn btn-dark " type="button">Signup</a>
      </form>
    </div>
  </div>
</nav>