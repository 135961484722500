import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { ApiUrl } from '../../shared/service/api.service';

@Component({
    selector: 'reset-password-confirmation',
    templateUrl: './reset-password-confirmation.component.html',
    styleUrls: ['./reset-password-confirmation.component.css']
})
export class ResetPasswordConfirmationComponent implements OnInit {
    
    constructor(private titleService: Title, private route: ActivatedRoute) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Reset Password Confirmation");
    }

    ngOnInit() {
    }

}