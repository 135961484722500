import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { ApiUrl } from '../../shared/service/api.service';

@Component({
    selector: 'email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {
    emailAddress: string;
    
    constructor(private titleService: Title, private route: ActivatedRoute) {
        this.route.params.subscribe(params => { this.emailAddress = params['emailAddress']; });
        this.titleService.setTitle(ApiUrl.baseTitle + "Email Verification");
    }

    ngOnInit() {
    }

}