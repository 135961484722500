import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router'
import { Location } from '@angular/common';
import { Title }     from '@angular/platform-browser';

import { LocalStorageService } from  '../../../shared/service/local.storage.service';
import { ApiUrl } from '../../../shared/service/api.service';
import {CopyWriteService} from '../copywriting.service'

@Component({
selector: 'copywriting-list',
templateUrl: './copywriting-list.component.html',
styleUrls: ['./copywriting-list.component.css']
})

export class CopywritingListComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;
    public copyWritings:any; 

    errorMessage = "";
    successMessage = "";
    
    constructor(
        private router: Router,
        private service: CopyWriteService, 
        private localStorageService: LocalStorageService,
        private location: Location,
        private titleService: Title,
    ) { 
        this.titleService.setTitle(ApiUrl.baseTitle+ "Copywriting");
    }

    ngOnInit() {
        this.getInformation();
    }

    getInformation() {
        try {
            this.blockUI.start("Loading....");          
            this.service.GetCopywriting().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.copyWritings = val.data;
                } else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    goBack() {
        this.location.back();
    };

    create(id: number) {
        try {
            this.blockUI.start("Loading....");          
            
            var selectedCopyWriting = this.copyWritings.filter(obj => { return obj['id'] == id; });
            this.localStorageService.setCopyWriting(JSON.stringify(selectedCopyWriting));

            this.blockUI.stop();
            this.router.navigateByUrl("copywriting/create");
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

 }