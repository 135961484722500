import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiUrl } from 'src/app/shared/service/api.service';

@Component({
    selector: 'app-policies',
    templateUrl: './policies.component.html',
    styleUrls: ['./policies.component.css']
})
export class PoliciesComponent implements OnInit {

    constructor(private titleService: Title) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Policies");
    }

    ngOnInit() {
    }

}