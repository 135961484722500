import { Component, OnInit } from '@angular/core';

 @Component({
 selector: 'back-end-footer',
 templateUrl: './back-end-footer.component.html',
 styleUrls: ['./back-end-footer.component.css']
 })
 
 export class BackEndFooterComponent implements OnInit {

 constructor() { }

 ngOnInit() {
 }

 }