import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';// 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { LogoutModel } from './../auth/auth.model';
import { ApiUrl } from '../shared/service/api.service';
import { RoleModel, LoginModel, ResetPassword, RegistrationModel, EmailConfirmationModel, ChangePasswordModel } from './auth.model';
import { LocalStorageService } from '../shared/service/local.storage.service';

@Injectable({
    providedIn: 'root' /* by using providerIn we don't need to import service on provider array in module */
})

export class AuthService{
    token: string;

    constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }
 
    //-------------------------------Authentication--------------------------------------------

    onLogin(loginDetail: LoginModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Authentication/authenticate');
        return this.http.post(url, loginDetail);
    }

    onLogout(logoutDetail: LogoutModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Authentication/logout');
        return this.http.post(url, logoutDetail,  this.localStorageService.getAuthenticationHeader());
    }

    resetPasswordRequest(passwordResetModel: ResetPassword): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Authentication/forgot-password');
        return this.http.post(url, passwordResetModel);
    }

    resetPasswordConfirmation(passwordResetModel: ResetPassword): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Authentication/reset-password');
        return this.http.post(url, passwordResetModel);
    }

    onRegistration(registrationModel: RegistrationModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Authentication/register-user');
        return this.http.post(url, registrationModel);
    }

    emailConfirmation(emailConfirmationModel: EmailConfirmationModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Authentication/verify-email');
        return this.http.post(url, emailConfirmationModel);
    }

    changePassword(changePasswordModel: ChangePasswordModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Authentication/change-password');
        return this.http.post(url, changePasswordModel, this.localStorageService.getAuthenticationHeader());
    }

    //-----------------------------------End--------------------------------------------------


    //-------------------------------Role Management------------------------------------------

    InsertRole(model: RoleModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Role/Insert');
        return this.http.post(url, model, this.localStorageService.getAuthenticationHeader());
    }
    
    UpdateRole(model: RoleModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Role/Update');
        return this.http.put(url, model, this.localStorageService.getAuthenticationHeader());
    }

    DeleteRole(id: string): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Role/Delete?Id='+ id);
        return this.http.delete(url, this.localStorageService.getAuthenticationHeader());
    }

    GetRoleInformation(id: string): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Role/GetInformation?Id=' + id);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    GetRoleList(pageSize: number, pageNo: number, searchString: string, filterByStatus: string, orderBy: string, order: string): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Role/GetList?PageSize=' + pageSize + '&PageNo='+ pageNo + 
        '&SearchString=' + searchString +'&FilterByStatus='+ filterByStatus +
        '&OrderBy=' + orderBy +'&Order='+ order);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //----------------------------------End--------------------------------------------------


}