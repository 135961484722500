
<div class="col-12 mb-2">

    <div class="card box-shadow">

        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Usage      
        </h5>
        
        <div class="card-body">
            <div class="card-body">

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
                        [(ngModel)]="searchModel.searchString">
                        <a href="javascript:void(0);" (click)="searchByString($event)" class="btn btn-outline-secondary"  title="Search">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                        </a>    
                    </div>
                </div>

                <div class="table-responsive table-fixed-head mh-37 mh-lg-27">
                    <table class="table table-sm table-centered table-striped table-hover table-nowrap w-100 table-fixed-last-child use-datatable dt-responsive">
                        <thead>
                            <tr>
                                <th class="text-left cursor-pointer" (click)="sorting(1)">
                                    Tools Name
                                    <svg *ngIf="col1Arrow==1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    <svg *ngIf="col1Arrow==2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>
                                </th>
                                <th class="text-right">Input Tokens</th>
                                <th class="text-right">Output Tokens</th>
                                <th class="text-right cursor-pointer" (click)="sorting(2)">
                                    No of Tokens
                                    <svg *ngIf="col2Arrow==1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    <svg *ngIf="col2Arrow==2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>
                                </th>
                                <th class="text-right">Usage USD</th>
                                <th class="text-right">Usage BDT</th>
                                <th class="text-center">Transaction Date</th>                                                                   
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let usageByUser of userUsage | paginate: pagingConfiguration; index as i">
                                <td class="text-left">{{usageByUser.toolsName}}</td>
                                <td class="text-right">{{usageByUser.inputTokens}}</td>
                                <td class="text-right">{{usageByUser.outputTokens}}</td>
                                <td class="text-right">{{usageByUser.noOfTokens}}</td>
                                <td class="text-right">&#65284;{{usageByUser.usageAmountUSD}}</td>
                                <td class="text-right">&#2547;{{usageByUser.usageAmountBDT}}</td>
                                <td class="text-center">{{usageByUser.transactionDate | date}} {{usageByUser.transactionDate | date :'h:mm a'}}</td>
                            </tr>

                            <tr>
                                <td class="text-left"></td>
                                <td class="text-left"></td>
                                <td class="text-left"></td>
                                <td class="text-left"></td>
                                <td class="text-right"><strong>Total: &#65284;{{usageTotalUsageAmountUSD}}</strong></td>
                                <td class="text-right"><strong>Total: &#2547;{{usageTotalUsageAmountBDT}}</strong></td>
                                <td class="text-center"></td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col-3">
                        <strong>Total usage: &#65284;{{usageTotalUsageAmountUSD}}
                        or
                        &#2547;{{usageTotalUsageAmountBDT}}
                        </strong> 
                    </div>
                    <div class="col-6 text-left">
                        <pagination-controls (pageChange)="pageChange($event)" class="my-pagination">
                        </pagination-controls>
                    </div>
                    <div class="col-2">
                        Showing
                        {{((pagingConfiguration.currentPage-1)*pagingConfiguration.itemsPerPage)+1}} -
                        {{((pagingConfiguration.currentPage*pagingConfiguration.itemsPerPage) >= pagingConfiguration.totalItems)? pagingConfiguration.totalItems: (pagingConfiguration.currentPage*pagingConfiguration.itemsPerPage)}}
                        of {{pagingConfiguration.totalItems}}
                    </div>
                    <div class="col-1">
                        <select [(ngModel)]="pagingConfiguration.itemsPerPage"
                        (change)="selectItemPerPage($event)" class="form-select" aria-label=".form-select-sm example"
                                >
                                <option value="10" selected>10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>