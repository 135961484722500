
<div class="row" style="margin-top: 65px;">

    <div class="col-3 mb-2">

        <div class="card box-shadow">           
            
            <div class="card-body">
                                
                <div class="row mb-2">
    
                    <div class="col-12">
                        <div class="audio-generation-btn-wrapper">
                            <button class="audio-generation-btn" (click)="startNewAudioGeneration()">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="plus" 
                                width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <defs><style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg>
                                <br/>
                                <span class="d-none d-sm-block">Upload a PDF File</span>                               
                            </button>
                        </div>                               
                    </div>   
                    
                </div>

                <div class="list-group audio-list-div-height" id="list-tab" role="tablist" style="--bs-list-group-border-color: none; --bs-list-group-border-radius: 0px;">       
                    
                    <div *ngFor="let availableAudioBook of availableAudioBooks;  index as i">

                        <a id="{{i}}" style="border-radius: 5px; float: left; width: 90%;"  
                            class="list-group-item list-group-item-action mb-1" id="list-home-list" 
                            data-bs-toggle="list" href="javascript:void(0);"  (click)="getAudioDetails(availableAudioBook.id, i)" role="tab" aria-controls="list-home">              
                            
                            <div class="row">
                                <div class="col-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>                                        
                                </div>
                                <div class="col-10 d-none d-sm-block">
                                    {{availableAudioBook.name}}
                                </div>
                            </div>
                            
                        </a> 
                        
                    </div>

                </div>

            </div>
        
        </div>

    </div>

    <div *ngIf="isNewAudioProcessingStarted==true || isViewingGeneratedAudio==true" class="col-5 mb-4">
        <div class="card box-shadow">
            
            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">  
                Generate Audio Book                
                <a href="javascript:void(0);" (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                </a>
            </h5>
            
            <div class="card-body audio-div-height">
                <div class="row">

                    <form [formGroup]="this.entryForm">

                        <div class="row">

                            <div class="col-12 mb-2">
                                <label for=name class="form-label">Name</label>
                                <input type="text" id="name" formControlName="name" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && audioBookGenerationFromControlStatus.name.errors }" placeholder="" />
                                <div *ngIf="submitted && audioBookGenerationFromControlStatus.name.errors" class="invalid-feedback">
                                    <div *ngIf="audioBookGenerationFromControlStatus.name.errors.required">Name is required</div>
                                </div>
                            </div>

                            <div class="col-12 mb-2">
                                <label for=gptModel>Model:</label>
                                <select id="gptModel" formControlName="gptModel" class="form-select" 
                                aria-label=".form-select-sm example">
                                    <option value="tts-1" selected>TTS</option>
                                    <option value="tts-1-hd" selected>TTS HD</option>
                                </select>
                            </div>

                            <div class="col-12 mb-2">
                                <label for=voice>Voice:</label>
                                <select id="voice" formControlName="voice" class="form-select" 
                                aria-label=".form-select-sm example">
                                    <option value="alloy" selected>Alloy</option>
                                    <option value="echo">Echo</option>
                                    <option value="fable">Fable</option>
                                    <option value="onyx">Onyx</option>
                                    <option value="nova">Nova</option>
                                    <option value="shimmer">Shimmer</option>
                                </select>
                            </div>

                            <div class="col-12 mb-2">
                                <label for=responseFormat>Response Format:</label>
                                <select id="responseFormat" formControlName="responseFormat" class="form-select" 
                                aria-label=".form-select-sm example">
                                    <option value="mp3" selected>mp3</option>
                                </select>
                            </div>                          
                            
                            <div [hidden]="!isNewAudioProcessingStarted" class="col-12 mb-2">
                                <label for=prompt>Upload your PDF:</label>
                                <input class="custom-file-input form-control form-control-sm" type="file" (change)="selectPDFFile($event)">
                            </div>   

                            <div class="col-12 mb-2">
                                <button [hidden]="!isNewAudioProcessingStarted" [disabled]="entryForm.invalid || !isTextFileSelected" (click)="generateAudio()" 
                                    type="button" class="btn btn-dark btn-elevate btn-pill">Generate</button>
                            </div>

                        </div>
    
                    </form>
                    
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="audioUrl !=''" class="col-4">
        <div class="card box-shadow">

            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                Download Audio Book               
            </h5>
            
            <div class="card-body image-div-height" style="text-align: center;">

                <a href="{{audioUrl}}" class="text-decoration-none text-dark" title="Download Audio Book" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" height="70" width="70" viewBox="0 0 512 512">
                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                </a>

                <!-- <div class="row">     

                    <audio controls="controls">
                        <source [src]="audioUrl" type="audio/mpeg">
                    </audio> 

                </div> -->

            </div>

        </div>
    </div>

</div>