import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

//Auth Component
import { RegistrationComponent } from '../auth/registration/registration.component';
import { LoginComponent } from '../auth/login/login.component';
import { ForgetPasswordComponent } from '../auth/forget-password/forget-password.component';
import { ResetPasswordRequestComponent } from '../auth/reset-password-request/reset-password-request.component';
import { ResetPasswordConfirmationComponent } from '../auth/reset-password-confirmation/reset-password-confirmation.component';
import { ChangePasswordComponent } from '../auth/change-password/change-password.component';
import { ForgetPasswordConfirmationComponent } from '../auth/forget-password-confirmation/forget-password-confirmation.component';
import { EmailConfirmationComponent } from '../auth/email-confirmation/email-confirmation.component';
import { RoleManagementComponent } from '../auth/role-management/role-management.component';

@NgModule({
  declarations: [
    RegistrationComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordRequestComponent,
    ResetPasswordConfirmationComponent,
    ForgetPasswordConfirmationComponent,
    EmailConfirmationComponent,
    RoleManagementComponent
  ],
  imports: [
    BrowserModule, NgxPaginationModule,
    RouterModule,
    FormsModule, ReactiveFormsModule,
  ],

})
export class AuthModule { }
