import { Component, OnInit, Input } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { AdminService} from '../admin.service';
import { BillingModel} from '../admin.model';

declare var $: any;

 @Component({
 selector: 'user-billing',
 templateUrl: './user-billing.component.html',
 styleUrls: ['./user-billing.component.css']
 })
 export class UserBillingComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 
    @Input() userId="";

    userBilling: BillingModel[];
    billingTotalUsageAmountUSD: number;
    billingTotalUsageAmountBDT: number;

    errorMessage = "";
    successMessage = "";
    
    constructor(
        private router: Router,
        private service: AdminService, 
        private localStorageService: LocalStorageService
    ) {
 
     }

     ngOnInit() {
         this.getBilling();
    }

    getBilling() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getBilling(this.userId).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {  
                    this.billingTotalUsageAmountUSD=val.data.totalBillingAmountUSD;
                    this.billingTotalUsageAmountBDT=val.data.totalBillingAmountBDT;                  
                    this.userBilling = val.data.billingHistory;
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

 }