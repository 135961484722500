
export class LoginModel {
  email: string;
  //userName: string;
  password: string;
}
export class LogoutModel {
  userId: string;
}
export class PasswordResetModel {
  emailaddress: string;
}
export class RegistrationModel {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
}
export class EmailConfirmationModel {
  userId: string;
  token: string;
}
export class ChangePasswordModel {
  userId: string;
  currentPassword: string;
  newPassword: string;
}

export class ResetPassword {
  public email: string;
  public token: string;
  public password: string;
  public confirmPassword: string;
}

export class RoleModel {
  id: string;
  name: string;
  status: string;
}