import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { ApiUrl } from '../../shared/service/api.service';
import { UtilityService } from '../../shared/service/utility.service';
import { LocalStorageService } from '../../shared/service/local.storage.service';
import { AuthService } from '../auth.service';
import { RoleModel } from '../auth.model';
import { SearchModel } from '../../../app/shared/model/search.model';

declare var $: any;

@Component({
    selector: 'role-management',
    templateUrl: './role-management.component.html',
    styleUrls: ['./role-management.component.css']
})

export class RoleManagementComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    entryModel: RoleModel = new RoleModel();
    entryForm: FormGroup;
    id: string;

    insertOperation = true;
    submitted = false;
    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";
    get entryFromControlStatus() { return this.entryForm.controls; }

    constructor(private formBuilder: FormBuilder, private service: AuthService,
        private route: ActivatedRoute, private router: Router, private location: Location,
        private localStorageService: LocalStorageService,
        private utilityService: UtilityService, private titleService: Title) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Role Management");
    }

    ngOnInit() {
        try {
            this.entryForm = this.formBuilder.group({
                name: ['', [Validators.required, Validators.maxLength(100)]],
                status: ['', [Validators.maxLength(15)]]
            });
            this.searchModel.status = "All";
            this.searchModel.searchString = "";
            this.GetList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                this.searchModel.searchString, this.searchModel.status, this.order, this.orderBy);
        }
        catch (ex) {

        }
    }

    goBack() {
        this.location.back();
    }

    //------------------------------------Data Table Declaration---------------------------------

    pagingConfiguration = {
        currentPage: 1,
        itemsPerPage: 5,
        totalItems: 0
    };

    order = "Id";
    orderBy = "Asc";

    col1: string = "";
    col2: string = "";
    col3: string = "";
    col4: string = "";
    col5: string = "";
    col6: string = "";
    col7: string = "";

    col1_arrow: string = "";
    col2_arrow: string = "";
    col3_arrow: string = "";
    col4_arrow: string = "";
    col5_arrow: string = "";
    col6_arrow: string = "";
    col7_arrow: string = "";

    searchModel: SearchModel = new SearchModel();
    list = [];

    selectItemPerPage(ev) {
        try {
            this.pagingConfiguration.itemsPerPage = ev.target.value;
            this.GetList(this.pagingConfiguration.itemsPerPage, 1,
                this.searchModel.searchString, this.searchModel.status, this.order, this.orderBy);
            this.pagingConfiguration.currentPage = 1;
        }
        catch (ex) {

        }
    }

    selectStatus(ev) {
        try {
            this.searchModel.status = ev.target.value;
            this.GetList(this.pagingConfiguration.itemsPerPage, 1,
                this.searchModel.searchString, this.searchModel.status, this.order, this.orderBy);
            this.pagingConfiguration.currentPage = 1;
        }
        catch (ex) {

        }
    }

    searchByString(ev) {
        try {
            setTimeout(() => {
                this.searchModel.searchString = ev.target.value;
                this.GetList(this.pagingConfiguration.itemsPerPage, 1,
                    this.searchModel.searchString, this.searchModel.status, this.order, this.orderBy);
                this.pagingConfiguration.currentPage = 1;
            }, 2000);
        }
        catch (ex) {

        }
    }

    GetList(pageSize: number, pageNo: number, searchString: string, filterByStatus: string,
        orderBy: string, order: string) {
        try {

            this.blockUI.start("Loading....");

            this.service.GetRoleList(pageSize, pageNo, searchString, filterByStatus, orderBy, order).subscribe(response => {
                var val = response;
                if (val.success == true) {
                    this.list = val.value;
                    this.pagingConfiguration.totalItems = val.totalRowsCount;
                } else {
                    this.list = [];
                    this.pagingConfiguration.totalItems = 0;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    pageChange(newPage: number) {
        try {
            this.GetList(this.pagingConfiguration.itemsPerPage, newPage, this.searchModel.searchString,
                this.searchModel.status, this.order, this.orderBy);
            this.pagingConfiguration.currentPage = newPage;
        }
        catch (ex) {

        }
    }

    sorting(index: number) {
        try {
            if (index == 1) {
                this.order = "Name";

                this.col1 = "kt-datatable__cell--sorted";
                this.col2 = "";

                this.col2_arrow = "";
                if (this.col1_arrow == "") {
                    this.orderBy = "Asc";
                    this.col1_arrow = "la-arrow-up";
                }
                else if (this.col1_arrow == "la-arrow-down") {
                    this.orderBy = "Asc";
                    this.col1_arrow = "la-arrow-up";
                }
                else if (this.col1_arrow == "la-arrow-up") {
                    this.orderBy = "Des";
                    this.col1_arrow = "la-arrow-down";
                }
            } else if (index == 2) {
                this.order = "Status";
                this.col1 = "";
                this.col2 = "kt-datatable__cell--sorted";

                this.col1_arrow = "";

                if (this.col2_arrow == "") {
                    this.orderBy = "Asc";
                    this.col2_arrow = "la-arrow-up";

                }
                else if (this.col2_arrow == "la-arrow-down") {
                    this.orderBy = "Asc";
                    this.col2_arrow = "la-arrow-up";
                }
                else if (this.col2_arrow == "la-arrow-up") {
                    this.orderBy = "Des";
                    this.col2_arrow = "la-arrow-down";
                }
            }
            this.GetList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                this.searchModel.searchString, this.searchModel.status, this.order, this.orderBy);
        }
        catch (ex) {

        }
    }

    //------------------------------------Data Table Declaration---------------------------------    

    //---------------------------------------------Modal-----------------------------------------

    openModalInInsertMode() {
        this.clear();
        this.insertUpdateMessage = "New";
        $("#myModal").modal('show');
    }

    openModalInUpdateMode(id: string) {
        this.clear();
        this.insertOperation = false;
        this.insertUpdateMessage = "Update";
        $("#myModal").modal('show');
        this.getInformation(id);
    }

    closeModal() {
        $('#myModal').modal('hide');
    }

    //-------------------------------------------End Modal-----------------------------------------

    //------------------------------------------Clear Message--------------------------------------

    clear() {
        try {
            this.id = "";
            this.entryForm.get('name').setValue('');
            this.entryForm.get('status').setValue('');
            this.insertOperation = true;
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.insertUpdateMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    //----------------------------------------End Clear Message-----------------------------------

    //------------------------------------------CURD Operation------------------------------------

    getInformation(id: string) {
        try {

            this.blockUI.start("Loading....");
            this.id = id;
            this.service.GetRoleInformation(id).subscribe(response => {
                var val = response;
                if (val.success == true) {
                    this.entryForm.get('name').setValue(val.value.name);
                    this.entryForm.get('status').setValue(val.value.status);
                } else {
                    this.list = [];
                    this.pagingConfiguration.totalItems = 0;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    onInsert() {
        try {

            this.submitted = true;
            if (this.entryForm.invalid) {
                return;
            }

            this.blockUI.start("Saving processing...");

            this.entryModel.name = this.entryForm.get('name').value;
            this.entryModel.status = this.entryForm.get('status').value;

            this.service.InsertRole(this.entryModel).subscribe(response => {
                this.clearMessage();
                var val = response;
                if (val.success == true) {
                    this.successMessage = val.message;
                    this.closeModal();
                    this.GetList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, this.searchModel.searchString,
                        this.searchModel.status, this.order, this.orderBy);
                } else {
                    this.errorMessage = val.message;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    onUpdate() {
        try {

            this.submitted = true;
            if (this.entryForm.invalid) {
                return;
            }

            this.blockUI.start("Update processing...");

            this.entryModel.id = this.id;
            this.entryModel.name = this.entryForm.get('name').value;
            this.entryModel.status = this.entryForm.get('status').value;

            this.service.UpdateRole(this.entryModel).subscribe(response => {
                this.clearMessage();
                var val = response;
                if (val.success == true) {
                    this.successMessage = val.message;
                    this.closeModal();
                    this.GetList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, this.searchModel.searchString, this.searchModel.status, this.order, this.orderBy);
                } else {
                    this.errorMessage = val.message;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    onDelete(id) {
        try {
            if (confirm("Are you sure to delete?")) {
                this.blockUI.start("Delete processing...");

                this.service.DeleteRole(id).subscribe(response => {
                    var val = response;
                    if (val.success == true) {
                        this.successMessage = val.message;
                        this.GetList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, this.searchModel.searchString,
                            this.searchModel.status, this.order, this.orderBy);
                    } else {
                        this.errorMessage = val.message;
                    }
                    this.blockUI.stop();
                }, error => {
                    this.blockUI.stop();
                });
            }
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    //----------------------------------------End CURD Operation----------------------------------    

}
