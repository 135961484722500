
<div class="row" style="margin-top: 65px;">

    <div class="col-3 mb-2">

        <div class="card box-shadow">           
            
            <div class="card-body">
                                
                <div class="row mb-4">
    
                    <div class="col-12 text-center">
                        <div class="copywriting-btn-wrapper">
                            <button class="copywriting-btn" (click)="startNewCopywriting()">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="plus" 
        
                                width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <defs><style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg>
                                <br/>
                                <span class="d-none d-sm-block">{{selectedTemplate[0].name}}</span>
                            </button>
                        </div>                               
                    </div>   
                    
                    <div class="col-12" [formGroup]="this.gptModelForm">
                        <label for=noOfImages>Model:</label>
                        <select id="gptModel" formControlName="gptModel" class="form-select" aria-label=".form-select-sm example">
                            <option value="gpt-4-1106-preview" selected>GPT-4 Turbo</option>
                            <option value="gpt-4">GPT-4</option>
                            <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                        </select>
                    </div>

                </div>

                <h5>Log</h5><hr/>
                <div class="list-group copywriting-list" id="list-tab" role="tablist" style="--bs-list-group-border-color: none; --bs-list-group-border-radius: 0px;">       
                    <a id="{{i}}" style="border-radius: 5px;" *ngFor="let availableCopywriting of availableCopywritings;  index as i" 
                        class="list-group-item list-group-item-action mb-1" id="list-home-list" 
                        data-bs-toggle="list" href="javascript:void(0);"  (click)="getCopywritingDetails(availableCopywriting.id, i)" role="tab" aria-controls="list-home">
                
                        <div class="row">
                            <div class="col-1">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 512a48 48 0 1096 0 48 48 0 10-96 0zm200 0a48 48 0 1096 0 48 48 0 10-96 0zm-400 0a48 48 0 1096 0 48 48 0 10-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 00-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 00-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 00112 714v152a46 46 0 0046 46h152.1A449.4 449.4 0 00510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 00142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z"></path></svg>                                                          
                            </div>
                            <div class="col-10 d-none d-sm-block">
                                {{ (availableCopywriting.generatedContent.length>60)? 
                                    (availableCopywriting.generatedContent  | slice:0:60)+'...'
                                    :(availableCopywriting.generatedContent ) }}
                            </div>
                        </div>                     
                        
                    </a>    
                </div>

            </div>
        
        </div>

    </div>

    <div *ngIf="isCopyWritingStarted" class="col-4 mb-2">
        <div class="card box-shadow">
            
            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">  
                {{selectedTemplate[0].name}}
                <a href="javascript:void(0);" (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                </a>
            </h5>
            
            <div class="card-body copywriting-div-height">
                <div class="row">

                    <form [formGroup]="this.entryForm">

                        <div class="row">

                            <div class="col-12 mb-2">
                                <label for=prompt>{{selectedTemplate[0].prompt}}</label>

                                <textarea  rows="10" id="prompt" type="text" formControlName="prompt" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && entryFromControlStatus.prompt.errors }" 
                                        placeholder="Your Input..."></textarea>

                                <div *ngIf="submitted && entryFromControlStatus.prompt.errors" class="invalid-feedback">
                                    <div *ngIf="entryFromControlStatus.prompt.errors.required">Name is required</div>
                                    <div *ngIf="entryFromControlStatus.prompt.errors.maxlength">Name maximum length is 100</div>
                                </div>
                            </div>                   

                            <div class="col-12 mb-2">
                                <button [hidden]="!insertOperation" [disabled]="entryForm.invalid" (click)="generateCopywriting()" 
                                    type="button" class="btn btn-dark btn-elevate btn-pill">Process</button>
                            </div>

                        </div>
    
                    </form>
                    
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="isCopyWritingStarted" class="col-5">
        <div class="card box-shadow">

            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                Generated Template
                <a title="Copy Clipboard" href="javascript:void(0);" (click)="copyText()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                  </svg>
                </a>              
            </h5>
            
            <div class="card-body copywriting-div-height">
                <div class="row">
                    <div class="col-12">                   
                        <form [formGroup]="this.entryForm">
                            <textarea  rows="22" cols="50" id="generatedContent" type="text" formControlName="generatedContent" class="form-control"
                            placeholder="Application Output"></textarea>
                        </form>                                                
                    </div>    
                </div>
            </div>

        </div>
    </div>

</div>