import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

//Admin component
 import { UserOverviewComponent } from './user-overview/user-overview.component';
 import { UserListComponent } from './user-list/user-list.component';
 import { UserImageComponent } from './user-image/user-image.component';
 import { UserCopywritingComponent } from './user-copywriting/user-copywriting.component';
 import { UserChatWithFileComponent } from './user-chat-with-file/user-chat-with-file.component';
 import { UserChatWithAIComponent } from './user-chat-with-ai/user-chat-with-ai.component';
 import { UserBillingComponent } from './user-billing/user-billing.component';
 import { UserUsageComponent } from './user-usage/user-usage.component';

@NgModule({
  declarations: [
    UserOverviewComponent,
    UserListComponent,
    UserImageComponent,
    UserCopywritingComponent,
    UserChatWithFileComponent,
    UserChatWithAIComponent,
    UserBillingComponent,
    UserUsageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxPaginationModule,
  ],

})

export class AdminModule { }
