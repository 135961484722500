<div class="card box-shadow" style="min-height: 380px;">                              
        
    <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
        Billing overview                
    </h5>

    <div class="card-body">
        <h4>Pay as you go</h4> <br/>
        <h5>Credit balance</h5>
        <h5>{{creditBalanceBDT}}&#2547;</h5>

        <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" class="btn btn-dark btn-elevate btn-pill mt-4">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><style>svg{fill:#fcfcfd}</style><path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm64 320H64V320c35.3 0 64 28.7 64 64zM64 192V128h64c0 35.3-28.7 64-64 64zM448 384c0-35.3 28.7-64 64-64v64H448zm64-192c-35.3 0-64-28.7-64-64h64v64zM288 160a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
            Add Balance
        </button>

        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">

                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Add Balance</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body mt-4 mb-4">
                    <div class="col-12 text-center">
                        Call on: 
                        <a href="tel:+8801817000339">
                            <strong>+8801817000339</strong>
                        </a>
                        <span class="hotline"> (Mobile/WhatsApp)</span>
                    </div>
                </div>
                <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Ok</button> -->
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                </div>
            </div>
            </div>
        </div>

    </div>   

    <div class="card-body">

        <div class="col-8">
           
            <!-- <form [formGroup]="this.entryForm">
                <div class="input-group mb-3">
    
                    <input type="text" id="amount" formControlName="amount" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && entryFromControlStatus.askedOrSearch.errors }" 
                        placeholder="Amount"/> 
    
                    <button [disabled]="entryForm.pristine || entryForm.invalid" (click)="addToCreditBalance()" type="button" class="btn btn-dark btn-elevate btn-pill">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><style>svg{fill:#fcfcfd}</style><path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm64 320H64V320c35.3 0 64 28.7 64 64zM64 192V128h64c0 35.3-28.7 64-64 64zM448 384c0-35.3 28.7-64 64-64v64H448zm64-192c-35.3 0-64-28.7-64-64h64v64zM288 160a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
                        Add to credit balance
                    </button>
    
                </div>
            </form> -->
        </div>

    </div>       

</div>