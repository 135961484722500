
<div class="row boby-property">
    
    <div class="col-1"></div>
        <div class="col-10">

            <div class="row mt-3">
                <div class="col-12">
                    <h4>I'm not sure I understand what GPTGhor is or how it works.</h4>
                    <div class="col-12">
                        GPTGhor is a swiss army knife of AI tools that help you streamline your white collar work. 
                        There are various experts, who educate you and help come up with plans-of-action to solve 
                        your problems. There are also Generators available under tools that write content, come up 
                        with new products, write emails, and more.        
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>I'm worried about the accuracy or reliability of GPTGhor.</h4>
                    <div class="col-12">
                        GPTGhor is about 90% accurate, so ask for multiple ideas or examples and just pick your favorite. 
                        Also, don't rely on it for factually correct information - it may struggle with exact dates and 
                        sources, but the ideas it generates and shares are usually very good.                       
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>I'm not sure I need GPTGhor or how it would be useful to me.</h4>
                    <div class="col-12">
                        People all over the world are using GPTGhor to work faster and make more money. 
                        It's a great tool for anyone who does white collar work, and it's especially useful 
                        for entrepreneurs, marketers, and writers.
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4>How Do I Sign-Up For GPTGhor?</h4>
                    <div class="col-12">
                        Click on Free Trial anywhere on this page to get started. It is a truly Free Trial, no credit card required!                 
                    </div>
                </div>
            </div>

        </div>
        <div class="col-1"></div>

</div>