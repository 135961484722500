import { Component, OnInit, ElementRef  } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';
import {ToolsService} from '../tools.service';
import {AudioGenerationModel} from '../tools.model';

declare var $: any;

 @Component({
 selector: 'vision',
 templateUrl: './vision.component.html',
 styleUrls: ['./vision.component.css']
 })
 export class VisionComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;    
    public entryForm: FormGroup;
    public availableVisions:any; 
   
    id: string;
    insertOperation = true;
    submitted = false;
    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";
    generatedAudio= null;
    isNewVisionProcessingStarted = false;
    isViewingProcessedVision = false;
    generatedVision="";
    uploadedImage="";
    visionIndex=0;
    isImageFileSelected = false;

    entryModel: AudioGenerationModel = new AudioGenerationModel();
    uploadFileFromData: FormData = new FormData();

    get entryFromControlStatus() { return this.entryForm.controls; }
    get audioDetails() { return (this.generatedAudio && this.generatedAudio.generatedAudioDetails) ? this.generatedAudio.generatedAudioDetails : null }

    constructor(
        private router: Router,
        private formBuilder: FormBuilder, 
        private service: ToolsService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private el: ElementRef
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Vision");
     }

    ngOnInit() {
        this.getGeneratedVisionList();
        
        this.entryForm = this.formBuilder.group({
            describeExpectationFromThisImage: ['', [Validators.required]],
            gptModel: ['gpt-4o', [Validators.required]]
        });
    }

    get visionGenerationFromControlStatus() { return this.entryForm.controls; }

    goBack() {
        this.location.back();
    };

    clear() {
        try {
            this.id = "";
            this.isImageFileSelected = false;

            this.generatedAudio={};

            this.entryForm.get('name').setValue('');
            this.entryForm.get('gptModel').setValue('tts-1');
            this.entryForm.get('voice').setValue('alloy');
            this.entryForm.get('responseFormat').setValue('mp3');

            this.submitted=false;
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.insertUpdateMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    startNewVisionGeneration() {
        try {
              
            var element = document.getElementById(this.visionIndex.toString());
            if(element !=null)
                element.classList.remove("active");

            this.isNewVisionProcessingStarted=true;
            this.isViewingProcessedVision=false;
            this.generatedVision="";
            this.uploadedImage="";

            this.entryModel.model = "gpt-4o";
           
            this.entryForm.get('describeExpectationFromThisImage').setValue("");   
            this.entryForm.get('gptModel').setValue("gpt-4o");     

            this.entryForm.get('describeExpectationFromThisImage').enable();
            this.entryForm.get('gptModel').enable();

        }
        catch (ex) {

        }
    }
    
    getGeneratedVisionList() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getGeneratedVisionList().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.availableVisions = val.data;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
    getGeneratedVisionDetails(index: number) {
        try {
            this.blockUI.start("Loading....");          
            
            this.visionIndex = index;

             this.generatedVision="";
             this.generatedVision = this.availableVisions[index].generatedVisionDetails[0].generatedContent;
             this.generatedVision = this.generatedVision.split("\n").join("<br/>");

             var appenddescription = $.parseHTML(this.generatedVision);
             
             $("#generatedVision").html("");
             $("#generatedVision").append(appenddescription);

             this. uploadedImage="";
             this.uploadedImage = this.availableVisions[index].fileName;
             this.entryForm.get('describeExpectationFromThisImage').setValue(this.availableVisions[index].prompt);

             var selectedModel = this.availableVisions[index].model.includes("gpt-4o") ? "gpt-4o" : this.availableVisions[index].model;
             this.entryForm.get('gptModel').setValue(selectedModel);
                  
             this.entryForm.get('describeExpectationFromThisImage').disable();
             this.entryForm.get('gptModel').disable();
                                     
            this.isNewVisionProcessingStarted=false;
            this.isViewingProcessedVision=true;
            
            this.blockUI.stop();
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
       
    generateVision() {
        try {

            this.submitted = true;
            if (this.entryForm.invalid) {
                return;
            }

            this.blockUI.start("Generating Vision...");

            this.uploadFileFromData.append('describeExpectationFromThisImage', this.entryForm.get('describeExpectationFromThisImage').value);
            this.uploadFileFromData.append('model', this.entryForm.get('gptModel').value);

            this.service.generateNewVision(this.uploadFileFromData).subscribe(response => {
                var val = response;
                this.blockUI.stop();
                if (val.isSucceed == true) {
                    this.clear();
                                        
                    //this.generatedVision=val.data;
                    this.generatedVision="";
                    this.generatedVision = val.data;
                    this.generatedVision = this.generatedVision.split("\n").join("<br/>");

                    var appenddescription = $.parseHTML(this.generatedVision);
                    
                    $("#generatedVision").html("");
                    $("#generatedVision").append(appenddescription);


                    this.getGeneratedVisionList();
                    this.successMessage = val.message;
                } else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
    selectImageFile(event) {
        try {
            const file:File = event.target.files[0];            
            if (file) {                         
                this.uploadFileFromData.append('imageFile', file, file.name);
                this.isImageFileSelected=true;
            }         
        }
        catch (ex) {
        }
    }
    
 }