import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService} from '../admin.service';
import { UserDetailsModel} from '../admin.model';
import { SearchModel } from '../../../app/shared/model/search.model';

 @Component({
 selector: 'user-list',
 templateUrl: './user-list.component.html',
 styleUrls: ['./user-list.component.css']
 })
 export class UserListComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 
   
    userList: any;

    errorMessage = "";
    successMessage = "";
    
    constructor(
        private router: Router,
        private service: AdminService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private clipboard: Clipboard,
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Registered User List");
     }

     ngOnInit() {
        this.searchModel.status = "All";
            this.searchModel.searchString = "";
            this.pagingConfiguration.currentPage = 1;

            this.getUserList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                            this.searchModel.searchString, this.order, this.orderBy);
    }

    goBack() {
        this.location.back();
    };

    clear() {
        try {
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    getUserList(pageSize: number, pageNo: number, searchString: string, orderBy: string, order: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getUserList(pageSize, pageNo, searchString, orderBy, order).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.userList = val.data;
                    this.pagingConfiguration.totalItems = val.totalRowsCount;
                } else {
                    this.errorMessage = val.errors[0].value;
                    //alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/login");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    getUserOverview(userId: string) {
        this.router.navigate(['admin/user-overview/'+userId]);  
    };


    //------------------------------------Data Table Declaration---------------------------------

    order = "";
    orderBy = "";

    col1Arrow=0;
    col2Arrow=0;
    col3Arrow=0;
    col4Arrow=0;
    col5Arrow=0;

    searchModel: SearchModel = new SearchModel();
    
    pagingConfiguration = {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0
    };

    selectItemPerPage(ev) {
        try {
            this.pagingConfiguration.currentPage = 1;
            this.pagingConfiguration.itemsPerPage = ev.target.value;
            this.getUserList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, 
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {

        }
    }

    searchByString(ev) {
        try {
            this.pagingConfiguration.currentPage = 1;
            this.getUserList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {

        }
    }
   
    pageChange(newPage: number) {
        try {
            this.pagingConfiguration.currentPage = newPage;
            this.getUserList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, 
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {

        }
    }

    sorting(col: number) {
        try {           
            if (col == 1) {
                this.pagingConfiguration.currentPage = 1;
                this.col2Arrow=0; 
                this.col3Arrow=0;
                this.col4Arrow=0;
                this.col5Arrow=0;

                if(this.col1Arrow==0 || this.col1Arrow==1){
                    this.col1Arrow=2;
                    this.orderBy = "Asc";                   
                }else if(this.col1Arrow==2){
                    this.col1Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Name";
            }else if (col == 2) {

                this.col1Arrow=0; 
                this.col3Arrow=0;
                this.col4Arrow=0;
                this.col5Arrow=0;

                if(this.col2Arrow==0 || this.col2Arrow==1){
                    this.col2Arrow=2;
                    this.orderBy = "Asc";
                }else if(this.col2Arrow==2){
                    this.col2Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Email";
            }else if (col == 3) {

                this.col1Arrow=0; 
                this.col2Arrow=0;
                this.col4Arrow=0;
                this.col5Arrow=0;

                if(this.col3Arrow==0 || this.col3Arrow==1){
                    this.col3Arrow=2;
                    this.orderBy = "Asc";
                }else if(this.col3Arrow==2){
                    this.col3Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Phone Number";
            }else if (col == 4) {

                this.col1Arrow=0; 
                this.col2Arrow=0;
                this.col3Arrow=0;
                this.col5Arrow=0;

                if(this.col4Arrow==0 || this.col4Arrow==1){
                    this.col4Arrow=2;
                    this.orderBy = "Asc";
                }else if(this.col4Arrow==2){
                    this.col4Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Created At";
            }else if (col == 5) {

                this.col1Arrow=0; 
                this.col2Arrow=0;
                this.col3Arrow=0;
                this.col4Arrow=0;

                if(this.col5Arrow==0 || this.col5Arrow==1){
                    this.col5Arrow=2;
                    this.orderBy = "Asc";
                }else if(this.col5Arrow==2){
                    this.col5Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Last Logined At";
            }                               
                        
            this.getUserList(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {

        }
    }
    
 }