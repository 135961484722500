
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12" style="margin-top: 80px;">

    <div class="card box-shadow">

        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Available Tools                
            <a href="javascript:void(0);" (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
              </svg>
            </a>
        </h5>
        
        <div class="card-body">
            <div class="row module-list">

                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-4"
                *ngFor="let tool of tools">
                    <a  href="javascript:void(0);" (click)="create(tool.id)">
                        <div class="card  box-shadow card-custom-background-color">
                            <div class="card-body">
                                <div class="mb-1"><strong>{{tool.name}}</strong></div>                 
                                <div class="description">{{tool.description}}</div>
                            </div>
                        </div>
                    </a>
                </div>                    

            </div>
        </div>

    </div>

</div>