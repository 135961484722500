<div class="row" style="margin-top: 65px;">
    
    <div class="col-xl-4"></div>
    
    <div class="col-xl-4">

        <!--begin::Portlet-->
        <div class="card box-shadow">

            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                Registration        
                <a href="javascript:void(0);"  (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                </a>
            </h5>

            <!--begin::Form-->
            <form class="card-body">
                <form [formGroup]="registrationForm">

                    <div class="row mb-2">

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label for=firstName class="form-label">First Name</label>
                            <input type="text" id="firstName" formControlName="firstName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.firstName.errors }" placeholder="" />
                            <div *ngIf="submitted && registrationFromControlStatus.firstName.errors" class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.firstName.errors.required">First name is required</div>
                            </div>
                        </div>
                        
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label for=lastName class="form-label">Last Name</label>
                            <input type="text" id="lastName" formControlName="lastName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.lastName.errors }" placeholder="" />
                            <div *ngIf="submitted && registrationFromControlStatus.lastName.errors" class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.lastName.errors.required">Last name is required</div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="row mb-2">

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label for=phoneNumber class="form-label">Phone Number</label>
                            <input type="text" id="phoneNumber" formControlName="phoneNumber" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.phoneNumber.errors }" placeholder="" />
                            <div *ngIf="submitted && registrationFromControlStatus.phoneNumber.errors" class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.phoneNumber.errors.required">Phone number name is required</div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label for=email class="form-label">Email</label>
                            <input type="email" id="email" formControlName="email" class="form-control" aria-describedby="emailHelp" 
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.email.errors }"/>
                            <div *ngIf="submitted && registrationFromControlStatus.email.errors"
                                class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.email.errors.required">Email is required</div>
                                <div *ngIf="registrationFromControlStatus.email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="row mb-2">

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label for=password class="form-label">Password</label>
                            <input type="password" id="password" formControlName="password" class="form-control" aria-describedby="password" 
                                placeholder="" [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.password.errors }"/>
                            <div *ngIf="submitted && registrationFromControlStatus.password.errors"
                                class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.password.errors.required">Password is required</div>
                                <div *ngIf="registrationFromControlStatus.password.errors.minLength">Password must be minimum 6 characters</div>
                            </div>
                        </div>
    
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label for=confirmPassword class="form-label">Confirm Password</label>
                            <input type="password" id="confirmPassword" formControlName="confirmPassword" class="form-control" aria-describedby="password" 
                                placeholder="" [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.confirmPassword.errors }"/>
                            <div *ngIf="submitted && registrationFromControlStatus.password.errors"
                                class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.confirmPassword.errors.required">Password is required</div>
                                <div *ngIf="registrationFromControlStatus.confirmPassword.errors.confirmPassword">Password and Confirm Password should match</div>
                            </div>
                        </div>

                    </div>
                                      
                    <div class="mb-2">
                        <div class="form-check checkbox-primary form-text">
                            <input v-model="formEntity.acceptTerms" type="checkbox" class="form-check-input"  
                                formControlName="acceptTerms" id="acceptTerms">
                            <label class="form-check-label" for="checkbox-signin">Accept Terms of Use</label>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div style="text-align: center;">
                            <h5 class="fail_message" *ngIf="errorMessage!=''">{{errorMessage}}</h5>
                            <h5 class="success_message" *ngIf="successMessage!=''">{{successMessage}}</h5>
                        </div>
                    </div>

                    <div class="col-auto" style="text-align: center;">
                        <button [disabled]="registrationForm.pristine || registrationForm.invalid" (click)="onRegistration()" 
                            type="button" class="btn btn-dark me-2">Register</button>
                        <button (click)="goBack()" class="btn btn-dark" >Cancel</button>
                    </div>

                </form>
            </form>
            <!--end::Form-->

        </div>
        <!--end::Portlet-->
    </div>
    <div class="col-xl-4"></div>
</div>