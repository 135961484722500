import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import { LocalStorageService } from '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';
import { ChangePasswordModel } from '../auth.model';
import { AuthService } from '../auth.service';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;
    changePasswordModel: ChangePasswordModel = new ChangePasswordModel();
    changePasswordForm: FormGroup;

    submitted = false;
    errorMessage = "";
    successMessage = "";

    constructor(
        private formBuilder: FormBuilder, 
        private titleService: Title,
        private localStorageService: LocalStorageService, 
        private location: Location,
        private authService: AuthService
    ) 
    {
        this.titleService.setTitle(ApiUrl.baseTitle + "Change Password");
    }

    ngOnInit() {
        this.changePasswordForm = this.formBuilder.group({
            currentPassword: ['', [Validators.required, Validators.minLength(6)]],
            newPassword: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    get changePasswordFromControlStatus() { return this.changePasswordForm.controls; }

    clear() {
        this.changePasswordForm.get('currentPassword').setValue('');
        this.changePasswordForm.get('newPassword').setValue('');
        this.submitted=false;
    }

    clearMessage() {
        this.errorMessage = "";
        this.successMessage = "";
    }

    goBack() {
        this.location.back();
    }

    changePassword() {
        try {
            this.submitted = true;
            if (this.changePasswordForm.invalid) {
                return;
            }

            this.blockUI.start("Password change processing");

            this.changePasswordModel.userId = this.localStorageService.getCurrentUser();
            this.changePasswordModel.currentPassword = this.changePasswordForm.get('currentPassword').value;
            this.changePasswordModel.newPassword = this.changePasswordForm.get('newPassword').value;

            this.authService.changePassword(this.changePasswordModel).subscribe(response => {
                this.clearMessage();
                var val = response;
                if (val.isSucceed) {
                    this.clear();
                    this.successMessage = val.message;
                }
                else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
}