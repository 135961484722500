<!-- Footer -->
<footer class="page-footer" >
 
  <!-- Copyright -->
    <div class="">
      <span class="me-2">© 2023</span>
      <a [routerLink]="['/']" class="link-dark me-2">FAQ</a>
      <a [routerLink]="['/']" class="link-dark me-2">Policies</a>
    </div>
  <!-- Copyright -->
  
</footer>
  <!-- Footer -->