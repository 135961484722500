
<div class="row boby-property">
    <h1 class="text-align-center mt-2">Your one place for unbeatable AI Apps</h1>
    <h5 class="free-registration mt-2">Create your free account:</h5>
    <div class="text-align-center">
        <a [routerLink]="['/registration']" class="btn btn-dark " type="button">Get Started</a>
    </div>

    <div class="row mt-2">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-5 col-xl-5 col-xxl-5"></div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
            <div class="row">
                <div class="col-12 powered-by">Powered By</div>
            </div>
            <div class="row">
                <!-- <div class="col-4">
                    <img class="img-fluid" src="../../../assets/images/google-ai.png" alt="Google AI">
                </div> -->
                <div class="col-4">
                    <img class="img-fluid" src="../../../assets/images/azure-ai.png" alt="Azure">
                </div>
                <div class="col-4">
                    <img class="img-fluid" src="../../../assets/images/open-ai.png" alt="OpenAI">
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4 col-xxl-4"></div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">

            <div class="row mt-3">
                <h1>Image Generation</h1><hr>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <img class="img-fluid rounded box-shadow" src="../../../assets/images/image-generation.png" alt="AI Image Generation">
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div style="text-align: justify;">
                        Unleash your creativity with AI Images. Our tool lets you effortlessly turn your imagination into stunning 
                        visuals quickly. With cutting-edge algorithms, you can generate high-quality, production-ready images from 
                        the get-go. Create stunning visuals in no time and leave an impact on your audience.
                    </div>
                    <div class="mt-3">
                        <a [routerLink]="['/registration']" class="btn btn-dark " type="button">Generate Images</a>
                    </div>
                </div>
        
            </div> 

            <div class="row mt-5">
                                  
                <h1>Vision</h1> <hr>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mt-1">
                    <img class="img-fluid rounded box-shadow" src="../../../assets/images/vision.png" alt="Chat with file">
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p style="text-align: justify;">
                        GPTGhor have vision capabilities, meaning it can take in images and answer questions about them.
                    </p>
                    <div class="mt-3">
                        <a [routerLink]="['/registration']" class="btn btn-dark " type="button">Vision</a>
                    </div>
                </div>

            </div>

            <div class="row mt-5">
                      
                <h1>Chat with PDF</h1> <hr>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <img class="img-fluid rounded box-shadow" src="../../../assets/images/chat-with-file.png" alt="Chat with file">
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p style="text-align: justify;">
                        Join millions of students, researchers and professionals to instantly answer questions and understand research with AI.
                    </p>
                    <p style="text-align: justify;">
                        GPTGhor is an AI-powered app that will make reading journal articles easier and faster. 
                        Simply upload a PDF and start asking it questions.

                        It's like ChatGPT, but for research papers.
                    </p>
                    <div class="mt-3">
                        <a [routerLink]="['/registration']" class="btn btn-dark " type="button">Chat with PDF</a>
                    </div>
                </div>

            </div>                     

            <div class="row mt-5">

                <h1>Copywriting</h1><hr>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <img class="img-fluid rounded box-shadow" src="../../../assets/images/copy-writing.png" alt="Copywriting">
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div style="text-align: justify;">
                        Boost your content's impact with AI Copywriting. Our advanced technology unleashes your creativity and fosters 
                        a strong emotional bond with your audience. Create engaging copy that drives action and profits. Elevate your 
                        business with AI Copywriting - try it now.
                    </div>
                    <div class="mt-3">
                        <a [routerLink]="['/registration']" class="btn btn-dark " type="button">Write Copy</a>
                    </div>
                </div>
        
            </div>

            <div class="row mt-5">                       
        
                <h1>Chat with AI</h1><hr>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <img class="img-fluid rounded box-shadow" src="../../../assets/images/chat-AI.png" alt="Chat with AI">
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div style="text-align: justify;">
                        Our conversational assistant is here to fulfill your every need, providing personalized responses to your 
                        questions and requests. With our AI technology, you'll experience seamless conversation and limitless 
                        possibilities. Join us today and unleash your curiosity.
                    </div>
                    <div class="mt-3">
                        <a [routerLink]="['/registration']" class="btn btn-dark " type="button">Chat with AI</a>
                    </div>
                </div>

            </div>

        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
    </div>
   
    <div class="row mt-3 mb-5">
        <div class="col-1"></div>
        <div class="col-10">
            <h1>Get the edge now.</h1>
            <div>Create an account and start creating with AI 100% free – no credit card required.</div>
            <div class="mt-3">
                <a [routerLink]="['/registration']" class="btn btn-dark " type="button">Create My Account</a>
            </div>
        </div>
        <div class="col-1"></div>
    </div>

</div>


