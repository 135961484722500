import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ApiUrl } from '../../shared/service/api.service';

 @Component({
 selector: 'settings',
 templateUrl: './settings.component.html',
 styleUrls: ['./settings.component.css']
 })

 export class SettingsComponent implements OnInit {

    constructor(private titleService: Title) 
    {
        this.titleService.setTitle(ApiUrl.baseTitle + "Settings");
    }

     ngOnInit() {   
    }

 }