<div class="card box-shadow" style="min-height: 380px;">                              
               
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Edit Profile                
        </h5>
        <div class="card-body" >
            <form class="card-body">
                <form [formGroup]="entryForm">

                    <div class="row">

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                            <label for=firstName class="form-label">First Name</label>
                            <input type="text" id="firstName" formControlName="firstName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.firstName.errors }" placeholder="" />
                            <div *ngIf="submitted && registrationFromControlStatus.firstName.errors" class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.firstName.errors.required">First name is required</div>
                            </div>
                        </div>
                        
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                            <label for=lastName class="form-label">Last Name</label>
                            <input type="text" id="lastName" formControlName="lastName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.lastName.errors }" placeholder="" />
                            <div *ngIf="submitted && registrationFromControlStatus.lastName.errors" class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.lastName.errors.required">Last name is required</div>
                            </div>
                        </div>
                                                   
                    </div>
                        
                    <div class="row">

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                            <label for=email class="form-label">Email</label>
                            <input [disabled]='false' type="email" id="email" formControlName="email" class="form-control" aria-describedby="emailHelp" 
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.email.errors }"/>
                            <div *ngIf="submitted && registrationFromControlStatus.email.errors"
                                class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.email.errors.required">Email is required</div>
                                <div *ngIf="registrationFromControlStatus.email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                            <label for=phoneNumber class="form-label">Phone Number</label>
                            <input type="text" id="phoneNumber" formControlName="phoneNumber" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.phoneNumber.errors }" placeholder="" />
                            <div *ngIf="submitted && registrationFromControlStatus.phoneNumber.errors" class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.phoneNumber.errors.maxLength">Maximum length will be 20</div>
                            </div>
                        </div>
                                                
                    </div>
   
                    <div class="row">

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                            <label for=city class="form-label">City</label>
                            <input type="text" id="city" formControlName="city" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.city.errors }" placeholder="" />
                            <div *ngIf="submitted && registrationFromControlStatus.city.errors" class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.city.errors.maxLength">Maximum length will be 20</div>
                            </div>
                        </div>
    
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                            <label for=postalcode class="form-label">Postal Code</label>
                            <input type="text" id="postalcode" formControlName="postalcode" class="form-control"  
                                [ngClass]="{ 'is-invalid': submitted && registrationFromControlStatus.postalcode.errors }"/>
                                
                            <!-- <div *ngIf="submitted && registrationFromControlStatus.postalcode.errors.maxLength" class="invalid-feedback">
                                <div *ngIf="registrationFromControlStatus.postalcode.errors.maxLength">Maximum length will be 20</div>
                            </div> -->
                        </div>

                    </div>

                    <div class="mb-2">
                        <div style="text-align: center;">
                            <h5 class="fail_message" *ngIf="errorMessage!=''">{{errorMessage}}</h5>
                            <h5 class="success_message" *ngIf="successMessage!=''">{{successMessage}}</h5>
                        </div>
                    </div>

                    <div class="col-auto" style="text-align: center;">
                        <button [disabled]="entryForm.pristine || entryForm.invalid" (click)="updateProlfe()" 
                            type="button" class="btn btn-dark me-2">Update</button>
                        <button (click)="goBack()" class="btn btn-dark" >Cancel</button>
                    </div>

                </form>
            </form>
        </div>   
   
    </div>

</div>