import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BlockUIModule } from 'ng-block-ui';
import { CommonModule } from '@angular/common';

//My Directive
import { NumberOnlyDirective } from './shared/directive/numbers-only.directive';

//My Modules
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { FontEndModule } from './font-end/font-end.module';
import { AuthModule } from './auth/auth.module';
import {ToolsModule} from './tools/tools.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {AdminModule} from './admin/admin.module';

//App Component
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
    NumberOnlyDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
    
    AppRoutingModule,
    LayoutModule,
    FontEndModule,
    AuthModule,
    ToolsModule,
    DashboardModule,
    AdminModule
  ],
  
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
