
<div  class="row boby-property">

    <div class="col-12 mt-2">
        <h1>Pricing</h1>
    </div>

    <div class="row">

        <div class="col-1"></div>

        <div class="col-10">

            <ol>

                <li>
                    <hr/>
                    <div class="row mt-3">

                        <div class="col-12">
                            <span class="module-price-label">Image Generation</span>
                        </div>
        
                        <div class="row mt-2">
                            <div class="col-3">                       
                                <strong>Resolution</strong>
                            </div>
                            <div class="col-3">
                                <strong>Price</strong>
                            </div>
                        </div>
        
                        <div class="row mt-1" *ngFor="let imagePrice of languageModelPrice.imageModel; let i = index">
                            <div class="col-3">
                                {{imagePrice.resolution}}
                            </div>
                            <div class="col-3">
                                {{imagePrice.price}} &#2547;/Per Image
                            </div>
                        </div>

                    </div>
                    <hr/>
                </li>

                <li class="mt-5">
                    <hr/>
                    <div class="row">

                        <div class="col-12">
                            <span class="module-price-label">Chat with File</span>
                        </div>
        
                        <ol type="i">
                            <li class="mt-2">
                                <hr style="border: 1px dotted"/>
                                <div class="row">
        
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="price-label-1">File Processing</span>
                                        </div>
                                    </div>
                    
                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <strong>No of Tokens</strong>
                                        </div>
                                        <div class="col-3">
                                            <strong>Price</strong>
                                        </div>
                                    </div>
                    
                                    <div class="row">
                                        <div class="col-3">
                                            {{languageModelPrice.chatWithFile.fileProcessingUsage.noOfTokens}}
                                        </div>
                                        <div class="col-3">
                                            {{languageModelPrice.chatWithFile.fileProcessingUsage.usage}} &#2547;
                                        </div>
                                    </div>

                                </div>
                                <hr style="border: 1px dotted"/>
                            </li>

                            <li class="mt-4">
                                <hr style="border: 1px dotted"/>
                                <div class="row">
        
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="price-label-1">Chat Details</span>
                                        </div>
                                    </div>
                    
                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <strong>No of Tokens</strong>
                                        </div>
                                        <div class="col-3">
                                            <strong>Request Price</strong>
                                        </div>
                                        <div class="col-3">
                                            <strong>Response Price</strong>
                                        </div>
                                    </div>
                    
                                    <div class="row mt-2">
                                        <div class="col-3">
                                            {{languageModelPrice.chatWithFile.chatWithFileUsage.noOfTokens}}
                                        </div>
                                        <div class="col-3">
                                            {{languageModelPrice.chatWithFile.chatWithFileUsage.inputPrice}} &#2547;
                                        </div>
                                        <div class="col-3">
                                            {{languageModelPrice.chatWithFile.chatWithFileUsage.outputPrice}} &#2547;
                                        </div>
                                    </div>
                    
                                </div>  
                                <hr style="border: 1px dotted"/>
                            </li>

                        </ol>                              

                    </div>
                    <hr/>
                </li>

                <li class="mt-5">
                    <hr/>
                    <div class="row">

                        <div class="col-12">
                            <span class="module-price-label">Chat with AI</span>
                        </div>
        
                        <div class="row mt-2">
                            <div class="col-3">
                                <strong>No of Tokens</strong>
                            </div>
                            <div class="col-3">
                                <strong>Request Price</strong>
                            </div>
                            <div class="col-3">
                                <strong>Response Price</strong>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col-3">
                                {{languageModelPrice.chatWithAI.noOfTokens}}
                            </div>
                            <div class="col-3">
                                {{languageModelPrice.chatWithAI.inputPrice}} &#2547;
                            </div>
                            <div class="col-3">
                                {{languageModelPrice.chatWithAI.outputPrice}} &#2547;
                            </div>
                        </div>

                    </div>   
                    <hr/>
                </li>

                <li class="mt-5">
                    <hr/>
                    <div class="row">

                        <div class="col-12">
                            <span class="module-price-label">Copywriting</span>
                        </div>
        
                        <div class="row mt-2">
                            <div class="col-3">
                                <strong>No of Tokens</strong>
                            </div>
                            <div class="col-3">
                                <strong>Request Price</strong>
                            </div>
                            <div class="col-3">
                                <strong>Response Price</strong>
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col-3">
                                {{languageModelPrice.copywriting.noOfTokens}}
                            </div>
                            <div class="col-3">
                                {{languageModelPrice.copywriting.inputPrice}} &#2547;
                            </div>
                            <div class="col-3">
                                {{languageModelPrice.copywriting.outputPrice}} &#2547;
                            </div>
                        </div>

                    </div>
                    <hr/>
                </li>

            </ol>

        </div>

        <div class="col-1"> </div>

    </div>

</div>




