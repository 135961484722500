
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { LocalStorageService } from '../../shared/service/local.storage.service';
import { AuthService } from '../auth.service';
import { ResetPassword } from '../auth.model';
import { ApiUrl } from '../../shared/service/api.service';

@Component({
    selector: 'reset-password-request',
    templateUrl: './reset-password-request.component.html',
    styleUrls: ['./reset-password-request.component.css']
})

export class ResetPasswordRequestComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;

    resetPasswordModel: ResetPassword = new ResetPassword();
    resetPasswordForm: FormGroup;

    submitted = false;
    errorMessage = "";
    successMessage = "";

    constructor(public authService: AuthService, private formBuilder: FormBuilder,
        private router: Router, private localStorageService: LocalStorageService,
        private titleService: Title, private location: Location, private route: ActivatedRoute) {

        this.route.params.subscribe(params => {            
            var val = params['token'];
            this.resetPasswordModel.token = val.split("_").join("/");// + "==";
        });

        this.titleService.setTitle(ApiUrl.baseTitle + "Reset Password");
        this.clearMessage();
    }

    ngOnInit() {
        this.resetPasswordForm = this.formBuilder.group({
            newPassword: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    clearMessage() {
        this.errorMessage = "";
        this.successMessage = "";
    }

    get resetPasswordFromControlStatus() { return this.resetPasswordForm.controls; }

    clear() {
        this.resetPasswordForm.get('email').setValue('');
        this.submitted = false;
    }

    goBack() {
        this.location.back();
    }

    resetPasswordConfirmation() {
        try {
            this.submitted = true;
            if (this.resetPasswordForm.invalid) {
                return;
            }

            this.blockUI.start("Reset Password Processing.");
            this.resetPasswordModel.password = this.resetPasswordForm.get('newPassword').value;
            this.resetPasswordModel.confirmPassword = this.resetPasswordForm.get('confirmPassword').value;

            this.authService.resetPasswordConfirmation(this.resetPasswordModel).subscribe(response => {
                this.clearMessage();
                var val = response;

                if (val.isSucceed) {
                    this.successMessage = val.message;
                    this.router.navigate(["reset-password-confirmation"]);     
                }
                else {
                    this.errorMessage = val.message;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

}