import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root' /* by using providerIn we don't need to import service on provider array in module */
})
export class LocalStorageService {

    constructor(private zone: NgZone, private router: Router) {
    }

    public getCurrentUser() {
        return localStorage.getItem('UserId');
    }

    public getCurrentUserRole() {
        return localStorage.getItem('UserRoleId');
    }

    public getCurrentUserFullName() {
        return localStorage.getItem('Name');
    }

    public getAccessToken(): string {
        return localStorage.getItem('Authorization');
    }

    public isAuthenticate(): boolean {
        if (localStorage.getItem('IsAuthenticate') == 'true')
            return true;
        else
            return false;
    }

    header = new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": this.getAccessToken()
    });

    httpOptions = {
        headers: this.header
      };

    public getAuthenticationHeader(): any {
        return this.httpOptions;
    }



    headerWithoutContentType = new HttpHeaders({
        "Authorization": this.getAccessToken()
    });

    httpOptionsWithoutContentType = {
        headers: this.headerWithoutContentType
      };

    public getAuthenticationHeaderWithoutContentType(): any {
        return this.httpOptionsWithoutContentType;
    }


    public setCurrentUser(userDetail): any {
        //localStorage.removeItem('Authorization');
        localStorage.setItem('Authorization', 'Bearer ' + userDetail.jwtToken);
        localStorage.setItem('Email', userDetail.email);
        localStorage.setItem('Name', userDetail.title + " " + userDetail.firstName + " " + userDetail.lastName);
        localStorage.setItem('UserId', userDetail.id);
        localStorage.setItem('UserRoleId', userDetail.userRoleId);
        localStorage.setItem('ProfileCode', userDetail.profileCode);
        localStorage.setItem('Latitude', userDetail.latitude);
        localStorage.setItem('Longitude', userDetail.longitude);

        return true;
    }

    public removeLogin() {
        localStorage.removeItem('Authorization');
        localStorage.removeItem('Email');
        localStorage.removeItem('Name');
        localStorage.removeItem('UserId');
        localStorage.removeItem('UserRoleId');
        localStorage.removeItem('ProfileCode');
        localStorage.removeItem('Latitude');
        localStorage.removeItem('Longitude');
        localStorage.removeItem('SelectedCopyWriting');
        
        this.router.navigate(['/login']);
    }

    public setCopyWriting(selectedCopyWriting) {
        localStorage.removeItem('SelectedCopyWriting');
        localStorage.setItem('SelectedCopyWriting', selectedCopyWriting);
    }

    public getCopyWriting() {
        return localStorage.getItem('SelectedCopyWriting');
    }

    public getUserId() {
        return localStorage.getItem('UserId');
    }

    public getProfileCode() {
        return localStorage.getItem('ProfileCode');
    }

}
