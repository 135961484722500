import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { LogoutModel } from '../../auth/auth.model';
import { AuthService } from '../../auth/auth.service';
import { LocalStorageService } from '../../shared/service/local.storage.service';

@Component({
    selector: 'back-end-header',
    templateUrl: './back-end-header.component.html',
    styleUrls: ['./back-end-header.component.css']
})

export class BackEndHeaderComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;
    logoutModel: LogoutModel = new LogoutModel();
    loginedUserFullName: string;
    currentUserRole: string;

    constructor(
        public authService: AuthService, 
        private localStorageService: LocalStorageService
    ) 
    { 

    }

    ngOnInit() {
        this.loginedUserFullName = this.localStorageService.getCurrentUserFullName();
        this.currentUserRole = this.localStorageService.getCurrentUserRole();
    }

    logout() {        
        try {
            this.blockUI.start("Logout Processing.");
            this.logoutModel.userId=this.localStorageService.getUserId();
            
            this.authService.onLogout(this.logoutModel).subscribe(response => {
                this.localStorageService.removeLogin();
                this.blockUI.stop();
            }, error => { 
                this.blockUI.stop(); 
            });            
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
}