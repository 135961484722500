import { Component, OnInit, Input } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router} from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { AdminService} from '../admin.service';
import { GeneratedImageModel, GeneratedImageDetailsModel} from '../admin.model';
import { SearchModel } from '../../../app/shared/model/search.model';

declare var $: any;

 @Component({
 selector: 'user-image',
 templateUrl: './user-image.component.html',
 styleUrls: ['./user-image.component.css']
 })
 export class UserImageComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 
   
    @Input() userId = ''; 
    
    userGeneratedImages: GeneratedImageModel[];
    generatedImage: GeneratedImageModel = new GeneratedImageModel();
    generatedImagesDetails: GeneratedImageDetailsModel[];
    
    errorMessage = "";
    successMessage = "";

    constructor(
        private router: Router,
        private service: AdminService, 
        private localStorageService: LocalStorageService,
    ) {
        
    }

    ngOnInit() {
        this.searchModel.searchString = "";
        this.pagingConfiguration.currentPage = 1;

        this.getImages(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                        this.searchModel.searchString, this.order, this.orderBy);
    }
    

    getImages(pageSize: number, pageNo: number, searchString: string, orderBy: string, order: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getImages(this.userId, pageSize, pageNo, searchString, orderBy, order).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.userGeneratedImages = val.data;
                    this.pagingConfiguration.totalItems = val.totalRowsCount;
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    showImageDetails(imageId: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getImage(imageId).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {     
                    this.generatedImage = val.data;
                    this.generatedImagesDetails = val.data.generatedImageDetails;
                    $('#imageDetailsModal').modal('show');
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    //------------------------------------Data Table Declaration---------------------------------

    order = "";
    orderBy = "";

    col1Arrow=0;
    col2Arrow=0;
    col3Arrow=0;
    col4Arrow=0;

    searchModel: SearchModel = new SearchModel();

    pagingConfiguration = {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0
    };

    selectItemPerPage(ev) {
        try {
            this.pagingConfiguration.currentPage = 1;
            this.pagingConfiguration.itemsPerPage = ev.target.value;
            this.getImages(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, 
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {
        }
    }

    searchByString(ev) {
        try {
            this.pagingConfiguration.currentPage = 1;
            this.getImages(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {
        }
    }
   
    pageChange(newPage: number) {
        try {
            this.pagingConfiguration.currentPage = newPage;
            this.getImages(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, 
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {
        }
    }

    sorting(col: number) {
        try {           
            if (col == 1) {
                this.pagingConfiguration.currentPage = 1;
                this.col2Arrow=0; 
                this.col3Arrow=0;
                this.col4Arrow=0;
                if(this.col1Arrow==0 || this.col1Arrow==1){
                    this.col1Arrow=2;
                    this.orderBy = "Asc";                   
                }else if(this.col1Arrow==2){
                    this.col1Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Prompt";
            }else if (col == 2) {
                this.col1Arrow=0; 
                this.col3Arrow=0;
                this.col4Arrow=0;
                if(this.col2Arrow==0 || this.col2Arrow==1){
                    this.col2Arrow=2;
                    this.orderBy = "Asc";
                }else if(this.col2Arrow==2){
                    this.col2Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "No of Images";
            }
            else if (col == 3) {
                this.col1Arrow=0; 
                this.col2Arrow=0; 
                this.col4Arrow=0;
                if(this.col3Arrow==0 || this.col3Arrow==1){
                    this.col3Arrow=2;
                    this.orderBy = "Asc";
                }else if(this.col3Arrow==2){
                    this.col3Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Size of Images";
            }
            else if (col == 4) {
                this.col1Arrow=0; 
                this.col2Arrow=0; 
                this.col3Arrow=0;

                if(this.col4Arrow==0 || this.col4Arrow==1){
                    this.col4Arrow=2;
                    this.orderBy = "Asc";
                }else if(this.col4Arrow==2){
                    this.col4Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Created At";
            }
            this.getImages(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {
        }
    }

 }