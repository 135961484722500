<div class="row" style="margin-top: 65px;">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xxl-4"></div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 col-xxl-4">

        <!--begin::Portlet-->
        <div class="card  box-shadow">

            <h5 class="card-header d-flex justify-content-between align-items-center  text-dark">
                Reset Password        
            </h5>
            
            <div class="card-body">

                <!--begin::Form-->
                <form [formGroup]="resetPasswordForm" >  
                    
                    <div class="mb-2">
                        <label for="newPassword">New Password</label>
                        <input type="password" formControlName="newPassword" class="form-control"  
                            [ngClass]="{ 'is-invalid': submitted && resetPasswordFromControlStatus.newPassword.errors }" />
                        <div *ngIf="submitted && resetPasswordFromControlStatus.newPassword.errors" class="invalid-feedback">
                            <div *ngIf="resetPasswordFromControlStatus.newPassword.errors.required">Password is required</div>
                            <div *ngIf="resetPasswordFromControlStatus.newPassword.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <label for="confirmPassword">Confirm Password</label>
                        <input type="password" formControlName="confirmPassword" class="form-control"  
                            [ngClass]="{ 'is-invalid': submitted && resetPasswordFromControlStatus.confirmPassword.errors }" />
                        <div *ngIf="submitted && resetPasswordFromControlStatus.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="resetPasswordFromControlStatus.confirmPassword.errors.required">Password is required</div>
                            <div *ngIf="resetPasswordFromControlStatus.confirmPassword.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                    </div>   

                    <div class="mb-2" style="text-align: center;">
                        <h5 class="fail_message" *ngIf="errorMessage!=''">{{errorMessage}}</h5>
                        <h5 class="success_message" *ngIf="successMessage!=''">{{successMessage}}</h5>
                    </div> 

                    <div class="col-auto" style="text-align: center;">
                        <button [disabled]="resetPasswordForm.pristine || resetPasswordForm.invalid" 
                            (click)="resetPasswordConfirmation()" type="button" class="btn btn-dark">Reset</button>
                        <button (click)="goBack()"  class="btn  btn-dark" style="margin-left: 1%;">Cancel</button>
                    </div>

                </form>   
                <!--end::Form-->

            </div>

        </div>
        <!--end::Portlet-->

    </div>
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xxl-4"></div>
</div>









