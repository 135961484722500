import { Component, OnInit} from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';
import {ToolsService} from '../tools.service';
import {AskedOrSearchModel, ChatWithFileModel} from '../tools.model';
import {EmbeddingsFile} from '../tools.model';

 @Component({
 selector: 'chat-with-file',
 templateUrl: './chat-with-file.component.html',
 styleUrls: ['./chat-with-file.component.css']
 })

 export class ChatWithFileComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;  
    public entryForm: FormGroup;
    public uploadForm: FormGroup;
    public selectedTemplate:any;
    public availableEmbeddedFiles:any;
 
    uploadFileFromData: FormData = new FormData();
    entryModel: AskedOrSearchModel = new AskedOrSearchModel();
    embeddingsFileModel: EmbeddingsFile= new EmbeddingsFile(); 
    messageList: ChatWithFileModel[];
    fileUrl;

    public gptModelForm: FormGroup;

    id: string;
    pdfFileSanitizedUrl: any;
    isFileUploaded = false;
    insertOperation = true;
    isFileAvailable = false;
    submitted = false;
    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";
    get entryFromControlStatus() { return this.entryForm.controls; }   
    generatedChatIndex=0;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder, 
        private service: ToolsService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private clipboard: Clipboard,
        private domSanitizer: DomSanitizer
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Chat with File");
     }

    ngOnInit() {

        this.getChatWithFileList();
        
        this.entryForm = this.formBuilder.group({
            askedOrSearch: ['', [Validators.required, Validators.maxLength(200)]]
        });

        this.uploadForm = this.formBuilder.group({
            uploadFile: ['', [Validators.required]]
        });

        this.gptModelForm = this.formBuilder.group({
            gptModel: ['', [Validators.required, Validators.maxLength(50)]]
        });
        this.gptModelForm.get('gptModel').setValue('gpt-4-1106-preview');

    }

    goBack() {
        this.location.back();
    };

    get uploadFromControlStatus() { return this.uploadForm.controls; }

    clear() {
        try {
            this.id = "";
            this.entryForm.get('askedOrSearch').setValue('');
            this.submitted=false;
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.insertUpdateMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    onFileSelected(event) {
        try {
            if (this.uploadForm.invalid) { return; }

            var element = document.getElementById(this.generatedChatIndex.toString());
            if(element !=null)
                element.classList.remove("active");
            
            const file:File = event.target.files[0];            
            if (file) {                         
                this.uploadFileFromData.append('file', file, file.name); 

                //Tempurary it is static. will make it dynamic on next release. 11/12/2023
                this.uploadFileFromData.append('gptModel','gpt-3.5-turbo-instruct');
                //this.uploadFileFromData.append('gptModel', this.gptModelForm.get('gptModel').value);

                //here we can pass multiple payloads. example: Id, Name, email etc
                //this.uploadFileFromData.append('id', "100200300");

                this.uploadFile();
            }         
        }
        catch (ex) {
        }
    }

    uploadFile() {
        try {

            if (this.uploadForm.invalid) { return; }

            this.blockUI.start("Uploading your file...");

            this.submitted = true;
            
            this.service.uploadFile(this.uploadFileFromData).subscribe(response => {
                this.clear();
                var val = response;
                this.blockUI.stop();
                if (val.isSucceed == true) {
                    this.successMessage = val.message;
                    this.embeddingsFileModel = val.data;
                    this.pdfFileSanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.embeddingsFileModel.fileName);
                    this.isFileAvailable=true;                    
                    this.isFileUploaded=true;                                
                    this.getChatWithFileList();
                }  else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    getChatWithFileList() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getChatWithFileList().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.availableEmbeddedFiles = val.data;
                } else {
                    this.errorMessage = val.errors[0].value;
                    //alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
    get availableEmbeddedFilesDetails() { return (this.availableEmbeddedFiles) ? this.availableEmbeddedFiles : null }

    getFileInfo(id: string, index: number) {
        try {
            this.blockUI.start("Loading....");
            this.service.getFileInfo(id).subscribe(response => {
                var val = response;
                if (val.isSucceed == true) {
                    this.embeddingsFileModel.id=val.data.fileId;
                    this.embeddingsFileModel.project=val.data.project;
                    this.embeddingsFileModel.userId=val.data.userId;
                    this.embeddingsFileModel.fileId=val.data.fileId;
                    this.embeddingsFileModel.fileName=val.data.fileName;
                    this.embeddingsFileModel.Messages = val.data.chatWithFile;
                    this.embeddingsFileModel.embeddingsFileSampleQuestion = val.data.embeddingsFileSampleQuestion;

                    this.pdfFileSanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(val.data.fileName);

                    this.isFileAvailable=true;
                    
                    this.messageList=[];
                    for (var val of val.data.chatWithFile) {
                        var message = new ChatWithFileModel();
                        message.role=val.role;
                        message.content= val.message;
                        message.pageNo= val.pageNo;
                        message.createdAt = val.createdAt;
                        this.messageList.push(message);
                    }

                    this.generatedChatIndex = index;

                    this.processDownloadableData();

                }else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    askThisQuestion(id: string, question: string) {
        try {
            this.entryForm.get('askedOrSearch').setValue(question);
            this.send();
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    processDownloadableData(){
        var data = '';        
        for (var val of this.messageList) {
            data = data + val.role + ": " + val.content + "\n"; // + "         " + val.pageNo + "\n\n";
        }
        const blob = new Blob([data], { type: 'application/octet-stream' });
        this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }

    send() {
        try {
            
            this.submitted = true;
            if (this.entryForm.invalid) { return; }

            this.blockUI.start("Getting answer from file...");

            //Temp data
            // this.embeddingsFileModel.id="3b1248fa-33d2-4682-b45e-a646e6220fa6";
            // this.embeddingsFileModel.project="GPT Ghor";
            // this.embeddingsFileModel.userId="81e764d5-dcaf-4047-869d-f4ef278c05ad";
            // this.embeddingsFileModel.fileId="fbc2e8e9-3326-4d8b-bb46-ec4356eec920";
            // this.embeddingsFileModel.fileName="fbc2e8e9-3326-4d8b-bb46-ec4356eec920.pdf";

            this.embeddingsFileModel.askedOrSearch = this.entryForm.get('askedOrSearch').value;
            this.embeddingsFileModel.gptModel = this.gptModelForm.get('gptModel').value;

            if(this.embeddingsFileModel.Messages=== undefined || this.embeddingsFileModel.Messages.length== 0)
                this.embeddingsFileModel.Messages =[];

            if(this.messageList=== undefined)
                this.messageList=[];

            var userMessage = new ChatWithFileModel();
            userMessage.role= "user";
            userMessage.content= this.entryForm.get('askedOrSearch').value;
            userMessage.createdAt= new Date();
            userMessage.pageNo= "";
            this.messageList.push(userMessage);

            this.service.queryByVector(this.embeddingsFileModel).subscribe(response => {
                this.clear();
                var val = response;
                this.blockUI.stop();
                if (val.isSucceed == true) {
                    
                    var assistantMessage = new ChatWithFileModel();
                    assistantMessage.role= "assistant";
                    assistantMessage.content= val.data.message;
                    assistantMessage.pageNo= val.data.pageNo;
                    assistantMessage.createdAt= val.data.createdAt;
                    this.messageList.push(assistantMessage);

                    this.successMessage = val.message;
                } else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    copyText(index: number) {
        this.clipboard.copy(this.embeddingsFileModel.Messages[index].message);
        alert("Copied to clipboard.");
    }
      
 }