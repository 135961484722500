
<div class="row" style="margin-top: 65px;">
  <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xl-4"></div>
  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 col-xl-4">
    <div class="card  box-shadow">
            
      <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
          Reset your password        
          <a href="javascript:void(0);"  (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
              class="bi bi-arrow-return-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
            </svg>
          </a>
      </h5>
      
      <div class="card-body">           
        <form [formGroup]="forgetPasswordForm">
              
          <div class="mb-2">
            <label for=emailAddress class="form-label">Email Address</label>
            <input type="email" id="emailAddress" formControlName="email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && forgetPasswordFromControlStatus.email.errors }"
                aria-describedby="emailHelp"/>
            <div *ngIf="submitted && forgetPasswordFromControlStatus.email.errors"
              class="invalid-feedback">
              <div *ngIf="forgetPasswordFromControlStatus.email.errors.required">Email is required</div>
              <div *ngIf="forgetPasswordFromControlStatus.email.errors.email">Email must be a valid email address</div>
            </div>               
          </div> 

          <div class="mb-2">
            <div style="text-align: center;">
              <h5 class="fail_message" *ngIf="errorMessage!=''">{{errorMessage}}</h5>
              <h5 class="success_message" *ngIf="successMessage!=''">{{successMessage}}</h5>
            </div>              
          </div>

          <div class="col-auto" style="text-align: center;">
            <button [disabled]="forgetPasswordForm.pristine || forgetPasswordForm.invalid"
              (click)="resetPasswordRequest()" type="button" class="btn btn-dark me-2">Reset</button>
            <button (click)="goBack()" class="btn btn-dark">Back</button>
          </div>

        </form>          
      </div>

    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 col-xl-4"></div>
</div>












