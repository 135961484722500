
import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { Router } from '@angular/router';

import { ApiUrl } from 'src/app/shared/service/api.service';
import { LocalStorageService } from '../../shared/service/local.storage.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    constructor(private titleService: Title, private router: Router, private localStorageService: LocalStorageService) {
        this.titleService.setTitle(ApiUrl.baseTitle+ "Home");
    }

    ngOnInit() {
        const currentUserRole = this.localStorageService.getCurrentUserRole();

        if (currentUserRole == "42ff5dad-3c27-4c97-a3a7-c3d44b67abb3")
            this.router.navigate(['/tools']); 
        else if (currentUserRole == "42ff5dad-3c27-4c97-a3a7-c3d44b67abb2")
            this.router.navigate(['/admin/user-list']);
    }

}