
  export class LanguageModelPrice {
    copywriting: GPTModel;
    chatWithAI: GPTModel;
    chatWithFile: ChatWithFileModel;
    imageModel: ImageModel[];
  }

  export class GPTModel{
      noOfTokens: number;
      inputPrice: number;
      outputPrice: number;
  }

  export class ImageModel
  {
    resolution: string;
    price: number;
  }
  
  export class ChatWithFileModel
  {
    fileProcessingUsage: FileProcessingUsage;
    chatWithFileUsage: GPTModel;
  }
  
  export class FileProcessingUsage
  {
    noOfTokens: number;
    usage: number;
  }