
<div class="row" style="margin-top: 65px;">

    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 mb-2">

        <div class="card box-shadow">

            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                Users Details
                <a href="javascript:void(0);" (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                      class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
                    </svg>
                  </a>         
            </h5>
            
            <div class="card-body">
                <div class="card-body">
                    
                    <div class="row mb-2">

                        <div class="col-12 mb-2">
                            <div>Name</div>
                            <strong>{{userDetailsModel.firstName}} {{userDetailsModel.lastName}}</strong>
                        </div>

                        <div class="col-12 mb-2">
                            <div>Email</div>
                            <strong>{{userDetailsModel.email}}</strong>
                        </div>

                        <div class="col-12 mb-2">
                            <div>Phone number</div>
                            <strong>{{userDetailsModel.phoneNumber}}</strong>
                        </div>

                        <div class="col-12 mb-2">
                            <div>Created At</div>
                            <strong>{{userDetailsModel.createdAt | date}} {{userDetailsModel.createdAt | date :'h:mm a'}}</strong>
                        </div>

                        <div class="col-12 mb-2">
                            <div>Last Logined At</div>
                            <strong>{{userDetailsModel.lastLoginAt | date}} {{userDetailsModel.lastLoginAt | date :'h:mm a'}}</strong>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>

    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 col-xxl-10">
        <user-chat-with-file [userId]="userId"></user-chat-with-file>
        <user-image [userId]="userId"></user-image>
        <user-copywriting [userId]="userId"></user-copywriting>
        <user-chat-with-ai [userId]="userId"></user-chat-with-ai>
        <user-billing [userId]="userId"></user-billing>
        <user-usage [userId]="userId"></user-usage>
    </div>

</div>