
<div class="row" style="margin-top: 65px;">

    <div class="col-3 mb-2">

        <div class="card box-shadow">           
            
            <div class="card-body">
                                
                <div class="row mb-2">
    
                    <div class="col-12">
                        <div class="image-generation-btn-wrapper">
                            <button class="image-generation-btn" (click)="startNewImageGeneration()">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="plus" 
                                width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <defs><style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg>
                                <br/>
                                <span class="d-none d-sm-block">Generate A New</span>
                            </button>
                        </div>                               
                    </div>   
                    
                </div>

                <div class="list-group image-list-div-height" id="list-tab" role="tablist" style="--bs-list-group-border-color: none; --bs-list-group-border-radius: 0px;">       
                    <a id="{{i}}" style="border-radius: 5px;" *ngFor="let availableImage of availableImages;  index as i" 
                        class="list-group-item list-group-item-action mb-1" id="list-home-list" 
                        data-bs-toggle="list" href="javascript:void(0);"  (click)="getImagedetails(availableImage.id, i)" role="tab" aria-controls="list-home">              
                        
                            <div class="row">
                                <div class="col-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>                                                   
                                </div>
                                <div class="col-10 d-none d-sm-block">
                                    <!-- {{availableImage.createdAt | date}}  -->
                                    {{ (availableImage.prompt.length>60)? 
                                        (availableImage.prompt  | slice:0:60)+'...'
                                        :(availableImage.prompt ) }}
                                </div>
                            </div>                                                                        
                               
                    </a>    
                </div>

            </div>
        
        </div>

    </div>

    <div *ngIf="isNewImageProcessingStarted==true || isViewingGeneratedImages==true" class="col-4 mb-2">
        <div class="card box-shadow">
            
            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">  
                Generate Image                
                <a href="javascript:void(0);" (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                </a>
            </h5>
            
            <div class="card-body image-div-height">
                <div class="row">

                    <form [formGroup]="this.entryForm">

                        <div class="row">

                            <div class="col-12 mb-2">

                                <div  [hidden]="!this.isViewingGeneratedImages == true" class="row">
                                    <label for=prompt>Image type:</label>
                                    <div><strong>{{generatedImagesType}}</strong>
                                    
                                    </div>
                                </div>

                                <div  [hidden]="this.isViewingGeneratedImages == true" class="row">

                                    <div class="col-2"></div>

                                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" value="Create New" name="type" id="type1" (change)="selectType($event)" checked>
                                            <label class="form-check-label" for="type1">
                                              New
                                            </label>
                                          </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" value="Create Variation" name="type" id="type2" (change)="selectType($event)">
                                            <label class="form-check-label" for="type2">
                                              Variation
                                            </label>
                                          </div>
                                    </div>

                                    <div class="col-2"></div>

                                </div>
                                                              
                            </div>

                            <div [hidden]="this.entryModel.type == 'Create Variation'" class="col-12 mb-2">
                                <label for=prompt>Describe your image:</label>
                                <textarea id="prompt" formControlName="prompt" class="form-control"  rows="4" cols="50"
                                    [ngClass]="{ 'is-invalid': submitted && entryFromControlStatus.prompt.errors }" 
                                    placeholder="A cow in space, painting by van Gogh"></textarea>
                                <div *ngIf="submitted && entryFromControlStatus.prompt.errors" class="invalid-feedback">
                                    <div *ngIf="entryFromControlStatus.prompt.errors.required">Prompt is required</div>
                                    <div *ngIf="entryFromControlStatus.prompt.errors.maxlength">Name maximum length is 200</div>
                                </div>
                            </div>
                            
                            <div class="col-12 mb-2">
                                <label for=noOfImages>Model:</label>
                                <select id="gptModel" formControlName="gptModel" class="form-select" 
                                (change)="selectModel($event)" aria-label=".form-select-sm example">
                                    <option *ngIf="this.entryModel.type=='Create New'" value="dall-e-3" selected>DALL·E 3</option>
                                    <option value="dall-e-2">DALL·E 2</option>
                                </select>
                            </div>

                            <div *ngIf="entryModel.model == 'dall-e-3'" class="col-12 mb-2">
                                <label for=quality>Quality:</label>
                                <select id="quality" formControlName="quality" class="form-select" 
                                aria-label=".form-select-sm example">
                                    <option value="standard" selected>Standard</option>
                                    <option value="hd">HD</option>
                                </select>
                            </div>

                            <div *ngIf="entryModel.model == 'dall-e-3'" class="col-12 mb-2">
                                <label for=style>Style:</label>
                                <select id="style" formControlName="style" class="form-select" 
                                aria-label=".form-select-sm example">
                                    <option value="vivid" selected>Vivid</option>
                                    <option value="natural">Natural</option>
                                </select>
                            </div>

                            <div [hidden]="this.entryModel.type == 'Create New'" class="col-12 mb-2">
                                <label for=prompt>Upload your image:</label>
                                <input class="custom-file-input form-control form-control-sm" type="file" (change)="selectImageFile($event)">
                            </div>                                                                               

                            <div class="col-12 mb-2">
                                <label for=noOfImages>Number of images (max. 4):</label>
                                <select id="noOfImages" formControlName="noOfImages" class="form-select" 
                                aria-label=".form-select-sm example">
                                    <option value="1" selected>1</option>
                                    <option *ngIf="this.entryModel.model == 'dall-e-2'" value="2">2</option>
                                    <option *ngIf="this.entryModel.model == 'dall-e-2'" value="3">3</option>
                                    <option *ngIf="this.entryModel.model == 'dall-e-2'" value="4">4</option>
                                </select>
                                <div *ngIf="submitted && entryFromControlStatus.noOfImages.errors" class="invalid-feedback">
                                    <div *ngIf="entryFromControlStatus.noOfImages.errors.required">No of images required</div>
                                </div>
                            </div>

                            <div class="col-12 mb-2">
                                <label for=sideOfImage>Size of image(s):</label>
                                <select id="sideOfImage" formControlName="sideOfImage" class="form-select" aria-label="Default select example"
                                [ngClass]="{ 'is-invalid': submitted && entryFromControlStatus.sideOfImage.errors }">

                                    <option value="1024x1024">1024x1024</option>
                                    <option *ngIf="entryModel.model == 'dall-e-3'" value="1024x1792">1024x1792</option>
                                    <option *ngIf="entryModel.model == 'dall-e-3'" value="1792x1024">1792x1024</option>

                                    <option *ngIf="entryModel.model == 'dall-e-2'" value="256x256">256x256</option>
                                    <option *ngIf="entryModel.model == 'dall-e-2'" value="512x512">512x512</option>

                                  </select>
                                    <div *ngIf="submitted && entryFromControlStatus.sideOfImage.errors" class="invalid-feedback">
                                    <div *ngIf="entryFromControlStatus.sideOfImage.errors.required">Size of image(s) required</div>
                                </div>
                            </div>

                            <div class="col-12 mb-2">
                                <button [hidden]="!isNewImageProcessingStarted" [disabled]="entryForm.invalid" (click)="generateImage()" 
                                    type="button" class="btn btn-dark btn-elevate btn-pill">Generate</button>
                            </div>

                        </div>
    
                    </form>
                    
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="isNewImageProcessingStarted==true || isViewingGeneratedImages==true" class="col-5">
        <div class="card box-shadow">

            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                Generated Image(s)                    
            </h5>
            
            <div class="card-body image-div-height">

                <div class="row">     

                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4" *ngFor="let image of imageDetails; let indexOfelement=index;">

                        <div class="mb-2">
                            <a href="{{image.url}}" class="btn btn-dark" target="_blank">Download</a>
                        </div>

                        <div class="col-12">
                            <img src="{{image.url}}" class="img-fluid rounded box-shadow"  alt="Image {{indexOfelement+1}}" >
                        </div>
                    
                    </div>

                </div>

            </div>

        </div>
    </div>

</div>