import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ApiUrl } from '../../shared/service/api.service';
import { EmailConfirmationModel } from '../auth.model';
import { AuthService } from '../auth.service';

@Component({
    selector: 'email-confirmation',
    templateUrl: './email-confirmation.component.html',
    styleUrls: ['./email-confirmation.component.css']
})
export class EmailConfirmationComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    emailConfirmationModel: EmailConfirmationModel = new EmailConfirmationModel();

    constructor(private titleService: Title, private authService: AuthService, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.emailConfirmationModel.token=params['token'];
        });
        this.titleService.setTitle(ApiUrl.baseTitle + "Email Confirmation");
    }

    ngOnInit() {
        this.emailConfirmation();
    }

    emailConfirmation() {
        try {

            this.blockUI.start("Email Verification Processing");          
            this.authService.emailConfirmation(this.emailConfirmationModel).subscribe(response => {
                var val = response;
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
}