import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { UtilityService } from '../../shared/service/utility.service';
import { ApiUrl } from '../../shared/service/api.service';
import {DashboardService} from '../dashboard.service';
import { LocalStorageService } from  '../../shared/service/local.storage.service';

 @Component({
 selector: 'overview',
 templateUrl: './overview.component.html',
 styleUrls: ['./overview.component.css']
 })

 export class OverviewComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    
    public selectedTemplate:any; 

    id: string;
    insertOperation = true;
    submitted = false;
    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";
   
    constructor(
        private formBuilder: FormBuilder, 
        private service: DashboardService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private utilityService: UtilityService,
        private location: Location,
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Dashboard");
     }

     ngOnInit() {
        // this.entryForm = this.formBuilder.group({
        //     askedOrSearch: ['', [Validators.required, Validators.maxLength(200)]]
        // });
    }

    goBack() {
        this.location.back();
    };

    create(id: number) {
        try {
            this.blockUI.start("Loading....");          
            
            // var filteredTemplate = this.templates.filter(obj => { return obj['id'] == id; });
            // this.localStorageService.setTemplate(JSON.stringify(filteredTemplate));

            this.blockUI.stop();
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    clear() {
        try {
            this.id = "";
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.insertUpdateMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    onInsert() {
        try {

            this.submitted = true;
            // if (this.entryForm.invalid) {
            //     return;
            // }

            this.blockUI.start("Generating Image...");

            //this.entryModel.askedOrSearch = this.entryForm.get('askedOrSearch').value;

            // this.service.Insert(this.entryModel).subscribe(response => {
            //     // this.clearMessage();
            //     // var val = response;
            //     // if (val.success == true) {
            //     //     this.successMessage = val.message;
            //     // } else {
            //     //     this.errorMessage = val.message;
            //     // }
            //     this.blockUI.stop();
            // }, error => {
            //     this.blockUI.stop();
            // });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

 }