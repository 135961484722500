import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';// 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { ApiUrl } from '../shared/service/api.service';
import { LocalStorageService } from '../shared/service/local.storage.service';
import { UserDetailsModel } from './admin.model';

@Injectable({
    providedIn: 'root' /* by using providerIn we don't need to import service on provider array in module */
})

export class AdminService{

    constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

    //-------------------------------Tools Item--------------------------------------------------

    getUserAccountSummary(userId: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetUserAccountSummary/?UserId='+userId);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }
    
    getUserList(pageSize: number, pageNo: number, searchString: string, 
        orderBy: string, order: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetUserList/?pageSize='+pageSize 
        + '&pageNo=' + pageNo + '&searchString='+ searchString
        + '&orderBy=' + orderBy + '&order=' +order);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //----------------------------------End------------------------------------------------------

    //----------------------------------Image------------------------------------------------------

    getImages(userId: string, pageSize: number, pageNo: number, searchString: string, 
        orderBy: string, order: string): Observable<any> {             
        const url = (ApiUrl.baseUrl + 'Admin/GetImages/?UserId='+userId + '&PageSize=' + pageSize
        + '&pageNo=' + pageNo + '&searchString='+ searchString
        + '&orderBy=' + orderBy + '&order=' +order);
                
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    getImage(imageId: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetImage/?Id='+imageId);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //-----------------------------------End-------------------------------------------------------


    //-----------------------------------Copywriting------------------------------------------------------

    getCopywritings(userId: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetGeneratedCopywritings/?UserId='+userId);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    getCopywritingDetails(id: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetCopywriting?Id='+id);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //------------------------------------End------------------------------------------------------


    //-----------------------------------Chat with AI------------------------------------------------------

    getChats(userId: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetChats/?UserId='+userId);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    getChatDetails(userId: string, createDate: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetChatDetails?UserId=' + userId + '&CreateDate='+createDate);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //------------------------------------End------------------------------------------------------

    //-----------------------------------Chat With PDF------------------------------------------------------

    getChatWithFileList(userId: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetEmbeddingsFileList/?UserId='+userId);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    getChatWithFileDetails(id: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetEmbeddingsFileChatDetails?FileEmbeddingId='+id);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //------------------------------------End---------------------------------------------------------
   
    //-----------------------------------Billing------------------------------------------------------

    getBilling(userId: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Admin/GetBilling/?UserId='+userId);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //------------------------------------End---------------------------------------------------------

    //-----------------------------------Usage--------------------------------------------------------
    getUsage(userId: string, pageSize: number, pageNo: number, searchString: string, 
        orderBy: string, order: string): Observable<any> {  

        const url = (ApiUrl.baseUrl + 'Admin/GetUsage/?UserId='+userId + '&pageSize=' + pageSize
        + '&pageNo=' + pageNo + '&searchString='+ searchString
        + '&orderBy=' + orderBy + '&order=' +order);

        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //-----------------------------------End----------------------------------------------------------

    
}