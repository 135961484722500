
export class UserDetailsModel {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;  
  createdAt: Date;
  lastLoginAt: Date;
}

export class GeneratedImageModel {
  id: string;
  prompt: string;
  noOfImages: string;
  sizeOfImages: string;
  type: string;  
  createdAt: Date;
}

export class GeneratedImageDetailsModel {
  id: string;
  generatedImageId: string;
  url: string;
}

export class GeneratedCopywritingsModel {
  id: string;
  prompt: string;
  generatedContent: string;
  copywritingName: string;  
  createdAt: Date;
}

export class GeneratedCopywritingDetailsModel {
  id: string;
  prompt: string;
  generatedContent: string;
  copywritingName: string;
  createdAt: Date;
}

export class GeneratedChatWithAIModel {
  id: string;
  message: string;
  createdAt: Date;
}

export class GeneratedChatWithAIDetailsModel {
  id: string;
  role: string;
  message: string;
}

export class GeneratedChatWithPDFModel {
  id: string;
  project: string;
  fileName: string;
  createdAt: Date;
}

export class GeneratedChatWithPDFDetailsModel {
  id: string;
  role: string;
  message: string;
  pageNo: string;
}

export class BillingModel {
  id: string;
  amountUSD: number;
  amountBDT: number;
  copywritingName: string;  
  transactionDate: Date;
  transactionStatus: string;
}

export class UsageModel {
  id: string;
  toolsName: string;
  noOfTokens: number;
  usageAmountUSD: string;
  usageAmountBDT: string; 
  transactionDate: Date;
  transactionStatus: string;
}