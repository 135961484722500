import { Component, OnInit, Input } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { AdminService} from '../admin.service';
import {  GeneratedChatWithAIModel, GeneratedChatWithAIDetailsModel} from '../admin.model';

declare var $: any;

 @Component({
 selector: 'user-chat-with-ai',
 templateUrl: './user-chat-with-ai.component.html',
 styleUrls: ['./user-chat-with-ai.component.css']
 })
 export class UserChatWithAIComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 
   
    @Input() userId="";

    userGeneratedChatWithAI: GeneratedChatWithAIModel[];
    generatedChatWithAIDetailsModel: GeneratedChatWithAIDetailsModel[];
    
    errorMessage = "";
    successMessage = "";
    
    constructor(
        private router: Router,
        private service: AdminService, 
        private localStorageService: LocalStorageService,
        private clipboard: Clipboard
    ) {

     }

     ngOnInit() {
         this.getChats(this.userId);
    }

    getChats(userId: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getChats(userId).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.userGeneratedChatWithAI = val.data;
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    showchatWithAIDetails(userId: string, createDate: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getChatDetails(userId, createDate).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {     
                    this.generatedChatWithAIDetailsModel = val.data;
                    $('#chatWithAIDetailsModal').modal('show');
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    copyText(index: number) {
        this.clipboard.copy(this.generatedChatWithAIDetailsModel[index].message);
        alert("Copied to clipboard.");
    }

 }