export class ApiUrl {
    static serverMode = false;
    //static localUrl = 'https://localhost:7280/api/';
    static localUrl = 'https://api.gptghor.com/api/';
    static prodUrl = 'https://104.236.63.203:6005/api/';
    static baseTitle = 'GPT Ghor | ';
    // static baseUrl: string = ApiUrl.localUrl;
  
    /* this is for production or development url */
    static prodMode = false;
    static baseUrl: string = ApiUrl.prodMode === true ? ApiUrl.prodUrl : ApiUrl.localUrl;
    static MASTER_URI_PORT = ApiUrl.prodMode === true ? ':3000/api/' : ':4055/';  
    static MASTER_URI = ApiUrl.baseUrl + ApiUrl.MASTER_URI_PORT;
  }
  