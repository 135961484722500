import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../auth.service';
import { RegistrationModel } from '../auth.model';
import { ApiUrl } from '../../shared/service/api.service';
import { UtilityService } from '../../shared/service/utility.service';
import { LocalStorageService } from '../../shared/service/local.storage.service';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css']
})

export class RegistrationComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;
    
    registration: RegistrationModel = new RegistrationModel();
    registrationForm: FormGroup;
    
    submitted = false;
    errorMessage = "";
    successMessage = "";

    constructor(
        private formBuilder: FormBuilder, 
        private authService: AuthService,
        private router: Router, 
        private location: Location,
        private localStorageService: LocalStorageService,
        private utilityService: UtilityService, 
        private titleService: Title
    ) 
    {
        this.titleService.setTitle(ApiUrl.baseTitle + "Registration");
    }

    ngOnInit() {
        const currentUser = this.localStorageService.getCurrentUser();
        if (currentUser) {
            this.router.navigate(["tools"]);
        }
        else{
            this.registrationForm = this.formBuilder.group({
                firstName: ['', [Validators.required, Validators.maxLength(100)]],
                lastName: ['', [Validators.required, Validators.maxLength(100)]],
                email: ['', [Validators.required, Validators.email]],
                phoneNumber: ['', [Validators.required]],
                password: ['', [Validators.required, Validators.minLength(6)]],
                confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
                acceptTerms: [false, Validators.requiredTrue]
            });
        }
    }

    get registrationFromControlStatus() { return this.registrationForm.controls; }

    clear() {
        this.registrationForm.get('fullName').setValue('');
        this.registrationForm.get('email').setValue('');
        this.registrationForm.get('password').setValue('');
        this.registrationForm.get('patientManagement').setValue('');
        this.registrationForm.get('pharmacyManagement').setValue('');
        this.clearMessage();
    }

    clearMessage() {
        this.errorMessage = "";
        this.successMessage = "";
    }

    goBack() {
        this.location.back();
    }

    onRegistration() {
        try {

            this.submitted = true;
            if (this.registrationForm.invalid) {
                return;
            }
            

            this.blockUI.start("Registration Processing");

            this.registration.title = "Mr.";
            this.registration.firstName = this.registrationForm.get('firstName').value;
            this.registration.lastName = this.registrationForm.get('lastName').value;
            this.registration.email = this.registrationForm.get('email').value;
            this.registration.phoneNumber = this.registrationForm.get('phoneNumber').value;
            this.registration.password = this.registrationForm.get('password').value;
            this.registration.confirmPassword = this.registrationForm.get('confirmPassword').value;
            this.registration.acceptTerms = this.registrationForm.get('acceptTerms').value;
            
            this.authService.onRegistration(this.registration).subscribe(response => {
                this.clearMessage();
                var val = response;
                if (val.isSucceed == true) {
                    this.successMessage = val.message;
                    this.router.navigate(['/email-verification/' + this.registrationForm.get('email').value]);
                }else{
                    this.errorMessage = val.message;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

}