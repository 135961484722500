import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router'
import { Location } from '@angular/common';
import { Title }     from '@angular/platform-browser';

import {ToolsService} from '../tools.service';
import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';

 @Component({
 selector: 'tools-list',
 templateUrl: './tools-list.component.html',
 styleUrls: ['./tools-list.component.css']
 })
 export class ToolsListComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    
    public tools:any; 

    constructor(
        private router: Router,
        private service: ToolsService, 
        private localStorageService: LocalStorageService,
        private location: Location,
        private titleService: Title,
    ) { 
        this.titleService.setTitle(ApiUrl.baseTitle+ "Tools");
    }

    ngOnInit() {        
        this.getInformation();
    }

    getInformation() {
        try {
            this.blockUI.start("Loading....");          
            this.service.GetTools().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.tools = val.data;
                } else {
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                    window.location.reload();
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    goBack() {
        this.location.back();
    };

    create(id: number) {
        try {
            this.blockUI.start("Loading....");          
            
            var selectedTools = this.tools.filter(obj => { return obj['id'] == id; });   
            
            if(selectedTools[0].id==1)
                this.router.navigateByUrl("image-generation");   
            else if(selectedTools[0].id==2)
                this.router.navigateByUrl("chat-with-file");  
            else if(selectedTools[0].id==3)
                this.router.navigateByUrl("chat-with-ai");
            else if(selectedTools[0].id==4)
                this.router.navigateByUrl("copywriting");
            else if(selectedTools[0].id==5)
                this.router.navigateByUrl("audiobook");
            else if(selectedTools[0].id==6)
                this.router.navigateByUrl("vision");
            this.blockUI.stop();
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

 }