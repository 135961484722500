
<div class="col-12 mb-2">

    <div class="card box-shadow">

        <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
            Copywritings      
        </h5>
        
        <div class="card-body">
            <div class="card-body">
                <div class="table-responsive table-fixed-head mh-37 mh-lg-27">
                    <table class="table table-sm table-centered table-striped table-hover table-nowrap w-100 table-fixed-last-child use-datatable dt-responsive">
                        <thead>
                            <tr>
                                <th class="text-left">Prompt</th>
                                <th class="text-left">Generated Content</th>    
                                <th class="text-left">Copywriting Name</th>
                                <th class="text-left">Created At</th>                                                                       
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let copywriting of userGeneratedCopywritings; index as i">
                                <td class="text-left">{{copywriting.prompt}}</td>
                                <td class="text-left">{{copywriting.generatedContent}}</td>
                                <td class="text-left">{{copywriting.copywritingName}}</td>
                                <td class="text-left">{{copywriting.createdAt | date}} {{copywriting.createdAt | date :'h:mm a'}}</td>
                                <td class="text-center">
                                    <a href="javascript:void(0);" (click)="showCopywritingDetails(copywriting.id)" class="text-decoration-none text-dark"  title="View">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">                                         
                                            <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
                                      </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

</div>

<!-- Copywriting Modal -->
<div class="modal fade  modal-lg" id="copywritingDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
    <div class="modal-content">

        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Copywriting Details</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-4 mb-4">

            <div class="row">

                <div class="col-12">

                    <div class="card box-shadow">
                        
                        <div class="card-body">
                            <div class="card-body">
                                
                                <div class="row mb-2">
            
                                    <div class="col-12 mb-2">
                                        <strong>Prompt</strong>
                                        <div>{{generatedCopywritingDetailsModel.prompt}}</div>
                                    </div>
            
                                    <div class="col-12 mb-2">
                                        <strong>Generated Content</strong>
                                        <div>{{generatedCopywritingDetailsModel.generatedContent}}</div>
                                    </div>
            
                                    <div class="col-12 mb-2">
                                        <strong>Copywriting Name</strong>
                                        <div>{{generatedCopywritingDetailsModel.copywritingName}}</div>
                                    </div>
    
                                    <div class="col-12 mb-2">
                                        <strong>Created At</strong>
                                        <div>{{generatedCopywritingDetailsModel.createdAt | date}} {{generatedCopywritingDetailsModel.createdAt | date :'h:mm a'}}</div>
                                    </div>
            
                                </div>
            
                            </div>
                        </div>
            
                    </div>
            
                </div>


            </div>

        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
    </div>
    </div>
</div>