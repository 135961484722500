import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//My Dashboard component
import { CopywritingListComponent } from './copywriting-list/copywriting-list.component';
import {CreateCopyWritingComponent} from './create-copywriting/create-copywriting.component';

@NgModule({
  declarations: [
    CopywritingListComponent,
    CreateCopyWritingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,
  ],

})

export class CopyWriteModule { }
