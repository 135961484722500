import { Component, OnInit, Input } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import {  UsageModel} from '../dashboard.model';
import {DashboardService} from '../dashboard.service';

import { SearchModel } from '../../../app/shared/model/search.model';

declare var $: any;

 @Component({
 selector: 'user-usage',
 templateUrl: './user-usage.component.html',
 styleUrls: ['./user-usage.component.css']
 })
 export class UserUsageComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 
    @Input() userId="";

    userUsage: UsageModel[];
    usageTotalUsageAmountUSD: number;
    usageTotalUsageAmountBDT: number;

    errorMessage = "";
    successMessage = "";
    
    constructor(
        private router: Router,
        private service: DashboardService, 
        private localStorageService: LocalStorageService
    ) {

     }

     ngOnInit() {
        this.searchModel.searchString = "";
        this.pagingConfiguration.currentPage = 1;

        this.getUsage(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                        this.searchModel.searchString, this.order, this.orderBy);
    }

    getUsage(pageSize: number, pageNo: number, searchString: string, orderBy: string, order: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getUsage(pageSize, pageNo, searchString, orderBy, order).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {    
                    this.usageTotalUsageAmountUSD=val.data.totalUsageAmountUSD;
                    this.usageTotalUsageAmountBDT=val.data.totalUsageAmountBDT;                 
                    this.userUsage = val.data.usageDetails;
                    this.pagingConfiguration.totalItems = val.totalRowsCount;
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    //------------------------------------Data Table Declaration---------------------------------

    order = "";
    orderBy = "";

    col1Arrow=0;
    col2Arrow=0;

    searchModel: SearchModel = new SearchModel();

    pagingConfiguration = {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0
    };

    selectItemPerPage(ev) {
        try {
            this.pagingConfiguration.currentPage = 1;
            this.pagingConfiguration.itemsPerPage = ev.target.value;
            this.getUsage(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, 
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {
        }
    }

    searchByString(ev) {
        try {
            this.pagingConfiguration.currentPage = 1;
            this.getUsage(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {
        }
    }
   
    pageChange(newPage: number) {
        try {
            this.pagingConfiguration.currentPage = newPage;
            this.getUsage(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage, 
                             this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {
        }
    }

    sorting(col: number) {
        try {           
            if (col == 1) {
                this.pagingConfiguration.currentPage = 1;
                this.col2Arrow=0; 

                if(this.col1Arrow==0 || this.col1Arrow==1){
                    this.col1Arrow=2;
                    this.orderBy = "Asc";                   
                }else if(this.col1Arrow==2){
                    this.col1Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "Tools Name";
            }else if (col == 2) {

                this.col1Arrow=0; 

                if(this.col2Arrow==0 || this.col2Arrow==1){
                    this.col2Arrow=2;
                    this.orderBy = "Asc";
                }else if(this.col2Arrow==2){
                    this.col2Arrow=1;
                    this.orderBy = "Des";
                }
                this.order = "No of Tokens";
            }

            this.getUsage(this.pagingConfiguration.itemsPerPage, this.pagingConfiguration.currentPage,
                this.searchModel.searchString, this.order, this.orderBy);
        }
        catch (ex) {
        }
    }

 }