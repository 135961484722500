import { Component, OnInit } from '@angular/core';

import {UtilityService} from '../../shared/service/utility.service';

 @Component({
 selector: 'front-end-header',
 templateUrl: './front-end-header.component.html',
 styleUrls: ['./front-end-header.component.css']
 })
 
 export class FrontEndHeaderComponent implements OnInit {

    hotLine: string;

    constructor(
        private utilityService: UtilityService
    ) { }

    ngOnInit() {
        this.hotLine = this.utilityService.getHotline();
    }

 }