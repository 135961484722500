
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiUrl } from 'src/app/shared/service/api.service';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

    constructor(private titleService: Title) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Faq");
    }

    ngOnInit() {
    }

}