import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//My Webpage component
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { PricingComponent } from './pricing/pricing.component';
import { PoliciesComponent } from './policies/policies.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    HomeComponent,
    FaqComponent,
    PricingComponent,
    PoliciesComponent
  ],
  imports: [
    RouterModule,
    BrowserModule
  ],

})

export class FontEndModule { }
