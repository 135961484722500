import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';

import { FrontEndHeaderComponent } from '../layout/front-end-header/front-end-header.component';
import { FrontEndFooterComponent } from '../layout/front-end-footer/front-end-footer.component';
import { FrontEndLayoutComponent } from '../layout/front-end-layout/front-end-layout.component';

import { BackEndHeaderComponent } from '../layout/back-end-header/back-end-header.component';
import { BackEndFooterComponent } from '../layout/back-end-footer/back-end-footer.component';
import { BackEndLayoutComponent } from '../layout/back-end-layout/back-end-layout.component';

@NgModule({
  declarations: [
    FrontEndHeaderComponent,
    FrontEndFooterComponent,
    FrontEndLayoutComponent,
    BackEndHeaderComponent,
    BackEndFooterComponent,
    BackEndLayoutComponent
  ],
  imports: [
    RouterModule,
    BrowserModule
  ],

})
export class LayoutModule { }
