
<div class="row" style="margin-top: 65px;">

    <div class="col-3 mb-2">

        <div class="card box-shadow">           
            
            <div class="card-body">
                                
                <div class="row mb-2">
    
                    <div class="col-12">
                        <div class="audio-generation-btn-wrapper">
                            <button class="audio-generation-btn" (click)="startNewVisionGeneration()">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="plus" 
                                width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <defs><style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg>
                                <br/>
                                <span class="d-none d-sm-block">Upload a Image</span>                               
                            </button>
                        </div>                               
                    </div>   
                    
                </div>

                <div class="list-group audio-list-div-height" id="list-tab" role="tablist" style="--bs-list-group-border-color: none; --bs-list-group-border-radius: 0px;">       
                    
                    <div *ngFor="let availableVision of availableVisions;  index as i">

                        <a id="{{i}}" style="border-radius: 5px; float: left; width: 90%;"  
                            class="list-group-item list-group-item-action mb-1" id="list-home-list" 
                            data-bs-toggle="list" href="javascript:void(0);"  (click)="getGeneratedVisionDetails(i)" role="tab" aria-controls="list-home">              
                            
                            <div class="row">
                                <div class="col-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>                                        
                                </div>
                                <div class="col-10 d-none d-sm-block">
                                    {{ (availableVision.prompt.length>60)? (availableVision.prompt  | slice:0:60)+'...' :(availableVision.prompt ) }}
                                </div>
                            </div>
                            
                        </a> 
                        
                    </div>

                </div>

            </div>
        
        </div>

    </div>

    <div *ngIf="isNewVisionProcessingStarted==true || isViewingProcessedVision==true" class="col-5 mb-4">
        <div class="card box-shadow">
            
            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">  
                Generate Vision               
                <a href="javascript:void(0);" (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                </a>
            </h5>
            
            <div class="card-body audio-div-height">
                <div class="row">

                    <form [formGroup]="this.entryForm">

                        <div class="row">

                            <div class="col-12 mb-2">
                                <label for=describeExpectationFromThisImage class="form-label">Describe Expectation From This Image</label>
                                <input type="text" id="describeExpectationFromThisImage" formControlName="describeExpectationFromThisImage" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && visionGenerationFromControlStatus.describeExpectationFromThisImage.errors }" placeholder="" />
                                <div *ngIf="submitted && visionGenerationFromControlStatus.describeExpectationFromThisImage.errors" class="invalid-feedback">
                                    <div *ngIf="visionGenerationFromControlStatus.describeExpectationFromThisImage.errors.required">Description is required</div>
                                </div>
                            </div>

                            <div class="col-12 mb-2">
                                <label for=gptModel>Model:</label>
                                <select id="gptModel" formControlName="gptModel" class="form-select" 
                                aria-label=".form-select-sm example">
                                    <option value="gpt-4o" selected>GPT-4o</option>
                                    <option value="gpt-4">GPT-4</option>
                                </select>
                            </div>                     
                            
                            <div [hidden]="!isNewVisionProcessingStarted" class="col-12 mb-2">
                                <label for=prompt>Upload your Image:</label>
                                <input class="custom-file-input form-control form-control-sm" type="file" (change)="selectImageFile($event)">
                            </div>   

                            <div class="col-12 mb-2">
                                <button [hidden]="!isNewVisionProcessingStarted" [disabled]="entryForm.invalid || !isImageFileSelected" (click)="generateVision()" 
                                    type="button" class="btn btn-dark btn-elevate btn-pill">Generate</button>
                            </div>

                        </div>
    
                    </form>
                    
                </div>
            </div>

        </div>
    </div>

    <div [ngStyle]="generatedVision !='' ? {'display': 'block'} : {'display': 'none'}" class="col-4">

    <!-- <div *ngIf="generatedVision !=''" class="col-4"> -->
        <div class="card box-shadow">
            
            <div class="card-body image-div-height" style="text-align: center;">

                <img src="{{uploadedImage}}" class="img-fluid rounded box-shadow"  alt="Uploaded image for vision" >
                <br/><br/>
                <!-- <span>
                    {{generatedVision}}
                </span> -->
                
                <h4>Generated Vision</h4><hr>
                <div id="generatedVision" style="text-align: left;"></div>

                
            </div>

        </div>
    </div>

</div>