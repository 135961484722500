import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { LocalStorageService } from  '../../../shared/service/local.storage.service';
import { ApiUrl } from '../../../shared/service/api.service';
import { CopyWriteService} from '../copywriting.service'
import { CreateCopyWriteModel } from '../copywriting.model';

 @Component({
 selector: 'create-copywriting',
 templateUrl: './create-copywriting.component.html',
 styleUrls: ['./create-copywriting.component.css']
 })
 export class CreateCopyWritingComponent implements OnInit {
    
    @BlockUI() blockUI: NgBlockUI;

    public availableCopywritings: any;
    public entryForm: FormGroup;
    public selectedTemplate: any;
    
    entryModel: CreateCopyWriteModel = new CreateCopyWriteModel();
    public gptModelForm: FormGroup;

    id: string;
    generatedContent: string;
    insertOperation = true;
    submitted = false;
    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";
    isCopyWritingStarted = false;
    generatedCopywritingIndex=0;
    fileUrl;

    get entryFromControlStatus() { return this.entryForm.controls; }
   
    constructor(
        private router: Router,
        private formBuilder: FormBuilder, 
        private service: CopyWriteService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private clipboard: Clipboard,
        private domSanitizer: DomSanitizer
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Copywriting");
     }

    ngOnInit() {
        this.generatedContent = "<div><b>This is my HTML.</b></div>";
        this.getSelectedTemplate();
        this.getCopywritings();

        this.entryForm = this.formBuilder.group({
            prompt: ['', [Validators.required, Validators.maxLength(5000)]],
            generatedContent: ['']        
        });    
        
        this.gptModelForm = this.formBuilder.group({
            gptModel: ['', [Validators.required, Validators.maxLength(50)]]
        });
        this.gptModelForm.get('gptModel').setValue('gpt-4-1106-preview');
        //this.gptModelForm.get('gptModel').disable();
    }

    goBack() {
        this.location.back();
    };

    getSelectedTemplate() {
        try {
            this.blockUI.start("Loading....");               
            this.selectedTemplate = JSON.parse(this.localStorageService.getCopyWriting());
            this.blockUI.stop();
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    clear() {
        try {
            this.id = "";
            this.entryForm.get('prompt').setValue('');
            this.entryForm.get('generatedContent').setValue('');
            
            this.entryForm.get('prompt').enable();
            this.entryForm.get('generatedContent').enable();

            this.insertOperation = true;
            this.submitted = false;
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.insertUpdateMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    getCopywritings() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getCopywritings(this.selectedTemplate[0].id).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.availableCopywritings = val.data;
                }else {
                    this.errorMessage = val.errors[0].value;
                    //alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    startNewCopywriting() {
        try {
            this.clear();

            var element = document.getElementById(this.generatedCopywritingIndex.toString());
            if(element !=null)
                element.classList.remove("active");
                      
            this.isCopyWritingStarted=true;
        }
        catch (ex) {

        }
    }

    getCopywritingDetails(id: string, index: number) { 
        try {
            this.blockUI.start("Loading....");          
            this.service.getCopywritingDetails(id).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    

                    this.entryForm.get('prompt').setValue(val.data.prompt); 
                    this.entryForm.get('generatedContent').setValue(val.data.generatedContent); 

                    this.entryForm.get('prompt').disable();
                    this.entryForm.get('generatedContent').disable();

                    this.generatedCopywritingIndex= index;
                    this.isCopyWritingStarted=true;
                    this.insertOperation = false;
                }else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    generateCopywriting() {
        try {

            this.submitted = true;
            if (this.entryForm.invalid) {
                return;
            }

            this.blockUI.start("System is executing your request...");

            this.entryModel.id=this.selectedTemplate[0].id;
            this.entryModel.prompt = this.entryForm.get('prompt').value;
            this.entryModel.gptModel = this.gptModelForm.get('gptModel').value;
            
            this.service.Insert(this.entryModel).subscribe(response => {
                this.clearMessage();
                var val = response;
                if (val.isSucceed == true) {
                    this.entryForm.get('generatedContent').setValue(val.data.generatedContent);  
                    this.generatedContent = val.data.generatedContent;                  
                    this.successMessage = val.message;
                    
                    this.getCopywritings();
                } else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    copyText() {
        this.clipboard.copy(this.entryForm.get('generatedContent').value);
        alert("Copied to clipboard.");
    }  

    processDownloadableData(){
        var data = '';        
        // for (var val of this.messageList) {
        //     data = data + val.role + ": " + val.content + "\n"; // + "         " + val.pageNo + "\n\n";
        // }
        const blob = new Blob([data], { type: 'application/octet-stream' });
        this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }

 }