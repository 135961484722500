<div class="col-xl-12">
    <div class="kt-portlet">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon">
                    <i class="la la-gear"></i>
                </span>
                <h3 class="kt-portlet__head-title">
                    Role Management
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">

            <div class="row">
                <div class="col-lg-5">
                    <div class="row">

                        <div class="col-lg-1">
                            <label>Status:</label>
                        </div>

                        <div class="col-lg-4 form-group">
                            <select [(ngModel)]="searchModel.status" (change)="selectStatus($event)"
                                class="form-control">
                                <option value="All" selected>All</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>

                        <div class="col-lg-6 form-group">
                            <div class="kt-input-icon kt-input-icon--left">
                                <input [(ngModel)]="searchModel.searchString" (keyup)="searchByString($event)"
                                    type="text" class="form-control" placeholder="Search..." id="generalSearch">
                                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                    <span><i class="la la-search"></i></span>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-lg-6 form-group">
                    <div style="text-align: left; margin-top: 10px;">
                        <h5 class="fail_message" style="color: red;" *ngIf="errorMessage!=''">{{errorMessage}}</h5>
                        <h5 class="success_message" style="color: green;" *ngIf="successMessage!=''">{{successMessage}}</h5>
                    </div>
                </div>

                <div class="col-lg-1 m--align-right  m--margin-top-10-tablet-and-mobile">
                    <button type="button" (click)="openModalInInsertMode()" class="btn btn-primary 
                    btn-elevate btn-pill">
                        <i class="la la-plus"></i>
                        New</button>
                </div>
            </div>

            <div class="kt-form" style="overflow: auto;">

                <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

                    <table class="kt-datatable__table">
                        <thead class="kt-datatable__head">
                            <tr class="kt-datatable__row">

                                <th (click)="sorting(1)" class="kt-datatable__cell kt-datatable__cell--sort  {{col1}}">
                                    <span style="width: 133px; font-weight: bold">Name<i class="las {{col2_arrow}}"></i></span>
                                </th>

                                <th  (click)="sorting(2)" class="kt-datatable__cell kt-datatable__cell--sort {{col2}}">
                                    <span style="width: 133px; font-weight: bold">Status<i class="las  {{col6_arrow}}"></i></span>
                                </th>

                                <th class="kt-datatable__cell kt-datatable__cell--sort">
                                    <span style="width: 110px; font-weight: bold">Actions</span>
                                </th>

                            </tr>
                        </thead>
                        <tbody class="kt-datatable__body">

                            <tr *ngFor="let item of list | paginate: pagingConfiguration" class="kt-datatable__row">

                                <td class="kt-datatable__cell">
                                    <span style="width: 133px;">{{ item.name }}</span>
                                </td>

                                <td class="kt-datatable__cell">
                                    <span style="width: 133px;">

                                        <span *ngIf="item.status=='Active'"
                                            class="kt-badge  kt-badge--primary kt-badge--inline kt-badge--pill">
                                            {{ item.status }}
                                        </span>

                                        <span *ngIf="item.status!='Active'"
                                            class="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                                            {{ item.status }}
                                        </span>

                                    </span>
                                </td>

                                <td class="kt-datatable__cell">
                                    <span style="overflow: visible; position: relative; width: 110px;">

                                        <div class="dropdown">
                                            <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown">
                                                <i class="la la-cog"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item" href="#">
                                                    <i class="la la-edit"></i> Edit Details</a>
                                                <a class="dropdown-item" href="#"><i class="la la-leaf"></i> Update
                                                    Status</a>
                                                <a class="dropdown-item" href="#"><i class="la la-print"></i> Generate
                                                    Report</a>
                                            </div>
                                        </div>

                                        <a (click)="openModalInUpdateMode(item.id)"
                                            class="btn btn-sm btn-clean btn-icon btn-icon-md" title="Edit details">
                                            <i class="la la-edit"></i>
                                        </a>

                                        <a (click)="onDelete(item.id)" class="btn btn-sm btn-clean btn-icon btn-icon-md" title="Delete">
                                            <i class="la la-trash"></i>
                                        </a>

                                    </span>
                                </td>

                            </tr>

                        </tbody>
                    </table>

                    <div class="kt-datatable__pager kt-datatable--paging-loaded">

                        <pagination-controls (pageChange)="pageChange($event)" class="my-pagination">
                        </pagination-controls>

                        <div class="kt-datatable__pager-info">

                            <div class="dropdown bootstrap-select kt-datatable__pager-size dropup" style="width: 70px;">
                                <select [(ngModel)]="pagingConfiguration.itemsPerPage"
                                    (change)="selectItemPerPage($event)" class="form-control ">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>

                            <span class="kt-datatable__pager-detail">Showing
                                {{((pagingConfiguration.currentPage-1)*pagingConfiguration.itemsPerPage)+1}} -
                                {{((pagingConfiguration.currentPage*pagingConfiguration.itemsPerPage) >= pagingConfiguration.totalItems)? pagingConfiguration.totalItems: (pagingConfiguration.currentPage*pagingConfiguration.itemsPerPage)}}
                                of {{pagingConfiguration.totalItems}}</span>
                        </div>                        

                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="myModal">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{insertUpdateMessage}} Role</h4>
                <a data-dismiss="modal" style="font-size: 1.5rem; cursor: pointer;">
                    <i class="la la-close"></i>
                </a>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <form [formGroup]="entryForm">

                    <div class="col-xl-12">
                        <div class="kt-margin-b-15">
                            <label for=name>Name</label>
                            <input type="text" id="name" formControlName="name" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && entryFromControlStatus.name.errors }"
                                placeholder="" />
                            <div *ngIf="submitted && entryFromControlStatus.name.errors" class="invalid-feedback">
                                <div *ngIf="entryFromControlStatus.name.errors.required">Name is required</div>
                                <div *ngIf="entryFromControlStatus.name.errors.maxlength">Name maximum length is 100
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12">
                        <div class="kt-margin-b-15">
                            <label for="status">Status</label>
                            <select formControlName="status" class="form-control">
                                <option label="Active" value="Active" selected="selected">Active</option>
                                <option label="Inactive" value="Inactive">Inactive</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-xl-12">
                        <h5 class="fail_message" style="color: red;" *ngIf="errorMessage!=''">{{errorMessage}}</h5>
                    </div>

                </form>

            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button [hidden]="!insertOperation" [disabled]="entryForm.invalid" (click)="onInsert()" type="button"
                    class="btn btn-primary 
                    btn-elevate btn-pill"> <i class="la la-save"></i> Save</button>
                <button [hidden]="insertOperation" [disabled]="entryForm.invalid" (click)="onUpdate()" type="button"
                    class="btn btn-primary btn-elevate btn-pill"> <i class="la la-save"></i> Update</button>
                <button type="button" class="btn btn-default 
                btn-elevate btn-pill" data-dismiss="modal"><i class="la la-close"></i>Close</button>
            </div>

        </div>
    </div>
</div>