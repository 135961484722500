import { Component, OnInit } from '@angular/core';

 @Component({
 selector: 'front-end-layout',
 templateUrl: './front-end-layout.component.html',
 styleUrls: ['./front-end-layout.component.css']
 })
 
 export class FrontEndLayoutComponent implements OnInit {

 constructor() { }

 ngOnInit() {
 }

 }