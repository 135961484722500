import { Component, OnInit, Input } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { AdminService} from '../admin.service';
import { GeneratedCopywritingsModel, GeneratedCopywritingDetailsModel} from '../admin.model';

declare var $: any;

 @Component({
 selector: 'user-copywriting',
 templateUrl: './user-copywriting.component.html',
 styleUrls: ['./user-copywriting.component.css']
 })
 export class UserCopywritingComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 
    @Input() userId = '';

    userGeneratedCopywritings: GeneratedCopywritingsModel[];
    generatedCopywritingDetailsModel= new GeneratedCopywritingDetailsModel();   
    errorMessage = "";
    successMessage = "";
    
    constructor(
        private router: Router,
        private service: AdminService, 
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute
    ) {

     }

     ngOnInit() {
         this.getCopywritings();
    }

    getCopywritings() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getCopywritings(this.userId).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.userGeneratedCopywritings = val.data;
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    showCopywritingDetails(copywriteId: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getCopywritingDetails(copywriteId).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {     
                    this.generatedCopywritingDetailsModel = val.data;
                    $('#copywritingDetailsModal').modal('show');
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop(); 
        }
    }

 }