import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiUrl } from '../../shared/service/api.service';
import {DashboardService} from '../dashboard.service';
import { LocalStorageService } from  '../../shared/service/local.storage.service';
import {AddCreditBalanceModel} from '../../tools/tools.model';

 @Component({
 selector: 'billing-overview',
 templateUrl: './billing-overview.component.html',
 styleUrls: ['./billing-overview.component.css']
 })

 export class BillingOverviewComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    
    public entryForm: FormGroup;
    creditBalanceUSD: any;
    creditBalanceBDT: any;
    submitted = false;

    addCreditBalanceModel: AddCreditBalanceModel= new AddCreditBalanceModel();

    get entryFromControlStatus() { return this.entryForm.controls; }
    
    constructor(
        private router: Router,
        private service: DashboardService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private formBuilder: FormBuilder, 
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Billing overview");
     }

    ngOnInit() {
        this.getUserCreditBalance();    
        
        this.entryForm = this.formBuilder.group({
            amount: ['', [Validators.required, Validators.maxLength(3)]]
        });
    }

    goBack() {
        this.location.back();
    };

    clear() {
        try {
            this.entryForm.get('amount').setValue('');
            this.submitted=false;
        }
        catch (ex) {

        }
    }
    
    getUserCreditBalance() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getUserCreditBalance().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.creditBalanceUSD = val.data.balanceInUSD;
                    this.creditBalanceBDT=val.data.balanceInBDT;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    addToCreditBalance() {
        try {

            this.submitted = true;
            if (this.entryForm.invalid) { return; }

            this.blockUI.start("Adding to credit balance...");

            this.addCreditBalanceModel.amount = this.entryForm.get('amount').value;

            this.service.addToCreditBalance(this.addCreditBalanceModel).subscribe(response => {
                var val = response;
                this.blockUI.stop();
                if (val.isSucceed == true) {
                    this.getUserCreditBalance();
                }
                this.clear();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    };

 }