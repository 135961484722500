import { Component, OnInit, ElementRef  } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';
import {ToolsService} from '../tools.service';
import {ImageGenerationModel} from '../tools.model';

 @Component({
 selector: 'image-generation',
 templateUrl: './image-generation.component.html',
 styleUrls: ['./image-generation.component.css']
 })
 export class ImageGenerationComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;    
    public entryForm: FormGroup;
    public availableImages:any; 
   
    id: string;
    insertOperation = true;
    submitted = false;
    insertUpdateMessage = "";
    errorMessage = "";
    successMessage = "";
    generatedImage= null;
    isNewImageProcessingStarted = false;
    isViewingGeneratedImages = false;
    generatedImagesType = '';
    generatedImageIndex=0;

    entryModel: ImageGenerationModel = new ImageGenerationModel();
    uploadFileFromData: FormData = new FormData();

    get entryFromControlStatus() { return this.entryForm.controls; }
    get imageDetails() { return (this.generatedImage && this.generatedImage.generatedImageDetails) ? this.generatedImage.generatedImageDetails : null }

    constructor(
        private router: Router,
        private formBuilder: FormBuilder, 
        private service: ToolsService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private el: ElementRef
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Image Generation");
     }

    ngOnInit() {
        this.getImages();
        this.entryModel.type = "Create New";
        this.entryModel.model = "dall-e-3";
        
        this.entryForm = this.formBuilder.group({
            type: ['Create New', [Validators.required]],
            gptModel: ['dall-e-3', [Validators.required]],
            quality: ['standard'],
            style: ['vivid'],
            prompt: ['', [Validators.required, Validators.maxLength(5000)]],
            noOfImages: ['1', [Validators.required]],
            sideOfImage: ['1024x1024', [Validators.required]]
        });
    }

    goBack() {
        this.location.back();
    };

    clear() {
        try {
            this.id = "";
            this.entryModel.type = "Create New";
            this.entryModel.image ="";

            this.generatedImage={};

            this.entryForm.get('type').setValue('');
            this.entryForm.get('prompt').setValue('');
            this.entryForm.get('gptModel').setValue('');
            this.entryForm.get('noOfImages').setValue('');
            this.entryForm.get('sideOfImage').setValue('');

            this.submitted=false;
            this.clearMessage();
        }
        catch (ex) {

        }
    }

    clearMessage() {
        try {
            this.insertUpdateMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
        }
        catch (ex) {

        }
    }

    startNewImageGeneration() {
        try {
            //this.clear();

            this.generatedImage=null;

            var element = document.getElementById(this.generatedImageIndex.toString());
            if(element !=null)
                element.classList.remove("active");
                      
            this.isNewImageProcessingStarted=true;
            this.isViewingGeneratedImages=false;

            this.entryModel.type = "Create New";     
            this.entryModel.model = "dall-e-3";

            this.entryForm.get('type').setValue("Create New");            
            this.entryForm.get('gptModel').setValue("dall-e-3");     
            this.entryForm.get('quality').setValue("standard");
            this.entryForm.get('style').setValue("vivid");
            this.entryForm.get('prompt').setValue(""); 
            this.entryForm.get('noOfImages').setValue("1"); 
            this.entryForm.get('sideOfImage').setValue("1024x1024"); 

            this.entryForm.get('gptModel').enable();
            this.entryForm.get('quality').enable();
            this.entryForm.get('style').enable();
            this.entryForm.get('prompt').enable();
            this.entryForm.get('noOfImages').enable();
            this.entryForm.get('sideOfImage').enable();
        }
        catch (ex) {

        }
    }
    
    getImages() {
        try {
            this.blockUI.start("Loading....");          
            this.service.getImages().subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.availableImages = val.data;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
    
    getImagedetails(imageId: string, index: number) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getImage(imageId).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                    this.generatedImage = val.data;
                    
                    this.entryModel.type = this.generatedImage.type;

                    this.entryForm.get('type').setValue(this.generatedImage.type);
                    this.entryForm.get('gptModel').setValue(this.generatedImage.model);
                    this.entryForm.get('quality').setValue(this.generatedImage.quality);
                    this.entryForm.get('style').setValue(this.generatedImage.style);
                    this.entryForm.get('prompt').setValue(this.generatedImage.prompt);
                    this.entryForm.get('noOfImages').setValue(this.generatedImage.noOfImages);
                    this.entryForm.get('sideOfImage').setValue(this.generatedImage.sizeOfImages);
                  
                    this.entryForm.get('gptModel').disable();
                    this.entryForm.get('quality').disable();
                    this.entryForm.get('style').disable();
                    this.entryForm.get('prompt').disable();
                    this.entryForm.get('noOfImages').disable();
                    this.entryForm.get('sideOfImage').disable();                    
                    
                    if(this.generatedImage.type=="Create New")
                        this.generatedImagesType="New images";
                    else if(this.generatedImage.type=="Create Variation")
                    this.generatedImagesType="Variation of images";

                    this.generatedImageIndex = index;
                    this.isNewImageProcessingStarted=false;
                    this.isViewingGeneratedImages=true;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }
   
    selectType(evt) {
        var target = evt.target;
        
        this.entryForm.get('type').setValue(target.value);
        this.entryForm.get('noOfImages').setValue(1);
        this.entryModel.type = target.value;
        //this.entryModel.n = 1;

        if(this.entryModel.type=='Create New'){          
            this.entryModel.model = "dall-e-3";  
            //this.entryModel.size = "1024×1024";      
            //this.entryModel.quality = "Standard";
            //this.entryModel.style = "Vivid";           
            this.entryForm.get('gptModel').setValue(this.entryModel.model);   
            this.entryForm.get('sideOfImage').setValue("1024x1024");   
            this.entryForm.get('quality').setValue("standard");
            this.entryForm.get('style').setValue("vivid");
        }else if(this.entryModel.type=='Create Variation'){
            this.entryModel.model = "dall-e-2";      
            //this.entryModel.size = "256x256";  
            //this.entryModel.quality = "";
            //this.entryModel.style = "";      
            this.entryForm.get('gptModel').setValue(this.entryModel.model);
            this.entryForm.get('sideOfImage').setValue("256x256");
            this.entryForm.get('quality').setValue("");
            this.entryForm.get('style').setValue("");
        }    
    }
    
    selectModel(evt) {
        var target = evt.target;
        this.entryModel.model = target.value;  
        //this.entryModel.n = 1;
        this.entryForm.get('noOfImages').setValue('1');            
    }

    selectImageFile(event) {
        try {
            this.entryForm.get('prompt').setValue('variation');
            const file:File = event.target.files[0];            
            if (file) {                         
                this.uploadFileFromData.append('image', file, file.name);
            }         
        }
        catch (ex) {
        }
    }

    generateImage(){
        try {
            if(this.entryModel.type=="Create New"){
                this.onGenerateNewImage();
            }else if(this.entryModel.type=="Create Variation"){
                this.onGenerateImageVariation();
            }            
        }
        catch (ex) {
    
        }
    }

    onGenerateNewImage() {
        try {

            this.submitted = true;
            if (this.entryForm.invalid) {
                return;
            }

            this.blockUI.start("Generating Image...");

            this.entryModel.type = this.entryForm.get('type').value;
            this.entryModel.model = this.entryForm.get('gptModel').value;
            this.entryModel.quality = this.entryForm.get('quality').value;
            this.entryModel.style = this.entryForm.get('style').value;
            this.entryModel.prompt = this.entryForm.get('prompt').value;
            this.entryModel.n = this.entryForm.get('noOfImages').value;
            this.entryModel.size = this.entryForm.get('sideOfImage').value;

            this.service.generateNewImage(this.entryModel).subscribe(response => {
                var val = response;
                this.blockUI.stop();
                if (val.isSucceed == true) {
                    this.clear();
                    this.getImagedetails(val.data, 0);
                    this.getImages();
                    this.successMessage = val.message;
                } else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

    onGenerateImageVariation() {
        try {
            this.submitted = true;
            if (this.entryForm.invalid) {
                return;
            }

            this.blockUI.start("Generating Image Variation...");

            this.uploadFileFromData.append('n', this.entryForm.get('noOfImages').value);
            this.uploadFileFromData.append('size', this.entryForm.get('sideOfImage').value);
 
            this.service.generateImageVariation(this.uploadFileFromData).subscribe(response => {
                var val = response;
                this.blockUI.stop();
                if (val.isSucceed == true) {
                    this.clear();
                    this.getImagedetails(val.data, 0);
                    this.getImages();
                    this.successMessage = val.message;
                }else {
                    this.errorMessage = val.errors[0].value;
                    alert(this.errorMessage);
                }
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }  
    
 }