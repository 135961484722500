import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service'; 

//Layouts
import { FrontEndLayoutComponent } from './layout/front-end-layout/front-end-layout.component';
import { BackEndLayoutComponent } from './layout/back-end-layout/back-end-layout.component';
import { PageNotFoundComponent } from './font-end/page-not-found/page-not-found.component';

// website
import { HomeComponent } from './font-end/home/home.component';
import { FaqComponent } from './font-end/faq/faq.component';
import { PricingComponent } from './font-end/pricing/pricing.component';
import { PoliciesComponent } from './font-end/policies/policies.component';

//Auth
import { RegistrationComponent } from './auth/registration/registration.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { EmailConfirmationComponent } from './auth/email-confirmation/email-confirmation.component';
import { EmailVerificationComponent } from './auth/email-verification/email-verification.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ResetPasswordRequestComponent } from './auth/reset-password-request/reset-password-request.component';
import { ResetPasswordConfirmationComponent } from './auth/reset-password-confirmation/reset-password-confirmation.component';
import { ForgetPasswordConfirmationComponent } from './auth/forget-password-confirmation/forget-password-confirmation.component';
import { RoleManagementComponent } from './auth/role-management/role-management.component';

//Dashboard
import {DashboardComponent} from './dashboard/dashboard/dashboard.component'; 

//AI
import { ToolsListComponent } from './tools/tools-list/tools-list.component';
import { CopywritingListComponent } from './tools/copywriting/copywriting-list/copywriting-list.component';
import { CreateCopyWritingComponent } from './tools/copywriting/create-copywriting/create-copywriting.component';
import { ImageGenerationComponent } from './tools/image-generation/image-generation.component';
import { ChatWithAIComponent } from './tools/chat-with-ai/chat-with-ai.component';
import {ChatWithFileComponent} from './tools/chat-with-file/chat-with-file.component';
import {AudioBookComponent} from './tools/audio-book/audio-book.component';
import { VisionComponent } from './tools/vision/vision.component';

//Admin
import {UserOverviewComponent} from './admin/user-overview/user-overview.component';
import { UserListComponent } from './admin/user-list/user-list.component';

const routes: Routes = [

  {
    path: '',
    component: FrontEndLayoutComponent,
    children: [

      //Webiste
      { path: '', component: HomeComponent },
      { path: 'home', component: HomeComponent },
      { path: 'policies', component: PoliciesComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'pricing', component: PricingComponent },

      //Auth
      { path: 'registration', component: RegistrationComponent },
      { path: 'login', component: LoginComponent },
      { path: 'forget-password', component: ForgetPasswordComponent },
      { path: 'email-confirmation/:token', component: EmailConfirmationComponent },
      { path: 'email-verification/:emailAddress', component: EmailVerificationComponent },
      { path: 'reset-password-request/:token', component: ResetPasswordRequestComponent },
      { path: 'reset-password-confirmation', component: ResetPasswordConfirmationComponent },
      { path: 'forget-password-confirmation', component: ForgetPasswordConfirmationComponent }
      
      // Page not found
      //{ path: 'page-not-found', component: PageNotFoundComponent },
      //{ path: '**', redirectTo: '/page-not-found' },

    ]
  },

  {
    path: '',
    component: BackEndLayoutComponent,
    canActivate: [AuthGuard],
    children: [

      // Auth      
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'role-management', component: RoleManagementComponent },
      
      //Dashboard
      {path: 'dashboard', component: DashboardComponent},

      //AI 
      { path: 'tools', component: ToolsListComponent },
      { path: 'copywriting', component: CopywritingListComponent },
      { path: 'copywriting/create', component: CreateCopyWritingComponent },
      { path: 'image-generation', component: ImageGenerationComponent },
      { path: 'chat-with-ai', component: ChatWithAIComponent },
      { path: 'chat-with-file', component: ChatWithFileComponent },
      { path: 'audiobook', component: AudioBookComponent },
      { path: 'vision', component: VisionComponent },
      
      //Admin
      {path: 'admin/user-list', component: UserListComponent},
      {path: 'admin/user-overview/:userId', component: UserOverviewComponent},

    ]
  },

  {
    path: '',
    component: UserOverviewComponent,
    children: [
      // Page not found
      { path: 'page-not-found', component: PageNotFoundComponent },
      { path: '**', redirectTo: '/page-not-found' },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
