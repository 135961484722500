<div class="row" style="margin-top: 65px;">
    <div class="col-xl-4"></div>
    <div class="col-xl-4">

        <!--begin::Portlet-->
        <div class="card  box-shadow">

            <h5 class="card-header d-flex justify-content-between align-items-center text-dark">
                Login        
                <a href="javascript:void(0);"  (click)="goBack()" class="text-decoration-none text-dark"  title="Back">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                    class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                </a>
            </h5>
            
            <div class="card-body">

                <!--begin::Form-->
                <form [formGroup]="loginForm" >  
                    
                    <div class="mb-2">
                        <label for=email class="form-label">Email Address</label>
                        <input type="email" id="email" formControlName="email" class="form-control" 
                        [ngClass]="{ 'is-invalid': submitted && loginFromControlStatus.email.errors }" aria-describedby="emailHelp"  
                        placeholder=""/>
                        <div *ngIf="submitted && loginFromControlStatus.email.errors" class="invalid-feedback">
                            <div *ngIf="loginFromControlStatus.email.errors.required">Email is required</div>
                            <div *ngIf="loginFromControlStatus.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" formControlName="password" class="form-control"  
                        [ngClass]="{ 'is-invalid': submitted && loginFromControlStatus.password.errors }" />
                        <div *ngIf="submitted && loginFromControlStatus.password.errors" class="invalid-feedback">
                            <div *ngIf="loginFromControlStatus.password.errors.required">Password is required</div>
                            <div *ngIf="loginFromControlStatus.password.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                    </div>   

                    <div class="mb-2" style="text-align: center;">
                        <h5 class="fail_message" *ngIf="errorMessage!=''">{{errorMessage}}</h5>
                        <h5 class="success_message" *ngIf="successMessage!=''">{{successMessage}}</h5>
                    </div> 

                    <div class="col-auto" style="text-align: center;">
                        <button [disabled]="loginForm.pristine || loginForm.invalid" (click)="onLogin()" type="button" 
                            class="btn btn-dark">Login</button>
                        <button (click)="goBack()"  class="btn btn-dark me-2" style="margin-left: 1%;">Cancel</button>
                        <a [routerLink]="['/forget-password']" class="">Forgot Password?</a>
                    </div>

                </form>   
                <!--end::Form-->

            </div>

        </div>
        <!--end::Portlet-->

    </div>
    <div class="col-xl-4"></div>
</div>