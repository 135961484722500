import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';// 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { ApiUrl } from '../shared/service/api.service';
import { LocalStorageService } from '../shared/service/local.storage.service';
import { ImageGenerationModel, AudioGenerationModel, AIChatAskedOrSearchModel, EmbeddingsFile } from './tools.model';

@Injectable({
    providedIn: 'root' /* by using providerIn we don't need to import service on provider array in module */
})

export class ToolsService{

    constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

    //-------------------------------Tools Item--------------------------------------------------

    GetTools(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Tools/GetToolsList');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }
    
    //----------------------------------End------------------------------------------------------  

    //----------------------------------Image------------------------------------------------------

    getImages(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Image/GetImages');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    getImage(imageId: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Image/GetImage/?Id='+imageId);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    generateNewImage(emailConfirmationModel: ImageGenerationModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Image/CreateImage');
        return this.http.post(url, emailConfirmationModel, this.localStorageService.getAuthenticationHeader());
    }

    generateImageVariation1(emailConfirmationModel: ImageGenerationModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Image/CreateImageVariation');
        return this.http.post(url, emailConfirmationModel, this.localStorageService.getAuthenticationHeader());
    }

    generateImageVariation(emailConfirmationModel: FormData): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Image/CreateImageVariation');
        return this.http.post(url, emailConfirmationModel, this.localStorageService.getAuthenticationHeaderWithoutContentType());
    }

    //-----------------------------------End-------------------------------------------------------

    //-------------------------------------Chat with AI------------------------------------------
    
    getChats(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Chat/GetChats');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    getChatDetails(createDate: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Chat/GetChatDetails?CreateDate='+createDate);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }
    
    sendMesssage(askedOrSearchModel: AIChatAskedOrSearchModel): Observable<any> {
        const url = (ApiUrl.baseUrl + 'chat/completions');
        return this.http.post(url, askedOrSearchModel, this.localStorageService.getAuthenticationHeader());
    }

    //----------------------------------End------------------------------------------------------


    //-------------------------------------Chat with file----------------------------------------
    
    getChatWithFileList(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'ChatWithFile/GetChatWithFileList');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    getChatWithFileDetails(id: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Copywriting/GetGeneratedCopywriting?Id='+id);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }
    
    uploadFile(fileInfo: FormData): Observable<any> {
        const url = (ApiUrl.baseUrl + 'ChatWithFile/UploadFile');
        return this.http.post(url, fileInfo, this.localStorageService.getAuthenticationHeaderWithoutContentType());
    }

    queryByVector(embeddingsFileModel: EmbeddingsFile): Observable<any> {
        const url = (ApiUrl.baseUrl + 'ChatWithFile/ChatWithFile');
        return this.http.post(url, embeddingsFileModel, this.localStorageService.getAuthenticationHeader());
    }

    getFileInfo(fileId: string): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'ChatWithFile/GetChatWithFileDetails?FileEmbeddingId='+ fileId);
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //----------------------------------End------------------------------------------------------ 


    //-------------------------------------Audio Book--------------------------------------------

    generateNewAudio(audioGenerationModel: FormData): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Audio/CreateAudioBook');
        return this.http.post(url, audioGenerationModel, this.localStorageService.getAuthenticationHeaderWithoutContentType());
    }

    getAudioBookList(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Audio/GetAudioBookList');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }
    
    //-------------------------------------Audio Book--------------------------------------------

    //-------------------------------------Audio Book--------------------------------------------

    generateNewVision(audioGenerationModel: FormData): Observable<any> {
        const url = (ApiUrl.baseUrl + 'Vision/CreateVision');
        return this.http.post(url, audioGenerationModel, this.localStorageService.getAuthenticationHeaderWithoutContentType());
    }
    
    getGeneratedVisionList(): Observable<any> {                
        const url = (ApiUrl.baseUrl + 'Vision/GetVisionList');
        return this.http.get(url, this.localStorageService.getAuthenticationHeader());
    }

    //-------------------------------------Audio Book--------------------------------------------

}