
<div class="col-12 mb-2">
    <div class="card box-shadow hide-border">                              
        
        <div class="card-body chat-details">
            <div class="row module-list">
                <h3>Dashboard Overview</h3>
                <h3>Your Current Plan:</h3>
            </div>
        </div>

    </div>
</div>