import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { ApiUrl } from '../../shared/service/api.service';

@Component({
    selector: 'forget-password-confirmation',
    templateUrl: './forget-password-confirmation.component.html',
    styleUrls: ['./forget-password-confirmation.component.css']
})
export class ForgetPasswordConfirmationComponent implements OnInit {
    
    constructor(private titleService: Title, private route: ActivatedRoute) {
        this.titleService.setTitle(ApiUrl.baseTitle + "Forget Password Confirmation");
    }

    ngOnInit() {
    }

}