import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title }     from '@angular/platform-browser';
import {Location} from '@angular/common';

import { LocalStorageService } from '../../shared/service/local.storage.service';
import { AuthService } from '../auth.service';
import { LoginModel } from '../auth.model';
import { ApiUrl } from '../../shared/service/api.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    login: LoginModel = new LoginModel();

    loginForm: FormGroup;
    submitted = false;
    errorMessage = "";
    successMessage = "";

    constructor(
        public authService: AuthService, 
        private formBuilder: FormBuilder, 
        private router: Router, 
        private localStorageService: LocalStorageService, 
        private titleService: Title, 
        private location: Location, 
    ) 
    {
        this.titleService.setTitle(ApiUrl.baseTitle+ "Login");
        this.clearMessage();
    }

    ngOnInit() {
        const currentUser = this.localStorageService.getCurrentUser();
        if (currentUser) {
            this.router.navigate(["tools"]);
        }
        else{
            this.loginForm = this.formBuilder.group({
                email: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required, Validators.minLength(6)]]
            });
        }
    }

    clearMessage() {
        this.errorMessage = "";
        this.successMessage = "";
    }

    get loginFromControlStatus() { return this.loginForm.controls; }

    clear(){       
        this.loginForm.get('email').setValue('');
        this.loginForm.get('password').setValue('');
        this.submitted = false;
    }

    goBack() {
        this.location.back();
    }
    
    onLogin() {
        try {
            this.submitted = true;
            if (this.loginForm.invalid) {
                return;
            }
            
            this.blockUI.start("Login");

            this.login.email=this.loginForm.get('email').value;
            this.login.password=this.loginForm.get('password').value;

            this.authService.onLogin(this.login).subscribe(response => {
                this.clearMessage();
                var val = response;

                if (val.isSucceed) {
                    this.successMessage = val.message;
                    var loginSaveResponse= this.localStorageService.setCurrentUser(response.data);
                    if(loginSaveResponse==true){
                        if (response.data.userRoleId == "42ff5dad-3c27-4c97-a3a7-c3d44b67abb3")
                            this.router.navigate(['/tools']); 
                        else if (response.data.userRoleId == "42ff5dad-3c27-4c97-a3a7-c3d44b67abb2")
                            this.router.navigate(['/admin/user-list']);  
                    }                                                                                                
                }
                else {
                    this.errorMessage = val.message;
                }  
                this.blockUI.stop();
            }, error => { 
                this.blockUI.stop(); 
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

}