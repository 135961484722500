import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from  '../../shared/service/local.storage.service';
import { ApiUrl } from '../../shared/service/api.service';
import { AdminService} from '../admin.service';
import { UserDetailsModel} from '../admin.model';

declare var $: any;

 @Component({
 selector: 'user-overview',
 templateUrl: './user-overview.component.html',
 styleUrls: ['./user-overview.component.css']
 })
 export class UserOverviewComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI; 
   
    userId: string;

    userDetailsModel: UserDetailsModel = new UserDetailsModel();

    errorMessage = "";
    successMessage = "";
    
    constructor(
        private router: Router,
        private service: AdminService, 
        private localStorageService: LocalStorageService,
        private titleService: Title,
        private location: Location,
        private route: ActivatedRoute
    ) {
        this.titleService.setTitle(ApiUrl.baseTitle + "User Overview");

        this.route.params.subscribe(params => {
            this.userId=params['userId'];
        });
     }

     ngOnInit() {
        this.getUserAccountSummary(this.userId);
    }

    goBack() {
        this.location.back();
    };

    getUserAccountSummary(userId: string) {
        try {
            this.blockUI.start("Loading....");          
            this.service.getUserAccountSummary(userId).subscribe(response => {             
                var val = response;
                if (val.isSucceed == true) {                    
                   this.userDetailsModel = val.data;
                } else {
                    this.errorMessage = val.errors[0].value;
                }
                this.blockUI.stop();
            }, error => {
                this.blockUI.stop();
                if (error.status == 401 || error.status == 423) {
                    this.localStorageService.removeLogin();
                    this.router.navigateByUrl("/");
                }else if (error.status === undefined){
                    window.location.reload();
                }
            });
        }
        catch (ex) {
            this.blockUI.stop();
        }
    }

 }