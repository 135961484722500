import { Component } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { ApiUrl } from 'src/app/shared/service/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '';

  public constructor(private titleService: Title ) { 
    this.titleService.setTitle(ApiUrl.baseTitle + "Home");
  }

}
